import axios from "axios";
import React, { Component } from "react";
import { Button, Col, Form, Modal, Row, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import GroupCreate from "../group/GroupCreate";
import Check from "../other/Check";
import { data } from "jquery";

class ClassCreate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      listgroup: [],
      show: false,
      type: "",
      schoolYears: [],
      errors: {},
      dataInput: {
        class_name: "",
        id_year: "",
        id_group: this.props.id_group,
        teachers: [],
      },
    };
  }
  componentDidMount() {
    this.getlistgroup();
    this.getSchoolYears();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.show != prevProps.show) {
      this.getlistgroup();
    }
  }

  getlistgroup = () => {
    axios
      .get("/viewClassGroup")
      .then((res) => {
        if (res.data.status) {
          this.setState({
            listgroup: res.data.data,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getSchoolYears = () => {
    axios
      .get("/school-year-shows")
      .then((res) => {
        const schoolYears = res.data.data.schoolYears || [];

        const currentTime = Math.floor(Date.now() / 1000);

        const filteredData = schoolYears.filter(
          (item) =>
            item.start_date <= currentTime && item.end_date >= currentTime
        );

        let newDataInput = { ...this.state.dataInput };

        newDataInput.id_year =
          filteredData.length > 0 ? filteredData[0].id : "";

        this.setState({
          schoolYears: schoolYears,
          dataInput: newDataInput,
        });
      })
      .catch((error) => {
        toast(`${error.message}`, { type: "error" });
      });
  };

  createclass() {
    let formData = new FormData();
    formData.append("class_name", this.state.dataInput.class_name);
    formData.append("id_year", this.state.dataInput.id_year);
    formData.append("id_group", this.state.dataInput.id_group);
    formData.append("teachers", JSON.stringify(this.state.dataInput.teachers));

    axios
      .post(`/classroom-create`, formData)
      .then((res) => {
        if (res.status === 200) {
          toast(`${res.data.messages}`, { type: "success" });
          this.setState({
            dataInput: {
              class_name: "",
              id_year: "",
              id_group: this.props.id_group,
              teachers: [],
            },
          });
          this.props.close();
          this.props.onSave();
        }
      })
      .catch((error) => {
        this.setState({ errors: error.response.data.messages });
      });
  }

  formclose = () => {
    this.setState({ show: false });
    this.getlistgroup();
  };

  renderGroupAdd = () => {
    return (
      <GroupCreate
        PT={this.state.type}
        show={this.state.show}
        close={this.formclose}
      />
    );
  };

  hanldeInput = (e) => {
    const value = e.target.value;
    const name = e.target.name;

    let newDataInput = this.state.dataInput;
    newDataInput[name] = value;

    let newErrors = this.state.errors;
    delete newErrors[name];

    this.setState({
      dataInput: newDataInput,
      errors: newErrors,
    });
  };

  render() {
    return (
      <>
        <Modal
          show={this.props.show}
          onHide={() => {
            this.props.close();
            this.setState({
              dataInput: {
                class_name: "",
                id_year: "",
                id_group: this.props.id_group,
                teachers: [],
              },
            });
          }}
        >
          <Modal.Header closeButton>
            <h1 className="modal-title fs-5" id="staticBackdropLabel">
              Thêm lớp học
            </h1>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Floating className="mb-3">
                <Form.Control
                  type="text"
                  className={this.state.errors.class_name ? "is-invalid" : ""}
                  placeholder="Tên lớp học"
                  name="class_name"
                  value={this.state.dataInput.class_name}
                  onChange={(e) => {
                    this.hanldeInput(e);
                  }}
                />
                <Form.Label for="floatingInputClassName">
                  Tên lớp học<i className="text-danger">*</i>
                </Form.Label>
                {this.state.errors.class_name ? (
                  <span className="text-danger">
                    {this.state.errors.class_name}
                  </span>
                ) : (
                  ""
                )}
              </Form.Floating>

              <Table>
                <thead>
                  <tr>
                    <th className="text-center">STT</th>
                    <th>Họ và tên</th>
                    <th>Vai trò</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.dataInput.teachers.length > 0 ? (
                    this.state.dataInput.teachers.map((item, i) => {
                      return (
                        <tr>
                          <td className="text-center">{i + 1}</td>
                          <td>{item.teacher_name}</td>
                          <td>
                            <Form.Select
                              className={
                                item.is_main === 1 ? "text-primary" : ""
                              }
                              value={item.is_main}
                              onChange={(e) => {
                                let newDataInput = { ...this.state.dataInput };

                                const findTeacher = newDataInput.teachers.find(
                                  (teacher) => teacher.id === item.id
                                );

                                let newTeachers = newDataInput.teachers.map(
                                  (teacher) => {
                                    teacher.is_main =
                                      teacher.id === findTeacher.id ? 1 : 0;
                                    return teacher;
                                  }
                                );

                                newDataInput.teachers = newTeachers;

                                this.setState({ dataInput: newDataInput });
                              }}
                            >
                              <option value={1}>Chủ nhiệm</option>
                              <option value={0}>Giáo viên</option>
                            </Form.Select>
                          </td>
                          <td>
                            <Button
                              size="sm"
                              variant="outline-danger"
                              onClick={() => {
                                let newDataInput = { ...this.state.dataInput };
                                let newTeachers = newDataInput.teachers.filter(
                                  (teacher) => teacher.id !== item.id
                                );

                                const checkIsMain = newTeachers.some(
                                  (teacher) => teacher.is_main === 1
                                );
                                if (newTeachers.length > 0 && !checkIsMain)
                                  newTeachers[0].is_main = 1;

                                newDataInput.teachers = newTeachers;
                                this.setState({ dataInput: newDataInput });
                              }}
                            >
                              <i className="fa-solid fa-times" />
                            </Button>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <></>
                  )}
                </tbody>
              </Table>

              <Form.Floating className="mb-3">
                <Form.Select
                  id="selectTeacherId"
                  name="teachers"
                  placeholder="Giáo viên"
                  className={this.state.errors.teachers ? "is-invalid" : ""}
                  value={0}
                  onChange={(e) => {
                    const teacherId = Number(e.target.value);
                    let newDataInput = { ...this.state.dataInput };

                    const teacherChose = this.props.listuser.filter(
                      (item) => item.id === teacherId
                    );
                    const checkIsmain = newDataInput.teachers.some(
                      (item) => item.is_main === 1
                    );

                    newDataInput.teachers.push({
                      id: teacherChose[0].id,
                      teacher_name: teacherChose[0].user_name,
                      is_main: checkIsmain ? 0 : 1,
                    });

                    let newErrors = this.state.errors;
                    delete newErrors["teachers"];

                    this.setState({
                      dataInput: newDataInput,
                      errors: newErrors,
                    });
                  }}
                >
                  <option value="">--Chọn--</option>

                  {this.props.listuser.map((user) => {
                    const checkIsset = this.props.listuser
                      ? this.state.dataInput?.teachers.some(
                          (item) => item.id == user.id
                        )
                      : false;

                    if (!checkIsset)
                      return user.role_name === "Giáo viên" ? (
                        <option key={user.id} value={user.id}>
                          {user.user_name}
                        </option>
                      ) : (
                        <div key={user.id}></div>
                      );
                  })}
                </Form.Select>
                <Form.Label for="selectTeacherId">
                  Giáo viên<i className="text-danger">*</i>
                </Form.Label>
                {this.state.errors.teachers ? (
                  <span className="text-danger">
                    {this.state.errors.teachers ? "Không được để trống" : ""}
                  </span>
                ) : (
                  ""
                )}
              </Form.Floating>

              <Row>
                <Col>
                  {new Check().permission(["7"]) ? (
                    <Form.Floating className="mb-3">
                      <Form.Select
                        name="id_group"
                        id="selectGroupId"
                        className={
                          this.state.errors.id_group ? "is-invalid" : ""
                        }
                        placeholder="Nhóm học sinh"
                        value={this.state.dataInput.id_group}
                        onChange={(e) => {
                          this.hanldeInput(e);
                        }}
                      >
                        <option value="">--Chọn--</option>
                        {this.state.listgroup.map((group, i) => {
                          if (group.is_adult == 0 && group.is_main == 1) {
                            return (
                              <option key={group.id} value={group.id}>
                                {group.group_name}
                              </option>
                            );
                          } else {
                            return null;
                          }
                        })}
                      </Form.Select>
                      <Form.Label for="selectGroupId">
                        Nhóm học sinh<i className="text-danger">*</i>
                      </Form.Label>
                      {this.state.errors.id_group ? (
                        <span className="text-danger">
                          {this.state.errors.id_group}
                        </span>
                      ) : (
                        ""
                      )}
                    </Form.Floating>
                  ) : null}
                </Col>

                <Col>
                  <Form.Floating>
                    <Form.Select
                      id="idYear"
                      name="id_year"
                      className={this.state.errors.id_year ? "is-invalid" : ""}
                      placeholder="Năm học"
                      value={this.state.dataInput.id_year}
                      onChange={(e) => this.hanldeInput(e)}
                    >
                      <option value="">--Chọn--</option>
                      {this.state?.schoolYears.map((year, i) => {
                        return (
                          <option key={year.id} value={year.id}>
                            {year.year_name}
                          </option>
                        );
                      })}
                    </Form.Select>

                    <Form.Label for="idYear">
                      Năm học<i className="text-danger">*</i>
                    </Form.Label>
                    {this.state.errors.id_year ? (
                      <span className="text-danger">
                        {this.state.errors.id_year}
                      </span>
                    ) : (
                      ""
                    )}
                  </Form.Floating>
                </Col>
              </Row>
            </Form>
          </Modal.Body>

          <Modal.Footer>
            <Button
              size="sm"
              variant="secondary"
              onClick={() => {
                this.props.close();
                this.setState({
                  dataInput: {
                    class_name: "",
                    id_year: "",
                    id_group: this.props.id_group,
                    teachers: [],
                  },
                });
              }}
            >
              <i className="fa-solid fa-times"></i> Đóng
            </Button>
            <Button
              size="sm"
              type="button"
              variant="success"
              onClick={() => this.createclass()}
            >
              <i className="fa-solid fa-check"></i> Lưu
            </Button>
          </Modal.Footer>
        </Modal>
        {this.state.show ? this.renderGroupAdd() : null}
      </>
    );
  }
}

export default ClassCreate;
