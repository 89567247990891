import React, { Component } from "react";
import {
  Breadcrumb,
  Button,
  Card,
  Container,
  Table,
  Modal,
} from "react-bootstrap";
import axios from "axios";
import { Link } from "react-router-dom";
import * as XLSX from "xlsx-js-style";
import DatePicker from "react-datepicker";
import CurrencyFormat from "react-currency-format";
import swal from "sweetalert";

class ListCut extends Component {
  constructor(props) {
    super(props);
    this.state = {
      classifies: [],
      cutMeals: [],
      cutMealDetail: [],
      cutMealDetailShow: [],
      classifyShow: 1,
      listStaff: [],
      listAbsent: [],
      listcutStudent: [],
      toDay: new Date(),
      listSetting: [],
      showCutMealDetail: false,
      listcut: [],
      list_: [],
      listStudent: [],
      studentss: [],
      startDate: new Date(),
      endDate: new Date(),
      show: false,
      start_: new Date(),
      end_: new Date(),
    };
  }

  async componentDidMount() {
    document.title = "Chi tiết cắt suất ăn";

    const start = new Date();
    start.setHours(0, 0, 0, 0);

    const end = new Date();
    end.setHours(23, 59, 59, 0);

    this.setState(
      {
        startDate: start,
        endDate: end,
      },
      () => {
        this.getSetting(this.state.classifyShow);
        this.getClassifiesData();
        this.getListMealstudent(start, end);
      }
    );

    // this.getListMealstudent()
  }
  getSetting = (classifyShow) => {
    axios.get(`/viewSetting?id_classify=${classifyShow}`).then((res) => {
      this.setState({ listSetting: res.data.data.time });
    });
  };
  getDate_ = (data) => {
    let date = data;
    let result = `${date?.getDate()}/${
      date?.getMonth() + 1
    }/${date?.getFullYear()}`;
    return result;
  };
  getDate = (data) => {
    let date = new Date(data * 1000);
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
  };
  getTong = () => {
    var tong = 0;
    var tongOneDay = 0;
    this.state.listSetting?.map((item) =>
      item.is_active == 1
        ? (tongOneDay = tongOneDay + parseInt(item.poetry))
        : null
    );
    this.state.listStudent?.map((student_, index) => {
      student_.absent.map((absent_, index1) => {
        if (absent_.type_meal == 1 && absent_.is_term != 0)
          tong = tong + absent_.price_return;
        else {
          if (absent_.is_term != 0) {
            absent_.detail.map(
              (item) => (tong = parseInt(tong) + parseInt(item.price_return))
            );
          }
        }
      });
    });
    return tong;
  };
  removeDuplicates(arr) {
    const uniqueItems = new Set();
    const result = [];

    for (const item of arr) {
      const itemAsString = JSON.stringify(item);
      if (!uniqueItems.has(itemAsString)) {
        uniqueItems.add(itemAsString);
        result.push(item);
      }
    }

    return result;
  }
  getListMealstudent(start, end) {
    var arrStudent = [];
    var arrRS = [];
    let start_ = start.setHours(0, 0, 0) / 1000;
    let end_ = end.setHours(23, 59, 59) / 1000;
    axios
      .post(
        `/viewMeal?id_classify=1&start_date=${parseInt(
          start_
        )}&end_date=${end_}`
      )
      .then((res) => {
        if (res.data.status) {
          const dateFilter = res.data.data;
          // .filter(absentDay => start_ <= absentDay.meal_day && absentDay.meal_day <= end_)
          dateFilter.map((datefilter_student) => {
            arrStudent.push({
              id: datefilter_student.id_student,
              class_name: datefilter_student.class_name,
              student_name: datefilter_student.student_name,
            });
          });
          arrRS = this.removeDuplicates(
            arrStudent.sort(function (a, b) {
              if (a.class_name <= b.class_name) return -1;
              if (a.class_name > b.class_name) return 1;
              return 0;
            })
          );
          // console.log(arrRS)
          this.setState({
            listStudent: arrRS.map((student) => {
              return {
                id: student.id,
                username: student.student_name,
                class_name: student.class_name,
                absent: dateFilter.filter(
                  (absentDay) =>
                    absentDay.id_student === student.id &&
                    absentDay.is_status == 1 &&
                    absentDay.is_meal == 0
                ),
                // Ngày nghỉ có phép
                // date:
                // Khong trừ
              };
            }),
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  getCutMealsData = (classifyId) => {
    axios
      .post(
        `/viewMeal?id_classify=${classifyId}&start_date=${parseInt(
          this.state.startDate / 1000
        )}&end_date=${this.state.endDate / 1000}`
      )
      .then((res) => {
        if (res.data.status === true) {
          this.setState({
            cutMeals: res.data.data,
          });
        } else {
          this.setState({
            cutMeals: [],
          });
        }
      });
  };
  getClassifiesData = () => {
    axios.get(`/viewClassify`).then((res) => {
      if (res.data.status === true) {
        this.setState({
          classifies: res.data.data,
          classifyShow: res.data.data[0].id,
        });
        this.getCutMealsData(res.data.data[0].id);
      }
    });
  };
  getStudent() {
    axios
      .get("/viewStudent")
      .then((res) => {
        if (res.data.status) {
          this.setState({
            studentss: res.data.data.sort(function (a, b) {
              if (a.class_name <= b.class_name) return -1;
              if (a.class_name > b.class_name) return 1;
              return 0;
            }),
          });

          this.getListMealstudent(this.state.startDate, this.state.endDate);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  // getCutMealsData = (classifyId) => {
  // 	axios.post(`/viewMeal?id_classify=${classifyId}`)
  // 		.then(res => {
  // 			if (res.data.status === true) {
  // 				this.setState({
  // 					cutMeals: res.data.data
  // 				})

  // 			} else {
  // 				this.setState({
  // 					cutMeals: []
  // 				})
  // 			}
  // 		});
  // }

  getCutMealDetailData = (paramId) => {
    let classifyLink = `/viewListMealByClass?id_classify=${
      this.state.classifyShow
    }&${
      this.state.classifyShow === 1
        ? `id_class=${paramId}`
        : `id_user=${paramId}`
    }`;

    axios.post(classifyLink).then((res) => {
      if (res.data.status === true) {
        this.setState({
          cutMealDetail: res.data.data,
          cutMealDetailShow: res.data.data,
        });
      } else {
        this.setState({
          cutMealDetail: [],
          cutMealDetailShow: [],
        });
      }
    });
  };

  handleSelectClassify = (key) => {
    this.getCutMealsData(key);
    this.setState({
      classifyShow: key.toString(),
    });
  };
  formatNumber(num) {
    if (num != null && num != "")
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }
  Emaxlement = (array) => {
    let max = array[0];
    let max_index = 0;
    for (let i = 0; i < array.length; ++i) {
      if (max < array[i]) {
        max = array[i];
        max_index = i;
      }
    }
    return max;
  };

  renderCutMealListTable() {
    return (
      <div style={{ overflow: "auto" }}>
        <Table bordered>
          <thead>
            <tr>
              {" "}
              <th className="text-center" colSpan="12">
                {" "}
                DANH SÁCH HỌC SINH CẮT SUẤT ĂN (
                {this.getDate_(this.state.startDate)} -{" "}
                {this.getDate_(this.state.endDate)}){" "}
              </th>
            </tr>
            <tr>
              <th rowSpan="2" className="align-middle text-center">
                Họ và tên
              </th>
              <th className="align-middle text-center" rowSpan="2">
                Lớp
              </th>
              <th
                className="align-middle text-center"
                style={{ width: "80px" }}
                rowSpan="2"
              >
                {" "}
                SL ngày cắt suất
              </th>
              <th className="text-center" colSpan="5">
                Ngày nghỉ có phép
              </th>
              <th className="text-center" colSpan="3">
                Ngày nghỉ không phép
              </th>
            </tr>
            <tr>
              <th className="text-center" style={{}}>
                {" "}
                Ngày
              </th>
              <th className="text-center"> Lý do</th>
              <th className="text-center">Thành tiền</th>
              <th className="text-center"> SL</th>
              <th className="text-center">Tổng</th>
              <th className="text-center"> Ngày</th>
              <th className="text-center"> Lý do</th>
              <th className="text-center"> SL</th>
            </tr>
          </thead>
          <tbody>
            {this.state.listStudent.map((absent, index) => {
              let tongtien = 0;
              var tongAll = 0;
              this.state.listSetting.map((item) =>
                item.is_active == 1
                  ? (tongtien = tongtien + parseInt(item.poetry))
                  : null
              );
              if (absent.absent.length > 0) {
                let tong = 0;
                let tongN = 0;
                return (
                  <React.Fragment key={absent.id}>
                    <tr>
                      <td
                        className="align-middle"
                        rowSpan={absent.absent.length}
                      >
                        {absent.username}
                      </td>
                      <td
                        className="text-center align-middle"
                        rowSpan={absent.absent.length}
                      >
                        {" "}
                        {absent.class_name}
                      </td>
                      <td
                        className="text-center align-middle"
                        style={{ width: "80px" }}
                        rowSpan={absent.absent.length}
                      >
                        {absent.absent.length}
                      </td>
                      {/* Ngày nghỉ có phép */}
                      <td>
                        {absent.absent[0]?.is_meal == 0
                          ? absent.absent[0]?.is_term == 0
                            ? ""
                            : this.getDate(absent.absent[0]?.meal_day)
                          : ""}
                      </td>
                      {/* <td style={{ width: "150px" }}>
													{absent.absent[0]?.is_meal == 0 ?
														absent.absent[0]?.is_term == 0 ? "" : (absent.absent[0]?.type_meal == 1 ? "Nguyên ngày" : absent.absent[0]?.detail.map((item, indexx) =>
															item.time_name + ((indexx + 1 == absent.absent[0]?.detail.length) ? "" : ", "))
														)
														:
														(absent.absent[0]?.type_meal == 1 ? "" : absent.absent[0]?.detail.map((item, index) => item.time_name + ((index + 1 == absent.absent[0]?.detail.length) ? "" : ", ")))

													}
												</td> */}
                      <td>
                        <span
                          style={{
                            width: "140px",
                            // whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "block",
                          }}
                          title={
                            absent.absent[0]?.is_meal == 0
                              ? absent.absent[0]?.is_term == 0
                                ? ""
                                : absent.absent[0].note
                              : ""
                          }
                        >
                          {absent.absent[0]?.is_meal == 0
                            ? absent.absent[0]?.is_term == 0
                              ? ""
                              : absent.absent[0].note
                            : ""}
                        </span>
                      </td>
                      <td className="text-end align-middle">
                        {this.formatNumber(
                          absent.absent[0]?.is_term == 0
                            ? ""
                            : absent.absent[0]?.type_meal == 1
                            ? absent.absent[0]?.is_meal == 1
                              ? ""
                              : absent.absent[0]?.price_return
                            : this.Emaxlement(
                                absent.absent[0]?.detail.map((item, index) => {
                                  tongN =
                                    parseInt(tongN) +
                                    parseInt(item.price_return);
                                  tongAll = tongAll + tongN;
                                  return index + 1 ==
                                    absent.absent[0]?.detail.length
                                    ? tongN
                                    : "";
                                })
                              )
                        )}
                      </td>
                      <td
                        rowSpan={absent.absent.length}
                        className="text-end align-middle"
                      >
                        {absent.absent?.filter((e) => e.is_term != 0)?.length >
                        0
                          ? absent.absent?.filter((e) => e.is_term != 0)?.length
                          : ""}
                      </td>
                      <td
                        rowSpan={absent.absent.length}
                        className="text-end align-middle"
                      >
                        {
                          // this.formatNumber
                          this.formatNumber(
                            this.Emaxlement(
                              absent.absent.map((item, index_) => {
                                if (item.is_meal == 0) {
                                  if (item.is_term == 1) {
                                    if (item.type_meal == 1) {
                                      tong = tong + item.price_return;
                                    } else {
                                      item.detail?.map((i, index) => {
                                        tong =
                                          parseInt(tong) +
                                          parseInt(i.price_return);
                                        tongAll = tongAll + tong;
                                        return index + 1 == item.detail.length
                                          ? tong
                                          : 0;
                                      });
                                    }
                                    return index_ + 1 == absent.absent.length
                                      ? tong
                                      : 0;
                                  } else return tong;
                                } else return 0;
                              })
                            )
                          )
                        }
                      </td>
                      {/* Ngày nghỉ không phép */}
                      <td>
                        {absent.absent[0]?.is_meal == 1
                          ? this.getDate(absent.absent[0]?.meal_day)
                          : absent.absent[0]?.is_term == 0
                          ? this.getDate(absent.absent[0]?.meal_day)
                          : ""}
                      </td>
                      {/* <td style={{ width: "150px" }}>
													{absent.absent[0]?.is_meal == 1 ?
														(absent.absent[0]?.type_meal == 1 ? "Nguyên ngày" : absent.absent[0]?.detail.map((item, index) => item.time_name + ((index + 1 == absent.absent[0]?.detail.length) ? "" : ", "))) :
														absent.absent[0]?.is_term == 0 ? (absent.absent[0]?.type_meal == 1 ? "Nguyên ngày" : absent.absent[0]?.detail.map((item, index) => item.time_name + ((index + 1 == absent.absent[0]?.detail.length) ? "" : ", "))) :
															""}
												</td> */}
                      <td>
                        {absent.absent[0]?.is_meal == 1
                          ? ` (Báo trễ: ${this.getDateTime(
                              absent.absent[0].created_at
                            )} )`
                          : absent.absent[0]?.is_term == 0
                          ? ` (Báo trễ: ${this.getDateTime(
                              absent.absent[0].created_at
                            )} )`
                          : ""}
                      </td>
                      <td
                        rowSpan={absent.absent.length}
                        className="text-end align-middle"
                      >
                        {absent.absent?.filter((e) => e.is_term == 0)?.length >
                        0
                          ? absent.absent?.filter((e) => e.is_term == 0)?.length
                          : ""}
                      </td>
                    </tr>
                    {/* Cắt nhìu */}
                    {absent.absent
                      .slice(1, absent.absent.length)
                      ?.map((dayAbsent, index) => {
                        let tongT = 0;
                        return (
                          <tr key={index}>
                            <td>
                              {
                                // absent.absent[0]?.is_term == 0 ? "" :
                                // this.getDate(absent.absent[0]?.meal_day) : ""
                                dayAbsent.is_meal == 0
                                  ? dayAbsent.is_term == 0
                                    ? ""
                                    : this.getDate(dayAbsent.meal_day)
                                  : ""
                              }
                            </td>
                            {/* <td>{dayAbsent.is_meal == 0 ?
																dayAbsent.is_term == 0 ?
																	"" :
																	dayAbsent.type_meal == 1 ? "Nguyên ngày" :
																		dayAbsent.detail?.map((item, index) => item.time_name + ((index + 1 == dayAbsent.detail.length) ? "" : ", ")) : ""} </td> */}
                            <td>
                              <span
                                style={{
                                  width: "140px",
                                  // whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  display: "block",
                                }}
                                title={
                                  dayAbsent.is_meal == 0
                                    ? dayAbsent?.is_term == 0
                                      ? ""
                                      : dayAbsent.note
                                    : ""
                                }
                              >
                                {dayAbsent.is_meal == 0
                                  ? dayAbsent?.is_term == 0
                                    ? ""
                                    : dayAbsent.note
                                  : ""}
                              </span>
                            </td>

                            <td className="text-end align-middle">
                              {this.formatNumber(
                                dayAbsent?.is_term == 0
                                  ? ""
                                  : dayAbsent.type_meal == 1
                                  ? dayAbsent.is_meal == 1
                                    ? ""
                                    : dayAbsent.price_return
                                  : this.Emaxlement(
                                      dayAbsent.detail.map((item, index) => {
                                        tongT =
                                          parseInt(tongT) +
                                          parseInt(item.price_return);
                                        tongAll = tongAll + tongT;
                                        return index + 1 ==
                                          dayAbsent.detail.length
                                          ? tongT
                                          : "";
                                      })
                                    )
                              )}
                            </td>

                            {/* //Ngày nghỉ không phép */}

                            <td>
                              {" "}
                              {
                                dayAbsent.is_meal == 1
                                  ? this.getDate(dayAbsent.meal_day)
                                  : dayAbsent.is_term == 0
                                  ? this.getDate(dayAbsent.meal_day)
                                  : ""

                                // : ""
                              }
                            </td>
                            {/* <td>
																<td>{dayAbsent.is_meal == 1 ?
																	dayAbsent.type_meal == 1 ? "Nguyên ngày" :
																		dayAbsent.detail?.map((item, index) => item.time_name + ((index + 1 == dayAbsent.detail.length) ? "" : ", ")) :
																	dayAbsent.is_term == 0 ? (dayAbsent.type_meal == 1 ? "Nguyên ngày" : dayAbsent?.detail.map((item, index) => item.time_name + ((index + 1 == dayAbsent?.detail.length) ? "" : ", "))) :
																		""

																} </td>
															</td> */}
                            <td>
                              {dayAbsent.is_meal == 1
                                ? ` (Báo trễ: ${this.getDateTime(
                                    dayAbsent.created_at
                                  )})`
                                : dayAbsent?.is_term == 0
                                ? ` (Báo trễ: ${this.getDateTime(
                                    dayAbsent.created_at
                                  )} )`
                                : ""}
                            </td>
                          </tr>
                        );
                      })}
                    {/* ---- */}
                  </React.Fragment>
                );
              }
            })}
            <tr>
              <th colSpan={7} className="text-center">
                Tổng
              </th>
              <th className="text-end">
                <CurrencyFormat
                  value={this.getTong()}
                  thousandSeparator
                  className="text-end"
                  displayType="text"
                />
              </th>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        </Table>

        <Table bordered id="CutUserTable" className="d-none">
          <thead>
            <tr>
              <th className="text-center">
                {" "}
                DANH SÁCH HỌC SINH CẮT SUẤT ĂN (
                {this.getDate_(this.state.startDate)} -{" "}
                {this.getDate_(this.state.endDate)})
              </th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
            </tr>
            <tr>
              <th className="align-middle text-center">Họ và tên</th>
              <th className="align-middle text-center">Lớp</th>
              <th
                className="align-middle text-center"
                style={{ width: "80px" }}
              >
                {" "}
                SL ngày cắt suất
              </th>
              <th className="text-center">Ngày nghỉ có phép</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>

              <th className="text-center">Ngày nghỉ không phép</th>
              <th>empty</th>
              <th>empty</th>
            </tr>
            <tr>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>

              <th className="text-center"> Ngày</th>

              <th className="text-center"> Lý do</th>
              <th className="text-center">Thành tiền</th>
              <th className="text-center"> SL</th>
              <th className="text-center">Tổng</th>
              <th className="text-center"> Ngày</th>

              <th className="text-center"> Lý do</th>
              <th className="text-center"> SL</th>
            </tr>
          </thead>
          <tbody>
            {this.state.listStudent.map((absent) => {
              let tongtien = 0;
              var tongAll = 0;
              this.state.listSetting.map((item) =>
                item.is_active == 1
                  ? (tongtien = tongtien + parseInt(item.poetry))
                  : null
              );
              if (absent.absent.length > 0) {
                let tong = 0;
                let tongN = 0;
                return (
                  <React.Fragment key={absent.id}>
                    <tr>
                      <td className="align-middle">{absent.username}</td>
                      <td className="text-center align-middle">
                        {absent.class_name}
                      </td>
                      <td
                        className="text-center align-middle"
                        style={{ width: "80px" }}
                      >
                        {absent.absent.length}
                      </td>
                      {/* Ngày nghỉ có phép */}
                      <td>
                        {absent.absent[0]?.is_meal == 0
                          ? absent.absent[0]?.is_term == 0
                            ? "empty"
                            : `xy${this.getDate(absent.absent[0]?.meal_day)}`
                          : "empty"}
                      </td>
                      {/* <td style={{ width: "150px" }}>
													{
														absent.absent[0]?.is_meal == 0
															? absent.absent[0]?.is_term == 0
																? "empty"
																: (absent.absent[0]?.type_meal == 1
																	? "Nguyên ngày"
																	: absent.absent[0]?.detail.map((item, indexx) => item.time_name + ((indexx + 1 == absent.absent[0]?.detail.length)
																		? ""
																		: ", ")))
															: (absent.absent[0]?.type_meal == 1
																? "empty"
																: absent.absent[0]?.detail.map((item, index) => item.time_name + ((index + 1 == absent.absent[0]?.detail.length)
																	? ""
																	: ", ")))
													}
												</td> */}
                      <td>
                        {absent.absent[0]?.is_meal == 0
                          ? absent.absent[0]?.is_term == 0
                            ? "empty"
                            : absent.absent[0].note
                          : "empty"}
                      </td>

                      <td className="text-end align-middle">
                        {this.formatNumber(
                          absent.absent[0]?.is_term == 0
                            ? "empty"
                            : absent.absent[0]?.type_meal == 1
                            ? absent.absent[0]?.is_meal == 1
                              ? "empty"
                              : absent.absent[0]?.price_return
                            : this.Emaxlement(
                                absent.absent[0]?.detail.map((item, index) => {
                                  tongN =
                                    parseInt(tongN) +
                                    parseInt(item.price_return);
                                  tongAll = tongAll + tongN;
                                  return index + 1 ==
                                    absent.absent[0]?.detail.length
                                    ? tongN
                                    : "empty";
                                })
                              )
                        )}
                      </td>
                      <td className="text-end align-middle">
                        {absent.absent?.filter((e) => e.is_term != 0)?.length >
                        0
                          ? absent.absent?.filter((e) => e.is_term != 0)?.length
                          : "empty"}
                      </td>
                      <td className="text-end align-middle">
                        {this.formatNumber(
                          this.Emaxlement(
                            absent.absent.map((item, index_) => {
                              if (item.is_meal == 0) {
                                if (item.is_term == 1) {
                                  if (item.type_meal == 1) {
                                    tong = tong + item.price_return;
                                  } else {
                                    item.detail?.map((i, index) => {
                                      tong =
                                        parseInt(tong) +
                                        parseInt(i.price_return);
                                      tongAll = tongAll + tong;
                                      return index + 1 == item.detail.length
                                        ? tong
                                        : 0;
                                    });
                                  }
                                  return index_ + 1 == absent.absent.length
                                    ? tong
                                    : 0;
                                } else return tong;
                              } else return 0;
                            })
                          )
                        )}
                      </td>

                      {/* Ngày nghỉ không phép */}
                      <td>
                        {absent.absent[0]?.is_meal == 1
                          ? `xy${this.getDate(absent.absent[0]?.meal_day)}`
                          : absent.absent[0]?.is_term == 0
                          ? `xy${this.getDate(absent.absent[0]?.meal_day)}`
                          : "empty"}
                      </td>
                      {/* <td style={{ width: "150px" }}>
													{absent.absent[0]?.is_meal == 1 ?
														(absent.absent[0]?.type_meal == 1 ? "Nguyên ngày" : absent.absent[0]?.detail.map((item, index) => item.time_name + ((index + 1 == absent.absent[0]?.detail.length) ? "" : ", "))) :
														absent.absent[0]?.is_term == 0 ? (absent.absent[0]?.type_meal == 1 ? "Nguyên ngày" : absent.absent[0]?.detail.map((item, index) => item.time_name + ((index + 1 == absent.absent[0]?.detail.length) ? "" : ", "))) :
															"empty"}
												</td> */}
                      <td>
                        {absent.absent[0]?.is_meal == 1
                          ? ` (Báo trễ: ${this.getDateTime(
                              absent.absent[0].created_at
                            )} )`
                          : absent.absent[0]?.is_term == 0
                          ? ` (Báo trễ: ${this.getDateTime(
                              absent.absent[0].created_at
                            )} )`
                          : "empty"}
                      </td>
                      <td className="text-end align-middle">
                        {absent.absent?.filter((e) => e.is_term == 0)?.length >
                        0
                          ? absent.absent?.filter((e) => e.is_term == 0)?.length
                          : "empty"}
                      </td>
                    </tr>
                    {/* Cắt nhìu */}
                    {absent.absent
                      .slice(1, absent.absent.length)
                      ?.map((dayAbsent, index) => {
                        let tongT = 0;
                        return (
                          <tr key={index}>
                            <td>empty</td>
                            <td>empty</td>
                            <td>empty</td>

                            {/* Ngày nghỉ có phép */}
                            <td>
                              {dayAbsent.is_meal == 0
                                ? dayAbsent.is_term == 0
                                  ? "empty"
                                  : `xy${this.getDate(dayAbsent.meal_day)}`
                                : "empty"}
                            </td>
                            {/* <td>
																{dayAbsent.is_meal == 0 ?
																	dayAbsent.is_term == 0 ?
																		"empty" :
																		dayAbsent.type_meal == 1 ? "Nguyên ngày" :
																			dayAbsent.detail?.map((item, index) => item.time_name + ((index + 1 == dayAbsent.detail.length) ? "" : ", ")) : "empty"}
															</td> */}
                            <td>
                              {dayAbsent.is_meal == 0
                                ? dayAbsent?.is_term == 0
                                  ? "empty"
                                  : dayAbsent.note
                                : "empty"}
                            </td>
                            <td className="text-end align-middle">
                              {this.formatNumber(
                                dayAbsent?.is_term == 0
                                  ? "empty"
                                  : dayAbsent.type_meal == 1
                                  ? dayAbsent.is_meal == 1
                                    ? "empty"
                                    : dayAbsent.price_return
                                  : this.Emaxlement(
                                      dayAbsent.detail.map((item, index) => {
                                        tongT =
                                          parseInt(tongT) +
                                          parseInt(item.price_return);
                                        tongAll = tongAll + tongT;
                                        return index + 1 ==
                                          dayAbsent.detail.length
                                          ? tongT
                                          : "empty";
                                      })
                                    )
                              )}
                            </td>
                            <td>empty</td>

                            <td>empty</td>

                            {/* //Ngày nghỉ không phép */}
                            <td>
                              {dayAbsent.is_meal == 1
                                ? ` xy${this.getDate(dayAbsent.meal_day)}`
                                : dayAbsent.is_term == 0
                                ? ` xy${this.getDate(dayAbsent.meal_day)}`
                                : "empty"}
                            </td>
                            {/* <td>
																{dayAbsent.is_meal == 1 ?
																	dayAbsent.type_meal == 1 ? "Nguyên ngày" :
																		dayAbsent.detail?.map((item, index) => item.time_name + ((index + 1 == dayAbsent.detail.length) ? "" : ", ")) :
																	dayAbsent.is_term == 0 ? (dayAbsent.type_meal == 1 ? "Nguyên ngày" : dayAbsent?.detail.map((item, index) => item.time_name + ((index + 1 == dayAbsent?.detail.length) ? "" : ", "))) :
																		"empty"
																}
															</td> */}
                            <td>
                              {dayAbsent.is_meal == 1
                                ? ` (Báo trễ: ${this.getDateTime(
                                    dayAbsent.created_at
                                  )})`
                                : dayAbsent?.is_term == 0
                                ? ` (Báo trễ: ${this.getDateTime(
                                    dayAbsent.created_at
                                  )} )`
                                : "empty"}
                            </td>
                            <td>empty</td>
                          </tr>
                        );
                      })}
                    {/* ---- */}
                  </React.Fragment>
                );
              }
            })}
            <tr>
              <th className="text-center">Tổng</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th className="text-end">
                <CurrencyFormat
                  value={this.getTong()}
                  thousandSeparator
                  className="text-end"
                  displayType="text"
                />
              </th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
            </tr>
          </tbody>
        </Table>
      </div>
    );
  }

  getDateTime = (data) => {
    let dateTime = new Date(data * 1000);
    return `${dateTime.getDate()}/${
      dateTime.getMonth() + 1
    }/${dateTime.getFullYear()} ${dateTime.getHours()}:${dateTime.getMinutes()}:${dateTime.getSeconds()}`;
  };
  xport = () => {
    const table = document.getElementById("CutUserTable");
    const wb = XLSX.utils.table_to_book(table);
    var ws = wb.Sheets["Sheet1"];

    const colAlpha = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K"];

    ws[`A1`].s = {
      font: {
        name: "Times New Roman",
        sz: 16,
        bold: true,
      },
      alignment: {
        wrapText: true,
        vertical: "center",
        horizontal: "center",
      },
    };

    colAlpha.map((alpha) => {
      ws[`${alpha}2`].s = {
        font: {
          name: "Times New Roman",
          sz: 11,
          bold: true,
        },
        alignment: {
          wrapText: true,
          vertical: "center",
          horizontal: "center",
        },
        border: {
          top: { style: "thin" },
          bottom: { style: "thin" },
          left: { style: "thin" },
          right: { style: "thin" },
        },
      };

      return alpha;
    });

    colAlpha.map((alpha) => {
      ws[`${alpha}3`].s = {
        font: {
          name: "Times New Roman",
          sz: 11,
          bold: true,
        },
        alignment: {
          wrapText: true,
          vertical: "center",
          horizontal: "center",
        },
        border: {
          top: { style: "thin" },
          bottom: { style: "thin" },
          left: { style: "thin" },
          right: { style: "thin" },
        },
      };

      return alpha;
    });

    // Body
    let absentIndex = 0;
    this.state.listStudent.map((student) => {
      absentIndex += student.absent.length;

      return student;
    });

    for (let i = 4; i < absentIndex + 4; i++) {
      if (ws[`D${i}`].v != "empty") ws[`D${i}`].v = ws[`D${i}`].v?.substring(2);
      if (ws[`I${i}`].v != "empty") ws[`I${i}`].v = ws[`I${i}`].v?.substring(2);
      colAlpha.map((alpha) => {
        if (ws[`${alpha}${i}`].v === "empty") {
          ws[`${alpha}${i}`].v = " ";
        }

        if (alpha === "A" || alpha === "B" || alpha === "E" || alpha === "J") {
          ws[`${alpha}${i}`].s = {
            font: {
              name: "Times New Roman",
              sz: 11,
            },
            alignment: {
              wrapText: true,
              vertical: "center",
              horizontal: "left",
            },
            border: {
              top: { style: "thin" },
              bottom: { style: "thin" },
              left: { style: "thin" },
              right: { style: "thin" },
            },
          };
        } else if (alpha === "H" || alpha === "F") {
          ws[`${alpha}${i}`].s = {
            font: {
              name: "Times New Roman",
              sz: 11,
            },
            alignment: {
              wrapText: true,
              vertical: "center",
              horizontal: "right",
            },
            border: {
              top: { style: "thin" },
              bottom: { style: "thin" },
              left: { style: "thin" },
              right: { style: "thin" },
            },
          };
        } else {
          ws[`${alpha}${i}`].s = {
            font: {
              name: "Times New Roman",
              sz: 11,
            },
            alignment: {
              wrapText: true,
              vertical: "center",
              horizontal: "center",
            },
            border: {
              top: { style: "thin" },
              bottom: { style: "thin" },
              left: { style: "thin" },
              right: { style: "thin" },
            },
          };
        }

        return alpha;
      });

      ws[`G${i}`].z = "#,###,###,###";
      delete ws[`G${i}`].w;
      XLSX.utils.format_cell(ws[`G${i}`]);

      ws[`H${i}`].z = "#,###,###,###";
      delete ws[`H${i}`].w;
      XLSX.utils.format_cell(ws[`H${i}`]);

      ws[`F${i}`].z = "#,###,###,###";
      delete ws[`F${i}`].w;
      XLSX.utils.format_cell(ws[`F${i}`]);
    }
    // Body

    colAlpha.map((alpha) => {
      if (ws[`${alpha}${absentIndex + 4}`].v === "empty") {
        ws[`${alpha}${absentIndex + 4}`].v = " ";
      }

      ws[`${alpha}${absentIndex + 4}`].s = {
        font: {
          name: "Times New Roman",
          sz: 11,
          bold: true,
        },
        alignment: {
          wrapText: true,
          vertical: "center",
          horizontal: alpha === "H" ? "right" : "center",
        },
        border: {
          top: { style: "thin" },
          bottom: { style: "thin" },
          left: { style: "thin" },
          right: { style: "thin" },
        },
      };

      return alpha;
    });

    ws[`H${absentIndex + 4}`].z = "#,###,###,###";
    delete ws[`H${absentIndex + 4}`].w;
    XLSX.utils.format_cell(ws[`H${absentIndex + 4}`]);

    ws["!cols"] = [
      { wch: 15 },
      { wch: 10 },
      { wch: 8 },

      { wch: 9 },
      { wch: 11 },
      { wch: 11 },
      { wch: 5 },
      { wch: 10 },

      { wch: 9 },
      { wch: 16.5 },
      { wch: 5 },
    ];

    ws["!rows"] = [{ hpt: 30 }];

    const merge = [
      { s: { r: 0, c: 0 }, e: { r: 0, c: 10 } },

      { s: { r: 1, c: 0 }, e: { r: 2, c: 0 } },
      { s: { r: 1, c: 1 }, e: { r: 2, c: 1 } },
      { s: { r: 1, c: 2 }, e: { r: 2, c: 2 } },

      { s: { r: 1, c: 3 }, e: { r: 1, c: 7 } },
      { s: { r: 1, c: 8 }, e: { r: 1, c: 10 } },

      { s: { r: absentIndex + 3, c: 0 }, e: { r: absentIndex + 3, c: 6 } },
    ];

    let absentIndex2 = 3;

    this.state.listStudent.map((student) => {
      if (student.absent.length === 0) {
        // absentIndex2 += 1;

        return student;
      }

      merge.push({
        s: { r: absentIndex2, c: 0 },
        e: { r: absentIndex2 + student.absent.length - 1, c: 0 },
      });
      merge.push({
        s: { r: absentIndex2, c: 1 },
        e: { r: absentIndex2 + student.absent.length - 1, c: 1 },
      });
      merge.push({
        s: { r: absentIndex2, c: 2 },
        e: { r: absentIndex2 + student.absent.length - 1, c: 2 },
      });
      merge.push({
        s: { r: absentIndex2, c: 6 },
        e: { r: absentIndex2 + student.absent.length - 1, c: 6 },
      });
      merge.push({
        s: { r: absentIndex2, c: 10 },
        e: { r: absentIndex2 + student.absent.length - 1, c: 10 },
      });

      merge.push({
        s: { r: absentIndex2, c: 7 },
        e: { r: absentIndex2 + student.absent.length - 1, c: 7 },
      });

      absentIndex2 += student.absent.length;

      return student;
    });

    ws["!merges"] = merge;

    XLSX.writeFile(wb, "Danh sách cắt suất.xlsx");
  };
  handleExport = () => {
    swal({
      title: `Xác nhận`,
      text: `Xuất phiếu danh sách cắt suất ${this.getDate_(
        this.state.startDate
      )} -  ${this.getDate_(this.state.endDate)}`,
      icon: "info",
      buttons: ["Đóng", "Xuất"],
      successMode: true,
    }).then((ok) => {
      if (ok) {
        this.xport();
      }
    });
  };
  render() {
    return (
      <>
        <Container fluid>
          <Breadcrumb>
            <Breadcrumb.Item active>Báo cáo</Breadcrumb.Item>
            <Breadcrumb.Item active>Chi tiết cắt suất ăn</Breadcrumb.Item>
          </Breadcrumb>
          <Card>
            <Card.Header>
              <Card.Title>
                <i className="fas fa-list me-1"></i>Danh sách suất ăn{" "}
                {this.state.startDate
                  ? this.getDate_(this.state.startDate)
                  : ""}{" "}
                -{" "}
                {this.state.endDate ? this.getDate_(this.state.endDate) : "--"}{" "}
                <Button
                  size="sm"
                  onClick={() => {
                    this.setState({ show: true });
                  }}
                >
                  <i className="fa-solid fa-calendar-days"> </i>{" "}
                </Button>
                <Button
                  variant="success"
                  size="sm"
                  style={{ marginLeft: "5px", float: "right" }}
                  onClick={() => this.handleExport()}
                >
                  Xuất danh sách cắt suất ăn{}
                  <i className="fa-solid fa-file-export"></i>
                </Button>
              </Card.Title>
            </Card.Header>
            <Card.Body>{this.renderCutMealListTable()}</Card.Body>
          </Card>
        </Container>
        <Modal show={this.state.show} size={"sm"}>
          <Modal.Header>
            <Modal.Title>Chọn thời gian</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <center>
              <DatePicker
                size={{ height: "600px" }}
                selected={this.state.start_}
                onChange={(update) => {
                  this.setState({ start_: update[0] });
                  this.setState({ end_: update[1] });
                }}
                startDate={this.state.start_}
                endDate={this.state.end_}
                calendarStartDay={1}
                selectsRange
                inline
              />
            </center>
            <br />
          </Modal.Body>
          <Modal.Footer>
            <Button
              size="sm"
              onClick={() => {
                this.setState({ show: false });
                this.getListMealstudent(
                  this.state.startDate,
                  this.state.endDate
                );
              }}
            >
              Hủy
            </Button>
            <Button
              size="sm"
              disabled={this.state.start_ === null || this.state.end_ === null}
              onClick={() => {
                this.setState({ show: false });
                this.setState({ startDate: this.state.start_ });
                this.setState({ endDate: this.state.end_ });
                this.getListMealstudent(this.state.start_, this.state.end_);
              }}
            >
              Lưu
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default ListCut;
