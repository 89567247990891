import React, { Component } from "react";
import { Card, Table, Button } from "react-bootstrap";
import axios from "axios";
import CurrencyFormat from "react-currency-format";
import * as XLSX from "xlsx-js-style";

class FinanceMonthBook extends Component {
  constructor(props) {
    super(props);
    this.state = {
      schoolInfo: {},

      listVoucher: [],
      listmealbyclass: [],

      listStudent: [],

      takeMoney: 0,

      showModal: false,
      isLoading: false,

      datesInMonth: [],
      currentTime: new Date(),
      groups: [],
      listCut: [],
    };
  }

  async componentDidMount() {
    const date = new Date();

    const listDate = this.getDaysInMonth(date.getMonth(), date.getFullYear());

    this.getListVoucher(listDate[0], listDate[listDate.length - 1]);
    this.getListMealByClassData(listDate[0], listDate[listDate.length - 1]);
    this.getStudentData();
    this.getlistsetting();
    this.getDetailschool();
    // this.getSetting();
    this.getlistCutAll(listDate[0], listDate[listDate.length - 1]);
    this.setState({
      datesInMonth: listDate,
      currentTime: new Date(),
    });
  }

  //#region Fetch API
  getDetailschool = () => {
    axios.get(`/detailSchool`).then((res) => {
      if (res.data.status === true) {
        this.setState({
          schoolInfo: res.data.data[0],
        });
      }
    });
  };
  getlistCutAll = (startDate, endDate) => {
    let day =
      endDate < new Date()
        ? endDate
        : startDate > new Date()
        ? startDate
        : new Date();
    let start = startDate.setHours(0, 0, 0) / 1000;
    let end = day.setHours(23, 59, 59) / 1000;
    this.getSetting(parseInt(end));
    axios
      .get(
        `/report-class?start_date=${parseInt(start)}&end_date=${parseInt(end)}`
      )
      .then((res) => {
        this.setState({ listCut: res.data.data });
      });
  };
  countTotalStudentsPresent = (listCut) => {
    let total = 0;
    listCut.forEach((day) => {
      day?.class?.forEach((classItem) => {
        const presentStudents =
          classItem.countStudent - classItem.countStudentOff;
        total += presentStudents;
      });
    });

    return total;
  };
  getListVoucher = (startDate, endDate) => {
    this.setState({
      listVoucher: [],
    });

    let start = startDate.setHours(0, 0, 0) / 1000;
    let end = endDate.setHours(23, 59, 59) / 1000;
    //
    axios
      .get(`/voucher-show?start_at=${start}&term_at=${end}`)
      // axios.get(`/viewVoucher?type_voucher=1&start_at=${startDate.getTime() / 1000}&term_at=${endDate.getTime() / 1000}`)
      .then((res) => {
        if (res.data.errCode === 0) {
          this.setState({
            // listVoucher: res.data.data.filter(item => Number(item.id_classify) === 1),
            listVoucher: res.data.vouchers,
          });
        }
      });
  };

  getlistsetting = () => {
    axios
      .get(`/viewSetting?id_classify=1`)
      .then((res) => {
        if (res.data.status === true) {
          this.setState({
            takeMoney: Number(res.data.data.main[0].price_proceed),
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getListMealByClassData(startDate, endDate) {
    const start = startDate.setHours(0, 0, 0, 0) / 1000;
    const end = endDate.setHours(23, 59, 59, 0) / 1000;

    this.setState({
      isLoading: true,
    });

    axios
      .post(
        `/viewMeal?id_classify=1&start_date=${parseInt(start)}&end_date=${end}`
      )
      // .post(`/viewMeal?id_classify=1`)
      .then((res) => {
        if (res.data.status) {
          this.setState({
            listmealbyclass: res.data.data.filter(
              (mealCut) =>
                // start <= mealCut.meal_day
                // && mealCut.meal_day <= end
                // &&
                Number(mealCut.type_meal) === 1 &&
                Number(mealCut.is_status) === 1
            ),
          });
        }

        this.setState({
          isLoading: false,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }
  getSetting = async (date) => {
    await axios
      .get(`/setting-show?setting_date=${date}`)
      .then((res) => {
        if (res.data.errCode === 0) {
          const groups = res.data.setting.groups.map((item) => ({
            ...item,
            countStudent: 0,
            countStudentOff: 0,
          }));

          this.setState({ groups: groups });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getStudentData() {
    axios
      .get("/viewStudent")
      .then((res) => {
        if (res.data.status) {
          this.setState({
            listStudent: res.data.data.filter(
              (student) => student.id_class !== 0 && student.is_meal !== 1
            ),
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  //#endregion

  //#region Handle Logic
  xport = () => {
    const wb = XLSX.utils.book_new();

    const ws = this.xportFinanceMonthBook();

    XLSX.utils.book_append_sheet(wb, ws, "Bảng công khai chi chợ tháng");

    XLSX.writeFile(wb, "Bảng công khai chi chợ tháng.xlsx");
  };

  xportFinanceMonthBook = () => {
    const table = document.getElementById("finance-month-book");

    var ws = XLSX.utils.table_to_sheet(table, { raw: true });

    const colAlpha = [
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
    ];

    const borderStyle = {
      top: { style: "thin" },
      bottom: { style: "thin" },
      left: { style: "thin" },
      right: { style: "thin" },
    };

    // Style cell
    colAlpha.map((alpha) => {
      ws[`${alpha}1`].s = {
        font: {
          name: "Times New Roman",
          bold: true,
        },
        alignment: {
          vertical: "center",
          horizontal: "start",
        },
      };

      return alpha;
    });

    colAlpha.map((alpha) => {
      ws[`${alpha}2`].s = {
        font: {
          name: "Times New Roman",
          sz: 16,
          bold: true,
        },
        alignment: {
          vertical: "center",
          horizontal: "center",
        },
      };

      return alpha;
    });
    colAlpha.map((alpha) => {
      ws[`${alpha}3`].s = {
        font: {
          name: "Times New Roman",
        },
        alignment: {
          vertical: alpha == "I" ? "top" : "center",
          horizontal: alpha == "I" ? "start" : "center",
          wrapText: true,
        },
      };

      return alpha;
    });

    colAlpha.map((alpha) => {
      ws[`${alpha}4`].s = {
        font: {
          name: "Times New Roman",
          bold: true,
        },
        border: borderStyle,
        alignment: {
          vertical: "center",
          horizontal: "center",
          wrapText: true,
        },
      };

      return alpha;
    });

    let ingreBigArray = [];

    this.state.listVoucher.map((voucherItem) => {
      ingreBigArray = ingreBigArray.concat(voucherItem.ingredents);
      return voucherItem;
    });
    const aIngredientArray = this.group(ingreBigArray, "id_ingredent");

    const storeArrays =
      aIngredientArray.filter((item) => item[0].ingredent_type === 2) || [];
    const marketArrays =
      aIngredientArray.filter((item) => item[0].ingredent_type === 1) || [];

    let bigArray = [];

    const rows = [
      {},
      { hpt: 50 },
      { hpt: this.state?.groups?.length * 17 },
      { hpt: 30 },
    ];

    if (storeArrays.length > marketArrays.length) {
      bigArray = storeArrays;
    } else {
      bigArray = marketArrays;
    }

    for (let i = 0; i < bigArray.length + 5; i++) {
      colAlpha.map((alpha) => {
        if (ws[`${alpha}${5 + i}`].v === "empty") {
          ws[`${alpha}${5 + i}`].v = " ";
        }

        if (alpha === "A" || alpha === "G") {
          ws[`${alpha}${5 + i}`].s = {
            font: {
              name: "Times New Roman",
            },
            border: borderStyle,
            alignment: {
              vertical: "center",
              horizontal: "center",
            },
          };
        }

        if (alpha === "B" || alpha === "H" || alpha === "C" || alpha === "I") {
          ws[`${alpha}${5 + i}`].s = {
            font: {
              name: "Times New Roman",
            },
            border: borderStyle,
            alignment: {
              vertical: "center",
              horizontal: "left",
            },
          };
        }

        if (
          alpha === "D" ||
          alpha === "E" ||
          alpha === "F" ||
          alpha === "J" ||
          alpha === "K" ||
          alpha === "L"
        ) {
          ws[`${alpha}${5 + i}`].s = {
            font: {
              name: "Times New Roman",
            },
            border: borderStyle,
            alignment: {
              vertical: "center",
              horizontal: "right",
            },
          };
        }

        return alpha;
      });

      rows.push({});
    }

    // Near last row
    colAlpha.map((alpha) => {
      if (alpha === "A" || alpha === "G") {
        ws[`${alpha}${5 + bigArray.length}`].s = {
          font: {
            name: "Times New Roman",
            bold: true,
          },
          border: borderStyle,
          alignment: {
            vertical: "center",
            horizontal: "center",
          },
        };
      } else if (alpha === "F" || alpha === "L") {
        ws[`${alpha}${5 + bigArray.length}`].s = {
          font: {
            name: "Times New Roman",
            bold: true,
          },
          border: borderStyle,
          alignment: {
            vertical: "center",
            horizontal: "right",
          },
        };
      } else {
        ws[`${alpha}${5 + bigArray.length}`].s = {
          font: {
            name: "Times New Roman",
          },
          border: borderStyle,
          alignment: {
            vertical: "center",
            horizontal: "center",
          },
        };
      }

      return alpha;
    });

    rows.push({});

    // Last row
    for (let i = 0; i < 4; i++) {
      colAlpha.map((alpha) => {
        if (alpha === "A" || alpha === "D" || alpha === "G" || alpha === "J") {
          ws[`${alpha}${5 + bigArray.length + 1 + i}`].s = {
            font: {
              name: "Times New Roman",
              bold: true,
            },
            border: borderStyle,
            alignment: {
              wrapText: true,
            },
          };
        } else if (
          alpha === "C" ||
          alpha === "F" ||
          alpha === "I" ||
          alpha === "L"
        ) {
          ws[`${alpha}${5 + bigArray.length + 1 + i}`].s = {
            font: {
              name: "Times New Roman",
              bold: true,
            },
            border: borderStyle,
            alignment: {
              wrapText: true,
              vertical: "center",
              horizontal: "right",
            },
          };
        } else {
          ws[`${alpha}${5 + bigArray.length + 1 + i}`].s = {
            font: {
              name: "Times New Roman",
            },
            border: borderStyle,
            alignment: {
              wrapText: true,
              vertical: "center",
              horizontal: "center",
            },
          };
        }

        return alpha;
      });
    }

    rows.push({ hpt: 30 });

    ws["!cols"] = [
      { wch: 4 },
      { wch: 14.5 },
      { wch: 10.5 },
      { wch: 9 },
      { wch: 9 },
      { wch: 10.5 },

      { wch: 4 },
      { wch: 14.5 },
      { wch: 10.5 },
      { wch: 9 },
      { wch: 9 },
      { wch: 10.5 },
    ];

    ws["!rows"] = rows;

    const merge = [
      { s: { r: 0, c: 0 }, e: { r: 0, c: 11 } },
      { s: { r: 1, c: 0 }, e: { r: 1, c: 11 } },

      { s: { r: 2, c: 0 }, e: { r: 2, c: 3 } },
      { s: { r: 2, c: 4 }, e: { r: 2, c: 7 } },
      { s: { r: 2, c: 8 }, e: { r: 2, c: 11 } },

      {
        s: { r: 5 + bigArray.length - 1, c: 0 },
        e: { r: 5 + bigArray.length - 1, c: 4 },
      },
      {
        s: { r: 5 + bigArray.length - 1, c: 6 },
        e: { r: 5 + bigArray.length - 1, c: 10 },
      },
    ];
    for (let i = 0; i <= 3; i++) {
      merge.push({
        s: { r: 5 + bigArray.length + i, c: 0 },
        e: { r: 5 + bigArray.length + i, c: 5 },
      });
      merge.push({
        s: { r: 5 + bigArray.length + i, c: 6 },
        e: { r: 5 + bigArray.length + i, c: 10 },
      });
    }
    ws["!merges"] = merge;

    return ws;
  };

  getDaysInMonth = (month, year) => {
    var date = new Date(year, month, 1);
    var days = [];
    while (date.getMonth() === month) {
      days.push(new Date(date));
      date.setDate(date.getDate() + 1);
    }
    return days;
  };

  group = (arr, key) => {
    return [
      ...arr
        .reduce(
          (acc, o) => acc.set(o[key], (acc.get(o[key]) || []).concat(o)),
          new Map()
        )
        .values(),
    ];
  };
  //#endregion
  calculateTotalArray = (A) => {
    const result = {};

    A?.forEach((item) => {
      item?.class?.forEach((classItem) => {
        const { id_group, group_name, countStudent, countStudentOff } =
          classItem;
        if (!result[id_group]) {
          result[id_group] = { id_group, group_name, total: 0 };
        }
        result[id_group].total += countStudent - countStudentOff;
      });
    });
    return Object.values(result);
  };
  calculateTotalPriceMeal = (mealGroup, groups) => {
    let totalPrice = 0;

    // Duyệt qua A_result để kết hợp với B
    mealGroup.forEach((meal) => {
      const matchingGroup = groups.find((group) => group.id === meal.id_group);

      if (matchingGroup) {
        totalPrice += meal.total * parseInt(matchingGroup.price_meal);
      }
    });

    return totalPrice;
  };
  // Render
  RenderFinanceTable() {
    let totalTakeMoney = 0;
    let totalReturnMoney = 0;
    let totalMeal = 0;

    this.state.datesInMonth.map((date) => {
      const start = date.setHours(0, 0, 0, 0) / 1000;
      const end = date.setHours(23, 59, 59, 0) / 1000;

      if (
        !this.state.listVoucher.find(
          (voucher) => Number(voucher.vou_date) === start
        )
      ) {
        return date;
      }

      const mealInDate = [];

      this.state.listmealbyclass.map((mealcut) => {
        if (start <= mealcut.meal_day && mealcut.meal_day <= end) {
          mealInDate.push(mealcut);
        }
        return mealcut;
      });
      let returnMoney = 0;

      mealInDate.map((meal) => {
        if (Number(meal.is_term) === 1) {
          if (Number(meal.type_meal) === 1) {
            returnMoney += Number(meal.price_return);
          } else {
            meal.detail.map((detailItem) => {
              returnMoney += Number(detailItem.price_return);

              return detailItem;
            });
          }
        }
        return meal;
      });

      let totalPerson = this.state.listStudent.length;
      let pauseStudent = 0;

      this.state.listStudent.map((student) => {
        let isStillStudy = false;

        student.process.map((period) => {
          if (period.end_at !== null) {
            if (
              period.start_at <= date.getTime() / 1000 &&
              date.getTime() / 1000 <= period.end_at
            ) {
              isStillStudy = true;
            }
          } else {
            if (period.start_at <= date.getTime() / 1000) {
              isStillStudy = true;
            }
          }
          return period;
        });

        if (!isStillStudy) {
          pauseStudent++;
        }

        return student;
      });

      totalPerson -= pauseStudent;
      let takeMoneyInDay = this.state.takeMoney * totalPerson;
      // báo trễ thì k tính
      totalPerson -= mealInDate.filter(
        (mealCut) => Number(mealCut.type_meal) === 1 && mealCut.is_term === 1
      ).length;
      totalReturnMoney += returnMoney;
      totalTakeMoney += takeMoneyInDay;
      totalMeal += totalPerson;
      return date;
    });

    let ingreBigArray = [];

    this.state.listVoucher.map((voucherItem) => {
      ingreBigArray = ingreBigArray.concat(voucherItem.ingredents);

      return voucherItem;
    });
    const aIngredientArray = this.group(ingreBigArray, "id_ingredent");
    const storeArrays =
      aIngredientArray.filter((item) => item[0].ingredent_type === 2) || [];
    const marketArrays =
      aIngredientArray.filter((item) => item[0].ingredent_type === 1) || [];

    let bigArray = [];

    if (storeArrays.length > marketArrays.length) {
      bigArray = storeArrays;
    } else {
      bigArray = marketArrays;
    }

    let totalStoreMoney = 0;
    let totalMarketMoney = 0;

    return (
      <div style={{ overflowX: "scroll" }}>
        <Table bordered style={{ minWidth: 999 }}>
          <thead>
            <tr className="align-middle">
              <th colSpan={12}>{this.state.schoolInfo.school_name}</th>
            </tr>
            <tr className="text-center align-middle">
              <th colSpan={12}>BẢNG CÔNG KHAI CHI CHỢ</th>
            </tr>
            <tr className="align-middle">
              <th colSpan={4} className="text-center ">
                {" "}
                Tháng {this.state.currentTime.getMonth() + 1} Năm{" "}
                {this.state.currentTime.getFullYear()}{" "}
              </th>
              <th colSpan={4} className="text-center ">
                Tổng số suất ăn:&nbsp;
                <CurrencyFormat
                  value={
                    this.countTotalStudentsPresent(this.state.listCut) || 0
                  }
                  thousandSeparator={true}
                  allowNegative={false}
                  displayType="text"
                />
              </th>
              <th colSpan={4} className="text-end ">
                Định mức chi:&nbsp;
                {/* <CurrencyFormat
                  value={this.state.takeMoney.toFixed(0)}
                  thousandSeparator={true}
                  allowNegative={false}
                  displayType="text"
                />{" "}
                &nbsp; đồng/bé */}
                {this.state?.groups.map((item, index) => {
                  return (
                    <span key={`spendingNorms${index}`}>
                      <span>
                        {item.group_name}:{" "}
                        <CurrencyFormat
                          value={parseInt(item.price_meal)}
                          thousandSeparator={true}
                          allowNegative={false}
                          isNumericString
                          displayType="text"
                        />{" "}
                        / Bé
                      </span>
                      <br />
                    </span>
                  );
                })}
              </th>
            </tr>
            <tr className="text-center">
              <th className="align-middle">STT</th>
              <th className="align-middle">Thực phẩm kho</th>
              <th className="align-middle">ĐVT</th>
              <th className="align-middle">Số lượng</th>
              <th className="align-middle">Đơn giá</th>
              <th className="align-middle">Thành tiền</th>
              <th className="align-middle">STT</th>
              <th className="align-middle">Thực phẩm chợ</th>
              <th className="align-middle">ĐVT</th>
              <th className="align-middle">Số lượng</th>
              <th className="align-middle">Đơn giá</th>
              <th className="align-middle">Thành tiền</th>
            </tr>
          </thead>

          <tbody>
            {bigArray.length !== 0 ? (
              bigArray.map((_, index) => {
                const storeArray = storeArrays[index];
                const marketArray = marketArrays[index];

                let marketMoney = 0;
                let marketAmount = 0;
                let marketAvgPrice = 0;

                let storeMoney = 0;
                let storeAmount = 0;
                let storeAvgPrice = 0;

                if (!storeArray) {
                  marketArray.map((marketItem) => {
                    marketMoney +=
                      parseFloat(marketItem.ing_price) *
                      parseFloat(marketItem.ing_quantity);
                    marketAvgPrice += parseFloat(marketItem.ing_price);
                    marketAmount += parseFloat(marketItem.ing_quantity);
                    return marketItem;
                  });

                  marketAmount = Number(marketAmount).toFixed(4);
                  marketMoney = Number(marketMoney.toFixed(0));

                  const avgPriceIngredient =
                    (marketMoney / marketAmount).toFixed(0) % Infinity || 0;

                  totalMarketMoney += marketMoney;

                  return (
                    <tr className="align-middle" key={index}>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td className="text-center">{index + 1}</td>
                      <td>{marketArray[0].ingredent_name}</td>
                      <td>{marketArray[0].unit_market_name}</td>
                      <td className="text-end">
                        <CurrencyFormat
                          value={parseFloat(marketAmount)}
                          thousandSeparator={true}
                          allowNegative={false}
                          isNumericString
                          displayType="text"
                          decimalScale={4}
                        />
                      </td>
                      <td className="text-end">
                        <CurrencyFormat
                          value={parseInt(avgPriceIngredient)}
                          thousandSeparator={true}
                          allowNegative={false}
                          isNumericString
                          displayType="text"
                          decimalScale={0}
                        />
                      </td>
                      <td className="text-end">
                        <CurrencyFormat
                          value={parseInt(marketMoney)}
                          thousandSeparator={true}
                          allowNegative={false}
                          isNumericString
                          displayType="text"
                          decimalScale={0}
                        />
                      </td>
                    </tr>
                  );
                }

                if (!marketArray) {
                  storeArray.map((storeItem) => {
                    storeMoney +=
                      parseFloat(storeItem.ing_price) *
                      parseFloat(storeItem.ing_quantity);
                    storeAvgPrice += parseFloat(storeItem.ing_price);
                    storeAmount += parseFloat(storeItem.ing_quantity);
                    return storeItem;
                  });

                  totalStoreMoney += storeMoney;

                  return (
                    <tr className="align-middle" key={index}>
                      <td className="text-center">{index + 1}</td>
                      <td>{storeArray[0].ingredent_name}</td>
                      <td>{storeArray[0].unit_market_name}</td>
                      <td className="text-end">
                        <CurrencyFormat
                          value={Number(storeAmount).toFixed(4)}
                          thousandSeparator={true}
                          allowNegative={false}
                          isNumericString
                          displayType="text"
                        />
                      </td>
                      <td className="text-end">
                        <CurrencyFormat
                          value={
                            Number(
                              storeMoney.toFixed(0) / storeAmount.toFixed(4)
                            ).toFixed(0) % Infinity || 0
                          }
                          thousandSeparator={true}
                          allowNegative={false}
                          isNumericString
                          displayType="text"
                        />
                      </td>
                      <td className="text-end">
                        <CurrencyFormat
                          value={Number(storeMoney).toFixed(0)}
                          thousandSeparator={true}
                          allowNegative={false}
                          isNumericString
                          displayType="text"
                        />
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  );
                }

                storeArray.map((storeItem) => {
                  storeMoney +=
                    parseFloat(storeItem.ing_price) *
                    parseFloat(storeItem.ing_quantity);
                  storeAvgPrice += parseFloat(storeItem.ing_price);
                  storeAmount += parseFloat(storeItem.ing_quantity);
                  return storeItem;
                });

                marketArray.map((marketItem) => {
                  marketMoney +=
                    parseFloat(marketItem.ing_price) *
                    parseFloat(marketItem.ing_quantity);
                  marketAvgPrice += parseFloat(marketItem.ing_price);
                  marketAmount += parseFloat(marketItem.ing_quantity);
                  return marketItem;
                });

                totalStoreMoney += storeMoney;
                totalMarketMoney += marketMoney;

                storeAmount = Number(storeAmount).toFixed(4);
                storeMoney = Number(storeMoney).toFixed(0);
                const storeAvgIngredientPrice =
                  (storeMoney / storeAmount).toFixed(0) % Infinity || 0;

                marketAmount = Number(marketAmount).toFixed(4);
                marketMoney = Number(marketMoney).toFixed(0);
                const marketAvgIngredientPrice =
                  (marketMoney / marketAmount).toFixed(0) % Infinity || 0;

                return (
                  <tr className="align-middle" key={index}>
                    <td className="text-center">{index + 1}</td>
                    <td>{storeArray[0].ingredent_name}</td>
                    <td>{storeArray[0].unit_market_name} </td>
                    <td className="text-end">
                      <CurrencyFormat
                        value={parseFloat(storeAmount)}
                        thousandSeparator={true}
                        allowNegative={false}
                        isNumericString
                        displayType="text"
                        decimalScale={4}
                      />
                    </td>
                    <td className="text-end">
                      <CurrencyFormat
                        value={parseInt(storeAvgIngredientPrice)}
                        thousandSeparator={true}
                        allowNegative={false}
                        isNumericString
                        displayType="text"
                        decimalScale={0}
                      />
                    </td>
                    <td className="text-end">
                      <CurrencyFormat
                        value={parseFloat(storeMoney)}
                        thousandSeparator={true}
                        allowNegative={false}
                        isNumericString
                        displayType="text"
                        decimalScale={0}
                      />
                    </td>

                    <td className="text-center">{index + 1}</td>
                    <td>{marketArray[0].ingredent_name}</td>
                    <td>{marketArray[0].unit_market_name}</td>
                    <td className="text-end">
                      <CurrencyFormat
                        value={parseFloat(marketAmount)}
                        thousandSeparator={true}
                        allowNegative={false}
                        isNumericString
                        displayType="text"
                        decimalScale={4}
                      />
                    </td>
                    <td className="text-end">
                      <CurrencyFormat
                        value={parseInt(marketAvgIngredientPrice)}
                        thousandSeparator={true}
                        allowNegative={false}
                        isNumericString
                        displayType="text"
                        decimalScale={0}
                      />
                    </td>
                    <td className="text-end">
                      <CurrencyFormat
                        value={parseInt(marketMoney)}
                        thousandSeparator={true}
                        allowNegative={false}
                        isNumericString
                        displayType="text"
                        decimalScale={0}
                      />
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td className="text-center align-middle" colSpan={12}>
                  Không có dữ liệu
                </td>
              </tr>
            )}
          </tbody>

          <tfoot>
            <tr>
              <th className="text-end align-middle" colSpan={5}>
                Tổng cộng
              </th>
              <th className="text-end align-middle">
                <CurrencyFormat
                  value={totalStoreMoney.toFixed(0)}
                  thousandSeparator={true}
                  allowNegative={false}
                  displayType="text"
                />
              </th>
              <th className="text-end align-middle" colSpan={5}>
                Tổng cộng
              </th>
              <th className="text-end align-middle">
                <CurrencyFormat
                  value={totalMarketMoney.toFixed(0)}
                  thousandSeparator={true}
                  allowNegative={false}
                  displayType="text"
                />
              </th>
            </tr>
            <tr>
              <th colSpan={6}></th>
              <th className="text-start align-middle" colSpan={5}>
                Tổng thu theo danh sách
              </th>
              <th className="text-end align-middle">
                <CurrencyFormat
                  value={this.calculateTotalPriceMeal(
                    this.calculateTotalArray(this.state.listCut),
                    this.state.groups
                  ).toFixed(0)}
                  thousandSeparator={true}
                  allowNegative={false}
                  displayType="text"
                />
              </th>
            </tr>
            <tr>
              <th colSpan={6}></th>

              <th className=" align-middle" colSpan={5}>
                Đã chi thực phẩm
              </th>
              <th className="text-end align-middle">
                <CurrencyFormat
                  value={(totalStoreMoney + totalMarketMoney).toFixed(0)}
                  thousandSeparator={true}
                  allowNegative={false}
                  displayType="text"
                />
              </th>
            </tr>
            <tr>
              <th colSpan={6}></th>
              <th colSpan={5} className="align-middle">
                Tổng tiền trả lại
              </th>
              <th className="text-end align-middle">
                <CurrencyFormat
                  value={0}
                  thousandSeparator={true}
                  allowNegative={false}
                  displayType="text"
                />
              </th>
            </tr>
            <tr>
              <th colSpan={6}></th>
              <th className=" align-middle" colSpan={5}>
                Thừa
              </th>
              <th className="text-end align-middle">
                <CurrencyFormat
                  value={(
                    this.calculateTotalPriceMeal(
                      this.calculateTotalArray(this.state.listCut),
                      this.state.groups
                    ) -
                    (totalStoreMoney + totalMarketMoney) -
                    0
                  ).toFixed(0)}
                  thousandSeparator={true}
                  allowNegative={true}
                  displayType="text"
                />
              </th>
            </tr>
          </tfoot>
        </Table>

        <Table bordered id="finance-month-book" className="d-none">
          <thead>
            <tr className="align-middle">
              <th>{this.state.schoolInfo.school_name}</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
            </tr>
            <tr>
              <th>BẢNG CÔNG KHAI CHI CHỢ</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
            </tr>
            <tr>
              <th>
                {" "}
                Tháng {this.state.currentTime.getMonth() + 1} Năm{" "}
                {this.state.currentTime.getFullYear()}{" "}
              </th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>

              <th>
                Tổng số suất ăn:&nbsp;
                <CurrencyFormat
                  value={
                    this.countTotalStudentsPresent(this.state.listCut) || 0
                  }
                  thousandSeparator={true}
                  allowNegative={false}
                  displayType="text"
                />
              </th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>

              <th>
                Định mức chi:&nbsp;
                {/* <CurrencyFormat
                  value={this.state.takeMoney.toFixed(0)}
                  thousandSeparator={true}
                  allowNegative={false}
                  displayType="text"
                />{" "}
                &nbsp; đồng/bé */}
                {this.state?.groups.map((item, index) => {
                  return (
                    <span key={`spendingNorms${index}`}>
                      <span>
                        {item.group_name}:{" "}
                        <CurrencyFormat
                          value={parseInt(item.price_meal)}
                          thousandSeparator={true}
                          allowNegative={false}
                          isNumericString
                          displayType="text"
                        />{" "}
                        / Bé
                      </span>
                      <br />
                    </span>
                  );
                })}
              </th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
            </tr>
            <tr className="text-center">
              <th className="align-middle">STT</th>
              <th className="align-middle">Thực phẩm kho</th>
              <th className="align-middle">ĐVT</th>
              <th className="align-middle">Số lượng</th>
              <th className="align-middle">Đơn giá</th>
              <th className="align-middle">Thành tiền</th>
              <th className="align-middle">STT</th>
              <th className="align-middle">Thực phẩm chợ</th>
              <th className="align-middle">ĐVT</th>
              <th className="align-middle">Số lượng</th>
              <th className="align-middle">Đơn giá</th>
              <th className="align-middle">Thành tiền</th>
            </tr>
          </thead>

          <tbody>
            {bigArray.length !== 0 ? (
              bigArray.map((_, index) => {
                const storeArray = storeArrays[index];
                const marketArray = marketArrays[index];

                let marketMoney = 0;
                let marketAmount = 0;
                let marketAvgPrice = 0;

                let storeMoney = 0;
                let storeAmount = 0;
                let storeAvgPrice = 0;

                if (!storeArray) {
                  marketArray.map((marketItem) => {
                    marketMoney +=
                      parseFloat(marketItem.ing_price) *
                      parseFloat(marketItem.ing_quantity);
                    marketAvgPrice += parseFloat(marketItem.ing_price);
                    marketAmount += parseFloat(marketItem.ing_quantity);

                    return marketItem;
                  });

                  marketAmount = Number(marketAmount).toFixed(4);
                  marketMoney = Number(marketMoney).toFixed(0);
                  const marketAvgPriceIngredient =
                    Number(marketMoney / marketAmount).toFixed(0) % Infinity ||
                    0;

                  return (
                    <tr className="align-middle" key={index}>
                      <td>empty</td>
                      <td>empty</td>
                      <td>empty</td>
                      <td>empty</td>
                      <td>empty</td>
                      <td>empty</td>
                      <td className="text-center">{index + 1}</td>
                      <td>{marketArray[0].ingredent_name}</td>
                      <td>{marketArray[0].unit_market_name}</td>
                      <td className="text-end">
                        <CurrencyFormat
                          value={parseFloat(marketAmount)}
                          thousandSeparator={true}
                          allowNegative={false}
                          isNumericString
                          displayType="text"
                          decimalScale={4}
                        />
                      </td>
                      <td className="text-end">
                        <CurrencyFormat
                          value={parseInt(marketAvgPriceIngredient)}
                          thousandSeparator={true}
                          allowNegative={false}
                          isNumericString
                          displayType="text"
                          decimalScale={0}
                        />
                      </td>
                      <td className="text-end">
                        <CurrencyFormat
                          value={parseInt(marketMoney)}
                          thousandSeparator={true}
                          allowNegative={false}
                          isNumericString
                          displayType="text"
                          decimalScale={0}
                        />
                      </td>
                    </tr>
                  );
                }

                if (!marketArray) {
                  storeArray.map((storeItem) => {
                    storeMoney +=
                      parseFloat(storeItem.ing_price) *
                      parseFloat(storeItem.ing_quantity);
                    storeAvgPrice += parseFloat(storeItem.ing_price);
                    storeAmount += parseFloat(storeItem.ing_quantity);
                    return storeItem;
                  });

                  storeAmount = Number(storeAmount).toFixed(4);
                  storeMoney = Number(storeMoney).toFixed(0);
                  const storeAvgIngredientPrice =
                    (storeMoney / storeAmount).toFixed(0) % Infinity || 0;

                  return (
                    <tr className="align-middle" key={index}>
                      <td className="text-center">{index + 1}</td>
                      <td>{storeArray[0].ingredent_name}</td>
                      <td>{storeArray[0].unit_market_name}</td>
                      <td className="text-end">
                        <CurrencyFormat
                          value={parseInt(storeAmount)}
                          thousandSeparator={true}
                          allowNegative={false}
                          isNumericString
                          displayType="text"
                        />
                      </td>
                      <td className="text-end">
                        <CurrencyFormat
                          value={parseInt(storeAvgIngredientPrice)}
                          thousandSeparator={true}
                          allowNegative={false}
                          isNumericString
                          displayType="text"
                        />
                      </td>
                      <td className="text-end">
                        <CurrencyFormat
                          value={parseInt(storeMoney)}
                          thousandSeparator={true}
                          allowNegative={false}
                          isNumericString
                          displayType="text"
                        />
                      </td>
                      <td>empty</td>
                      <td>empty</td>
                      <td>empty</td>
                      <td>empty</td>
                      <td>empty</td>
                      <td>empty</td>
                    </tr>
                  );
                }

                storeArray.map((storeItem) => {
                  storeMoney +=
                    parseFloat(storeItem.ing_price) *
                    parseFloat(storeItem.ing_quantity);
                  storeAvgPrice += parseFloat(storeItem.ing_price);
                  storeAmount += parseFloat(storeItem.ing_quantity);
                  return storeItem;
                });

                marketArray.map((marketItem) => {
                  marketMoney +=
                    parseFloat(marketItem.ing_price) *
                    parseFloat(marketItem.ing_quantity);
                  marketAvgPrice += parseFloat(marketItem.ing_price);
                  marketAmount += parseFloat(marketItem.ing_quantity);
                  return marketItem;
                });

                storeAmount = Number(storeAmount).toFixed(4);
                storeMoney = Number(storeMoney).toFixed(0);
                const storeAvgIngredientPrice =
                  (storeMoney / storeAmount).toFixed(0) % Infinity || 0;

                marketAmount = Number(marketAmount).toFixed(4);
                marketMoney = Number(marketMoney).toFixed(0);
                const marketAvgIngredientPrice =
                  (marketMoney / marketAmount).toFixed(0) % Infinity || 0;

                return (
                  <tr className="align-middle" key={index}>
                    <td className="text-center">{index + 1}</td>
                    <td>{storeArray[0].ingredent_name}</td>
                    <td>{storeArray[0].unit_market_name}</td>
                    <td className="text-end">
                      <CurrencyFormat
                        value={parseFloat(storeAmount)}
                        thousandSeparator={true}
                        allowNegative={false}
                        isNumericString
                        displayType="text"
                        decimalScale={4}
                      />
                    </td>
                    <td className="text-end">
                      <CurrencyFormat
                        value={parseInt(storeAvgIngredientPrice)}
                        thousandSeparator={true}
                        allowNegative={false}
                        isNumericString
                        displayType="text"
                        decimalScale={0}
                      />
                    </td>
                    <td className="text-end">
                      <CurrencyFormat
                        value={parseInt(storeMoney)}
                        thousandSeparator={true}
                        allowNegative={false}
                        isNumericString
                        displayType="text"
                        decimalScale={0}
                      />
                    </td>
                    <td className="text-center">{index + 1}</td>
                    <td>{marketArray[0].ingredent_name}</td>
                    <td>{marketArray[0].unit_market_name}</td>
                    <td className="text-end">
                      <CurrencyFormat
                        value={parseFloat(marketAmount)}
                        thousandSeparator={true}
                        allowNegative={false}
                        isNumericString
                        displayType="text"
                        decimalScale={4}
                      />
                    </td>
                    <td className="text-end">
                      <CurrencyFormat
                        value={parseInt(marketAvgIngredientPrice)}
                        thousandSeparator={true}
                        allowNegative={false}
                        isNumericString
                        displayType="text"
                        decimalScale={0}
                      />
                    </td>
                    <td className="text-end">
                      <CurrencyFormat
                        value={parseInt(marketMoney)}
                        thousandSeparator={true}
                        allowNegative={false}
                        isNumericString
                        displayType="text"
                        decimalScale={0}
                      />
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td className="text-center align-middle" colSpan={12}>
                  Không có dữ liệu
                </td>
              </tr>
            )}
          </tbody>

          <tfoot>
            <tr>
              <th className="text-center align-middle">Tổng cộng</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>

              <th className="text-end align-middle">
                <CurrencyFormat
                  value={totalStoreMoney.toFixed(0)}
                  thousandSeparator={true}
                  allowNegative={false}
                  displayType="text"
                />
              </th>
              <th className="text-center align-middle">Tổng cộng</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>

              <th className="text-end align-middle">
                <CurrencyFormat
                  value={totalMarketMoney.toFixed(0)}
                  thousandSeparator={true}
                  allowNegative={false}
                  displayType="text"
                />
              </th>
            </tr>
            <tr>
              <th>empty</th> <th>empty</th> <th>empty</th> <th>empty</th>{" "}
              <th>empty</th> <th>empty</th>
              <th className="text-start align-middle">
                Tổng thu theo danh sách
              </th>
              <th>empty</th> <th>empty</th> <th>empty</th> <th>empty</th>
              <th className="text-end align-middle">
                <CurrencyFormat
                  value={this.calculateTotalPriceMeal(
                    this.calculateTotalArray(this.state.listCut),
                    this.state.groups
                  ).toFixed(0)}
                  thousandSeparator={true}
                  allowNegative={false}
                  displayType="text"
                />
              </th>
            </tr>
            <tr>
              <th>empty</th> <th>empty</th> <th>empty</th> <th>empty</th>{" "}
              <th>empty</th> <th>empty</th>
              <th className=" align-middle">Đã chi thực phẩm</th>
              <th>empty</th> <th>empty</th> <th>empty</th> <th>empty</th>{" "}
              <th className="text-end align-middle">
                <CurrencyFormat
                  value={(totalStoreMoney + totalMarketMoney).toFixed(0)}
                  thousandSeparator={true}
                  allowNegative={false}
                  displayType="text"
                />
              </th>
            </tr>
            <tr>
              <th>empty</th> <th>empty</th> <th>empty</th> <th>empty</th>{" "}
              <th>empty</th> <th>empty</th>
              <th className="align-middle">Tổng tiền trả lại</th>
              <th>empty</th> <th>empty</th> <th>empty</th> <th>empty</th>{" "}
              <th className="text-end align-middle">
                <CurrencyFormat
                  value={0}
                  thousandSeparator={true}
                  allowNegative={false}
                  displayType="text"
                />
              </th>
            </tr>
            <tr>
              <th>empty</th> <th>empty</th> <th>empty</th> <th>empty</th>{" "}
              <th>empty</th> <th>empty</th>
              <th className=" align-middle">Thừa</th>
              <th>empty</th> <th>empty</th> <th>empty</th> <th>empty</th>{" "}
              <th className="text-end align-middle">
                <CurrencyFormat
                  value={(
                    this.calculateTotalPriceMeal(
                      this.calculateTotalArray(this.state.listCut),
                      this.state.groups
                    ) -
                    (totalStoreMoney + totalMarketMoney) -
                    0
                  ).toFixed(0)}
                  thousandSeparator={true}
                  allowNegative={true}
                  displayType="text"
                />
              </th>
            </tr>
          </tfoot>
        </Table>
      </div>
    );
  }

  render() {
    return (
      <Card>
        <Card.Header>
          <div className="d-block d-md-flex justify-content-between">
            <div className="d-block d-sm-flex mb-3 mb-sm-0">
              <Card.Title className="me-3 me-3 pt-1">
                <i className="fas fa-list me-1"></i> Sổ tài chính công khai
                Tháng {this.state.currentTime.getMonth() + 1} Năm{" "}
                {this.state.currentTime.getFullYear()}
              </Card.Title>
              <div className="d-flex align-items-center">
                <input
                  type="month"
                  name="month"
                  style={{
                    border: "none",
                    width: 38,
                    backgroundColor: "rgb(16,108,252)",
                    borderRadius: 5,
                  }}
                  onChange={(e) => {
                    const listDate = this.getDaysInMonth(
                      e.target.valueAsDate.getMonth(),
                      e.target.valueAsDate.getFullYear()
                    );

                    this.getListVoucher(
                      listDate[0],
                      listDate[listDate.length - 1]
                    );
                    this.getListMealByClassData(
                      listDate[0],
                      listDate[listDate.length - 1]
                    );
                    this.getlistCutAll(
                      listDate[0],
                      listDate[listDate.length - 1]
                    );

                    this.setState({
                      datesInMonth: listDate,
                      currentTime: e.target.valueAsDate,
                    });
                  }}
                />
              </div>
            </div>

            <Button
              variant="success"
              size="sm"
              onClick={() => {
                this.xport();
              }}
              disabled={this.state.listVoucher.length === 0}
            >
              Xuất file <i className="fa-solid fa-file-export"></i>
            </Button>
          </div>
        </Card.Header>
        <Card.Body>
          {this.state.isLoading ? (
            <div className="d-flex justify-content-center align-items-center">
              <div
                className="spinner-border text-primary"
                role="status"
                style={{ width: "3rem", height: "3rem" }}
              >
                <span className="sr-only">Đang tải báo cáo thu chi...</span>
              </div>
            </div>
          ) : (
            this.RenderFinanceTable()
          )}
        </Card.Body>
      </Card>
    );
  }
}

export default FinanceMonthBook;
