import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { Modal, Table, Form, Button, Row, Col } from "react-bootstrap";
import moment from "moment/moment";
import swal from "sweetalert";

const AddStudentsToClassModal = ({
  show,
  onHide,
  onSave,
  listStudents,
  schoolYears,
  yearIdNow,
  classroomsNow,
}) => {
  const [messagesErr, setMessagesErr] = useState([]);
  const [students, setStudents] = useState(listStudents);
  const [isLoading, setIsLoading] = useState(false);
  const [yearId, setYearId] = useState(yearIdNow);
  const [classId, setClassId] = useState("");
  const [classrooms, setClassrooms] = useState(classroomsNow);
  const [newClassStartAt, setNewClassStartAt] = useState("");
  const [oldClassEndAt, setOldClassEndAt] = useState("");

  const hanldeSubmit = (e) => {
    e.preventDefault();

    const id_students = students.map((student) => student.id);

    if (id_students.length === 0) {
      return setMessagesErr({
        ...messagesErr,
        [`id_students`]: "Không được trống học sinh.",
      });
    }

    setMessagesErr({
      ...messagesErr,
      [`id_student`]: "",
    });

    let data = {
      id_class: classId,
      new_class_start_at: newClassStartAt,
      old_class_end_at: oldClassEndAt,
      id_students: id_students,
    };

    swal({
      title: `Bạn chắc muốn chuyển lớp?`,
      icon: "warning",
      buttons: ["Đóng", "Đồng ý"],
      successMode: true,
    }).then(async (ok) => {
      if (ok) {
        setIsLoading(true);

        axios
          .post("add-students-to-class", data, {
            headers: { "Content-Type": "application/json" },
          })
          .then((res) => {
            toast(res.data.messages, { type: "success" });
            setIsLoading(false);
            return onSave();
          })
          .catch((error) => {
            if (error.request.status === 400) {
              setMessagesErr(error.response.data.messages);
            } else {
              toast(error.response.data.messages, {
                type: "error",
              });
            }
            setIsLoading(false);
          });
      }
    });
  };

  const getClassRooms = (yearId) => {
    axios
      .get(`/classroom-shows?id_year=${yearId}`)
      .then((res) => {
        setClassrooms(res.data.data?.classrooms);
        setClassId("");
      })
      .catch((err) => {
        toast(err.message, { type: "error" });
      });
  };

  const handleDestroy = (student, index) => {
    swal({
      title: `Bạn chắc muốn xóa?`,
      text: `Học sinh: ${student.student_name}`,
      icon: "error",
      buttons: ["Đóng", "Xóa"],
      dangerMode: true,
    }).then(async (ok) => {
      if (ok) {
        setMessagesErr({ ...messagesErr, [`id_students[${index}]`]: "" });
        setStudents(students.filter((item) => item.id !== student.id));
      }
    });
  };

  //#region Render
  return (
    <Modal
      backdrop="static"
      keyboard={false}
      show={show}
      onHide={() => onHide(true)}
      size="md"
    >
      <Modal.Header closeButton>
        <Modal.Title>Chuyển lớp</Modal.Title>
      </Modal.Header>

      <Form onSubmit={hanldeSubmit}>
        <Modal.Body>
          {isLoading ? (
            <div className="d-flex justify-content-center align-items-center">
              <div className="spinner-border text-primary" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          ) : (
            <>
              <Row>
                <Col md={6}>
                  <Form.Floating className="mb-3">
                    <Form.Select
                      value={yearId}
                      onChange={(e) => {
                        const yearId = e.target.value;
                        setYearId(yearId);
                        setClassrooms([]);
                        setClassId("");
                        if (yearId !== "") getClassRooms(yearId);
                      }}
                      required
                    >
                      <option value="">--Chọn--</option>

                      {schoolYears?.map((itemYear, indexYear) => {
                        return (
                          <option key={`year_${indexYear}`} value={itemYear.id}>
                            {itemYear.year_name}
                          </option>
                        );
                      })}
                    </Form.Select>

                    <Form.Label>
                      Năm học<i className="text-danger">*</i>
                    </Form.Label>
                  </Form.Floating>
                </Col>

                <Col md={6}>
                  <Form.Floating className="mb-3">
                    <Form.Select
                      value={classId}
                      onChange={(e) => {
                        setClassId(e.target.value);
                      }}
                      required
                    >
                      <option value="">--Chọn--</option>
                      {classrooms?.map((item, index) => {
                        return (
                          <option key={`classroom_1_${index}`} value={item.id}>
                            {item.class_name}
                          </option>
                        );
                      })}
                    </Form.Select>

                    <Form.Label>
                      Lớp học
                      <i className="text-danger">*</i>
                    </Form.Label>

                    <i className="text-danger">{messagesErr["id_class"]}</i>
                  </Form.Floating>
                </Col>

                <Col md={6}>
                  <Form.Floating className="mb-3">
                    <input
                      type="date"
                      className="form-control"
                      required
                      value={
                        oldClassEndAt
                          ? moment(new Date(oldClassEndAt * 1000)).format(
                              "YYYY-MM-DD"
                            )
                          : ""
                      }
                      onChange={(e) => {
                        setOldClassEndAt(
                          new Date(e.target.value).setHours(23, 59, 59) / 1000
                        );
                        setMessagesErr({
                          ...messagesErr,
                          old_class_end_at: "",
                        });
                      }}
                    />
                    <Form.Label>
                      Ngày kết thúc lớp cũ
                      <i className="text-danger">*</i>
                    </Form.Label>
                    <i className="text-danger">
                      {messagesErr["old_class_end_at"]}
                    </i>
                  </Form.Floating>
                </Col>

                <Col md={6}>
                  <Form.Floating className="mb-3">
                    <input
                      type="date"
                      className="form-control"
                      required
                      value={
                        newClassStartAt
                          ? moment(new Date(newClassStartAt * 1000)).format(
                              "YYYY-MM-DD"
                            )
                          : ""
                      }
                      onChange={(e) => {
                        setNewClassStartAt(
                          new Date(e.target.value).setHours(0, 0, 0) / 1000
                        );
                        setMessagesErr({
                          ...messagesErr,
                          new_class_start_at: "",
                        });
                      }}
                    />
                    <Form.Label>
                      Ngày vào lớp mới
                      <i className="text-danger">*</i>
                    </Form.Label>
                    <i className="text-danger">
                      {messagesErr["new_class_start_at"]}
                    </i>
                  </Form.Floating>
                </Col>
              </Row>

              <div style={{ overflowX: "scroll", maxHeight: "400px" }}>
                <Table
                  key="tableAccounts"
                  size="lg"
                  bordered
                  hover
                  style={{ position: "relative" }}
                >
                  <thead style={{ position: "sticky", top: 0 }}>
                    <tr className="bg-light align-middle text-center">
                      <th>STT</th>
                      <th style={{ width: "220px" }}>Tên học sinh</th>
                      <th>Lớp</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {students.length > 0 ? (
                      students?.map((student, index) => {
                        return (
                          <>
                            <tr
                              key={`student-${index}`}
                              className="align-middle"
                            >
                              <td className="text-center">{index + 1}</td>
                              <td>
                                {student.student_name}
                                <br />
                                <i className="text-danger">
                                  {messagesErr[`id_students[${index}]`]}
                                </i>
                              </td>
                              <td>{student.class_name}</td>
                              <td className="text-center">
                                <Button
                                  size="sm"
                                  variant="outline-danger"
                                  title="Xóa"
                                  onClick={() => handleDestroy(student, index)}
                                >
                                  <i className="fa-solid fa-times" />
                                </Button>
                              </td>
                            </tr>
                          </>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={3} className="text-muted">
                          Không có dữ liệu.
                          <br />
                          <i className="text-danger">
                            {messagesErr[`id_students`]}
                          </i>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </>
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button size="sm" variant="secondary" onClick={() => onHide(true)}>
            <i className="fa-solid fa-times"></i> Đóng
          </Button>
          <Button
            size="sm"
            type="submit"
            variant="success"
            disabled={isLoading}
          >
            <i className="fa-solid fa-check"></i> Lưu
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
  //#endregion Render
};
export default AddStudentsToClassModal;
