import React, { Component } from "react";
import axios from "axios";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import swal from "sweetalert";
import moment from "moment/moment";

class ClassHistoryEditModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dataInput: props?.classHasStudent,
      err: {},
    };
  }

  handleSubmit = (event) => {
    event.preventDefault();
    swal({
      title: `Bạn chắc muốn cập nhật?`,
      text: `**Lưu ý: Khi cập nhật sẽ ảnh đến dữ liệu "Nghỉ học" và "Cắt suất".`,
      icon: "warning",
      buttons: ["Đóng", "Cập nhật"],
      successMode: true,
    }).then(async (ok) => {
      if (ok) {
        let data = {
          id: this.state.dataInput.id,
          start_at: this.state.dataInput.start_at,
          end_at: this.state.dataInput.end_at,
        };

        axios
          .put(`/class-has-student-update`, data, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((res) => {
            toast(res.data.messages, { type: "success" });
            this.props.onSave();
          })
          .catch((error) => {
            this.setState({ err: error.response.data.messages });
          });
      }
    });
  };

  handleInput = (event) => {
    let value = event.target.value;
    const field = event.target.name;
    const type = event.target.type;

    if (type === "date" && event.target.value !== "")
      value = new Date(event.target.value) / 1000;

    this.setState((prevState) => {
      return {
        dataInput: {
          ...prevState.dataInput,
          [field]: value,
        },
      };
    });
  };

  render() {
    const { onHide, ...other } = { ...this.props };
    return (
      <>
        <Modal
          show={other.show}
          onHide={onHide}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Chi tiết quá trình chuyển lớp</Modal.Title>
          </Modal.Header>

          <Form onSubmit={this.handleSubmit}>
            <Modal.Body>
              <Row>
                <Col md={6}>
                  <Form.Floating className="mb-3">
                    <Form.Control
                      type="text"
                      defaultValue={this.state.dataInput?.year_name}
                      disabled
                    />
                    <Form.Label>
                      Năm học<i className="text-danger">*</i>
                    </Form.Label>
                  </Form.Floating>
                </Col>

                <Col md={6}>
                  <Form.Floating className="mb-3">
                    <Form.Control
                      type="text"
                      value={this.state.dataInput?.class_name}
                      disabled
                    />
                    <Form.Label>
                      Lớp học<i className="text-danger">*</i>
                    </Form.Label>
                  </Form.Floating>
                </Col>

                <Col md={6}>
                  <Form.Floating className="mb-3">
                    <input
                      name="start_at"
                      type="date"
                      className="form-control"
                      required
                      value={
                        this.state.dataInput.start_at
                          ? moment(
                              new Date(this.state.dataInput.start_at * 1000)
                            ).format("YYYY-MM-DD")
                          : ""
                      }
                      onChange={this.handleInput}
                    />
                    <Form.Label>
                      Ngày vào lớp
                      <i className="text-danger">*</i>
                    </Form.Label>
                    <span className="text-form-err">
                      {this.state.err?.start_at}
                    </span>
                  </Form.Floating>
                </Col>

                <Col md={6}>
                  <Form.Floating className="mb-">
                    <input
                      name="end_at"
                      type="date"
                      className="form-control"
                      value={
                        this.state.dataInput.end_at
                          ? moment(
                              new Date(this.state.dataInput.end_at * 1000)
                            ).format("YYYY-MM-DD")
                          : ""
                      }
                      onChange={this.handleInput}
                    />
                    <Form.Label>Ngày kết thúc</Form.Label>
                    <span className="text-form-err">
                      {this.state.err?.end_at}
                    </span>
                  </Form.Floating>
                </Col>

                <i className="text-muted">
                  **Lưu ý: Ngày kết thúc trống là "Vô thời hạn".
                </i>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button
                size="sm"
                variant="secondary"
                id="btnCloseAddIngredent"
                onClick={onHide}
              >
                <i className="fa-solid fa-times"></i> Đóng
              </Button>

              <Button size="sm" variant="success" type="submit">
                <i className="fa-solid fa-check"></i> Cập nhật
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </>
    );
  }
}

export default ClassHistoryEditModal;
