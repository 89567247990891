import React, { Component } from "react";
import {
  Breadcrumb,
  Button,
  Card,
  Container,
  Table,
  Modal,
} from "react-bootstrap";
import axios from "axios";
import * as XLSX from "xlsx-js-style";
import DatePicker from "react-datepicker";
import CurrencyFormat from "react-currency-format";
import swal from "sweetalert";

class ListCut extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listStudent: [],
      startDate: new Date(),
      endDate: new Date(),
      show: false,
      start_: new Date(),
      end_: new Date(),
      checkloading: true,
    };
  }

  async componentDidMount() {
    document.title = "Chi tiết cắt suất ăn";

    const start = new Date();
    start.setHours(0, 0, 0, 0);

    const end = new Date();
    end.setHours(23, 59, 59, 0);

    this.setState(
      {
        startDate: start,
        endDate: end,
      },
      () => {
        this.getListMealstudent(start, end);
      }
    );
  }
  loading = () => {
    return (
      <Card>
        <Card.Body className="d-flex justify-content-center align-items-center">
          <div
            className="spinner-border text-primary"
            role="status"
            style={{ width: "3rem", height: "3rem" }}
          >
            <span className="sr-only">Loading...</span>
          </div>
        </Card.Body>
      </Card>
    );
  };
  getDate_ = (data) => {
    let date = data;
    let result = `${date?.getDate()}/${
      date?.getMonth() + 1
    }/${date?.getFullYear()}`;
    return result;
  };
  getDate = (data) => {
    let date = new Date(data * 1000);
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
  };
  getTong = () => {
    var tong = 0;
    this.state.listStudent.map((student_, index) => {
      student_.absent.map((absent_, index1) => {
        if (absent_.is_valid != 0) tong = tong + parseInt(absent_.money_back);
      });
    });
    return tong;
  };
  removeDuplicates(arr) {
    const uniqueItems = new Set();
    const result = [];

    for (const item of arr) {
      const itemAsString = JSON.stringify(item);
      if (!uniqueItems.has(itemAsString)) {
        uniqueItems.add(itemAsString);
        result.push(item);
      }
    }

    return result;
  }
  getListMealstudent(start, end) {
    var arrStudent = [];
    var arrRS = [];
    let start_ = start.setHours(0, 0, 0) / 1000;
    let end_ = end.setHours(23, 59, 59) / 1000;
    this.setState({ checkloading: true });
    axios
      .get(
        `day-off-shows?&start_date=${parseInt(start_)}&end_date=${parseInt(
          end_
        )}
       `
      )
      .then((res) => {
        if (res) {
          this.setState({ checkloading: false });
          const dateFilter = res.data.data;
          dateFilter?.map((datefilter_student) => {
            arrStudent.push({
              id: datefilter_student.id_student,
              class_name: datefilter_student.class_name,
              student_name: datefilter_student.student_name,
            });
          });
          arrRS = this.removeDuplicates(
            arrStudent?.sort(function (a, b) {
              if (a.class_name <= b.class_name) return -1;
              if (a.class_name > b.class_name) return 1;
              return 0;
            })
          );
          this.setState({
            listStudent: arrRS?.map((student) => {
              return {
                id: student.id,
                username: student.student_name,
                class_name: student.class_name,
                absent: dateFilter.filter(
                  (absentDay) =>
                    absentDay.id_student == student.id &&
                    absentDay.is_status == 1
                ),
              };
            }),
          });
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({ checkloading: true });
      });
  }

  formatNumber(num) {
    if (num != null && num != "")
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }
  Emaxlement = (array) => {
    let max = 0;
    if (array) {
      for (let i = 0; i < array.length; ++i) {
        if (max < parseInt(array[i])) {
          max = parseInt(array[i]);
        }
      }
    }
    return parseInt(max);
  };

  renderCutMealListTable() {
    return (
      <div style={{ overflow: "auto" }}>
        <Table bordered>
          <thead>
            <tr>
              <th className="text-center" colSpan="12">
                DANH SÁCH HỌC SINH CẮT SUẤT ĂN (
                {this.getDate_(this.state.startDate)} -{" "}
                {this.getDate_(this.state.endDate)}){" "}
              </th>
            </tr>
            <tr>
              <th rowSpan="2" className="align-middle text-center">
                Họ và tên
              </th>
              <th className="align-middle text-center" rowSpan="2">
                Lớp
              </th>
              <th
                className="align-middle text-center"
                style={{ width: "80px" }}
                rowSpan="2"
              >
                SL ngày cắt suất
              </th>
              <th className="text-center" colSpan="5">
                Ngày nghỉ có phép
              </th>
              <th className="text-center" colSpan="3">
                Ngày nghỉ không phép
              </th>
            </tr>
            <tr>
              <th className="text-center">Ngày</th>
              <th className="text-center"> Lý do</th>
              <th className="text-center">Thành tiền</th>
              <th className="text-center"> SL</th>
              <th className="text-center">Tổng</th>
              <th className="text-center"> Ngày</th>
              <th className="text-center"> Lý do</th>
              <th className="text-center"> SL</th>
            </tr>
          </thead>
          <tbody>
            {this.state.listStudent.map((absent, index) => {
              if (absent.absent.length > 0) {
                let tong = 0;
                return (
                  <React.Fragment key={absent.id}>
                    <tr>
                      <td
                        className="align-middle"
                        rowSpan={absent?.absent?.length}
                      >
                        {absent.username}
                      </td>
                      <td
                        className="text-center align-middle"
                        rowSpan={absent?.absent?.length}
                      >
                        {absent?.class_name}
                      </td>
                      <td
                        className="text-center align-middle"
                        style={{ width: "80px" }}
                        rowSpan={absent?.absent?.length}
                      >
                        {absent?.absent?.length}
                      </td>
                      {/* Ngày nghỉ có phép */}
                      <td>
                        {absent.absent[0]?.is_valid == 0
                          ? ""
                          : this.getDate(absent.absent[0]?.date_off)}
                      </td>

                      <td>
                        <span
                          style={{
                            width: "140px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "block",
                          }}
                          title={
                            absent.absent[0]?.is_valid == 0
                              ? ""
                              : absent.absent[0]?.is_valid == 2
                              ? `${
                                  absent.absent[0].notes || ""
                                } (Báo trễ, duyệt bởi ${
                                  absent.absent[0].updater
                                })`
                              : absent.absent[0].notes
                          }
                        >
                          {absent.absent[0]?.is_valid == 0
                            ? ""
                            : absent.absent[0]?.is_valid == 2
                            ? `${
                                absent.absent[0].notes || ""
                              } (Báo trễ, duyệt bởi ${
                                absent.absent[0].updater
                              })`
                            : absent.absent[0].notes}
                        </span>
                      </td>
                      <td className="text-end align-middle">
                        {this.formatNumber(
                          absent.absent[0]?.is_valid == 0
                            ? ""
                            : parseInt(absent.absent[0]?.money_back)
                        )}
                      </td>
                      <td
                        rowSpan={absent?.absent?.length}
                        className="text-end align-middle"
                      >
                        {absent.absent?.filter((e) => e.is_valid != 0)?.length >
                        0
                          ? absent.absent?.filter((e) => e.is_valid != 0)
                              ?.length
                          : ""}
                      </td>

                      <td
                        rowSpan={absent?.absent?.length}
                        className="text-end align-middle"
                      >
                        {this.formatNumber(
                          this.Emaxlement(
                            absent?.absent?.map((item, index_) => {
                              if (item.is_valid != 0) {
                                tong = tong + parseInt(item.money_back);

                                return index_ + 1 == absent.absent?.length
                                  ? tong
                                  : 0;
                              } else return tong;
                            })
                          )
                        )}
                      </td>
                      {/* Ngày nghỉ không phép */}
                      <td>
                        {absent.absent[0]?.is_meal == 1
                          ? this.getDate(absent.absent[0]?.date_off)
                          : absent.absent[0]?.is_valid == 0
                          ? this.getDate(absent.absent[0]?.date_off)
                          : ""}
                      </td>

                      <td>
                        {absent.absent[0]?.is_meal == 1
                          ? ` (Báo trễ: ${this.getDateTime(
                              absent.absent[0].created_at
                            )})`
                          : absent.absent[0]?.is_valid == 0
                          ? ` (Báo trễ: ${this.getDateTime(
                              absent.absent[0].created_at
                            )})`
                          : ""}
                      </td>
                      <td
                        rowSpan={absent.absent.length}
                        className="text-end align-middle"
                      >
                        {absent.absent?.filter((e) => e.is_valid == 0)?.length >
                        0
                          ? absent.absent?.filter((e) => e.is_valid == 0)
                              ?.length
                          : ""}
                      </td>
                    </tr>
                    {/* Cắt nhìu */}
                    {absent.absent
                      .slice(1, absent.absent.length)
                      ?.map((dayAbsent, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              {dayAbsent.is_valid == 0
                                ? ""
                                : this.getDate(dayAbsent.date_off)}
                            </td>
                            <td>
                              <span
                                style={{
                                  width: "140px",
                                  // whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  display: "block",
                                }}
                                title={
                                  dayAbsent?.is_valid == 0
                                    ? ""
                                    : dayAbsent?.is_valid == 2
                                    ? `${
                                        dayAbsent?.notes || ""
                                      } (Báo trễ, duyệt bởi ${
                                        dayAbsent?.updater
                                      })`
                                    : dayAbsent?.notes
                                }
                              >
                                {dayAbsent?.is_valid == 0
                                  ? ""
                                  : dayAbsent?.is_valid == 2
                                  ? `${
                                      dayAbsent?.notes || ""
                                    } (Báo trễ, duyệt bởi ${
                                      dayAbsent?.updater
                                    })`
                                  : dayAbsent?.notes}
                              </span>
                            </td>

                            <td className="text-end align-middle">
                              {this.formatNumber(
                                dayAbsent?.is_valid == 0
                                  ? ""
                                  : parseInt(dayAbsent.money_back)
                              )}
                            </td>

                            {/* //Ngày nghỉ không phép */}

                            <td>
                              {" "}
                              {dayAbsent.is_valid == 0
                                ? this.getDate(dayAbsent.date_off)
                                : ""}
                            </td>

                            <td>
                              {dayAbsent.is_meal == 1
                                ? ` (Báo trễ: ${this.getDateTime(
                                    dayAbsent.created_at
                                  )})`
                                : dayAbsent?.is_valid == 0
                                ? ` (Báo trễ: ${this.getDateTime(
                                    dayAbsent.created_at
                                  )})`
                                : ""}
                            </td>
                          </tr>
                        );
                      })}
                    {/* ---- */}
                  </React.Fragment>
                );
              }
            })}
            <tr>
              <th colSpan={7} className="text-center">
                Tổng
              </th>
              <th className="text-end">
                <CurrencyFormat
                  value={this.getTong()}
                  thousandSeparator
                  className="text-end"
                  displayType="text"
                />
              </th>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        </Table>

        <Table bordered id="CutUserTable" className="d-none">
          <thead>
            <tr>
              <th className="text-center">
                {" "}
                DANH SÁCH HỌC SINH CẮT SUẤT ĂN (
                {this.getDate_(this.state.startDate)} -{" "}
                {this.getDate_(this.state.endDate)})
              </th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
            </tr>
            <tr>
              <th className="align-middle text-center">Họ và tên</th>
              <th className="align-middle text-center">Lớp</th>
              <th
                className="align-middle text-center"
                style={{ width: "80px" }}
              >
                {" "}
                SL ngày cắt suất
              </th>
              <th className="text-center">Ngày nghỉ có phép</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>

              <th className="text-center">Ngày nghỉ không phép</th>
              <th>empty</th>
              <th>empty</th>
            </tr>
            <tr>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>

              <th className="text-center"> Ngày</th>

              <th className="text-center"> Lý do</th>
              <th className="text-center">Thành tiền</th>
              <th className="text-center"> SL</th>
              <th className="text-center">Tổng</th>
              <th className="text-center"> Ngày</th>

              <th className="text-center"> Lý do</th>
              <th className="text-center"> SL</th>
            </tr>
          </thead>
          <tbody>
            {this.state.listStudent.map((absent) => {
              if (absent.absent.length > 0) {
                let tong = 0;

                return (
                  <React.Fragment key={absent.id}>
                    <tr>
                      <td className="align-middle">{absent.username}</td>
                      <td className="text-center align-middle">
                        {absent.class_name}
                      </td>
                      <td
                        className="text-center align-middle"
                        style={{ width: "80px" }}
                      >
                        {absent.absent.length}
                      </td>
                      {/* Ngày nghỉ có phép */}
                      <td>
                        {absent.absent[0]?.is_valid == 0
                          ? "empty"
                          : `xy${this.getDate(absent.absent[0]?.date_off)}`}
                      </td>

                      <td>
                        {absent.absent[0]?.is_valid == 0
                          ? "empty"
                          : absent.absent[0]?.is_valid == 2
                          ? `${
                              absent.absent[0].notes || "empty"
                            } (Báo trễ, duyệt bởi ${absent.absent[0].updater})`
                          : absent.absent[0].notes || "empty"}
                      </td>

                      <td className="text-end align-middle">
                        {this.formatNumber(
                          absent.absent[0]?.is_valid == 0
                            ? ""
                            : parseInt(absent.absent[0]?.money_back)
                        )}
                      </td>
                      <td className="text-end align-middle">
                        {absent.absent?.filter((e) => e.is_valid != 0)?.length >
                        0
                          ? absent.absent?.filter((e) => e.is_valid != 0)
                              ?.length
                          : "empty"}
                      </td>
                      <td className="text-end align-middle">
                        {this.formatNumber(
                          this.Emaxlement(
                            absent?.absent?.map((item, index_) => {
                              if (item.is_valid != 0) {
                                tong = tong + parseInt(item.money_back);

                                return index_ + 1 == absent.absent?.length
                                  ? tong
                                  : 0;
                              } else return tong;
                            })
                          )
                        )}
                      </td>

                      {/* Ngày nghỉ không phép */}
                      <td>
                        {absent.absent[0]?.is_meal == 1
                          ? `xy${this.getDate(absent.absent[0]?.date_off)}`
                          : absent.absent[0]?.is_valid == 0
                          ? `xy${this.getDate(absent.absent[0]?.date_off)}`
                          : "empty"}
                      </td>

                      <td>
                        {absent.absent[0]?.is_meal == 1
                          ? ` (Báo trễ: ${this.getDateTime(
                              absent.absent[0].created_at
                            )})`
                          : absent.absent[0]?.is_valid == 0
                          ? ` (Báo trễ: ${this.getDateTime(
                              absent.absent[0].created_at
                            )})`
                          : "empty"}
                      </td>
                      <td className="text-end align-middle">
                        {absent.absent?.filter((e) => e.is_valid == 0)?.length >
                        0
                          ? absent.absent?.filter((e) => e.is_valid == 0)
                              ?.length
                          : "empty"}
                      </td>
                    </tr>
                    {/* Cắt nhìu */}
                    {absent.absent
                      .slice(1, absent.absent.length)
                      ?.map((dayAbsent, index) => {
                        return (
                          <tr key={index}>
                            <td>empty</td>
                            <td>empty</td>
                            <td>empty</td>

                            {/* Ngày nghỉ có phép */}
                            <td>
                              {dayAbsent.is_meal == 0
                                ? dayAbsent.is_valid == 0
                                  ? "empty"
                                  : `xy${this.getDate(dayAbsent.date_off)}`
                                : "empty"}
                            </td>

                            <td>
                              {dayAbsent.is_meal == 0
                                ? dayAbsent?.is_valid == 0
                                  ? "empty"
                                  : dayAbsent.notes
                                : "empty"}
                            </td>
                            <td className="text-end align-middle">
                              {this.formatNumber(
                                dayAbsent?.is_valid == 0
                                  ? "empty"
                                  : dayAbsent.is_meal == 1
                                  ? "empty"
                                  : dayAbsent.money_back
                              )}
                            </td>
                            <td>empty</td>

                            <td>empty</td>

                            {/* //Ngày nghỉ không phép */}
                            <td>
                              {dayAbsent.is_meal == 1
                                ? ` xy${this.getDate(dayAbsent.date_off)}`
                                : dayAbsent.is_valid == 0
                                ? ` xy${this.getDate(dayAbsent.date_off)}`
                                : "empty"}
                            </td>

                            <td>
                              {dayAbsent.is_meal == 1
                                ? ` (Báo trễ: ${this.getDateTime(
                                    dayAbsent.created_at
                                  )})`
                                : dayAbsent?.is_valid == 0
                                ? ` (Báo trễ: ${this.getDateTime(
                                    dayAbsent.created_at
                                  )})`
                                : "empty"}
                            </td>
                            <td>empty</td>
                          </tr>
                        );
                      })}
                    {/* ---- */}
                  </React.Fragment>
                );
              }
            })}
            <tr>
              <th className="text-center">Tổng</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th className="text-end">
                <CurrencyFormat
                  value={this.getTong()}
                  thousandSeparator
                  className="text-end"
                  displayType="text"
                />
              </th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
            </tr>
          </tbody>
        </Table>
      </div>
    );
  }

  getDateTime = (data) => {
    let dateTime = new Date(data * 1000);
    return `${dateTime.getDate()}/${
      dateTime.getMonth() + 1
    }/${dateTime.getFullYear()} ${dateTime.getHours()}:${dateTime.getMinutes()}:${dateTime.getSeconds()}`;
  };
  xport = () => {
    const table = document.getElementById("CutUserTable");
    const wb = XLSX.utils.table_to_book(table);
    var ws = wb.Sheets["Sheet1"];

    const colAlpha = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K"];

    ws[`A1`].s = {
      font: {
        name: "Times New Roman",
        sz: 16,
        bold: true,
      },
      alignment: {
        wrapText: true,
        vertical: "center",
        horizontal: "center",
      },
    };

    colAlpha.map((alpha) => {
      ws[`${alpha}2`].s = {
        font: {
          name: "Times New Roman",
          sz: 11,
          bold: true,
        },
        alignment: {
          wrapText: true,
          vertical: "center",
          horizontal: "center",
        },
        border: {
          top: { style: "thin" },
          bottom: { style: "thin" },
          left: { style: "thin" },
          right: { style: "thin" },
        },
      };

      return alpha;
    });

    colAlpha.map((alpha) => {
      ws[`${alpha}3`].s = {
        font: {
          name: "Times New Roman",
          sz: 11,
          bold: true,
        },
        alignment: {
          wrapText: true,
          vertical: "center",
          horizontal: "center",
        },
        border: {
          top: { style: "thin" },
          bottom: { style: "thin" },
          left: { style: "thin" },
          right: { style: "thin" },
        },
      };

      return alpha;
    });

    // Body
    let absentIndex = 0;
    this.state.listStudent.map((student) => {
      absentIndex += student.absent.length;

      return student;
    });

    for (let i = 4; i < absentIndex + 4; i++) {
      if (ws[`D${i}`].v != "empty") ws[`D${i}`].v = ws[`D${i}`].v?.substring(2);
      if (ws[`I${i}`].v != "empty") ws[`I${i}`].v = ws[`I${i}`].v?.substring(2);
      colAlpha.map((alpha) => {
        if (ws[`${alpha}${i}`].v === "empty") {
          ws[`${alpha}${i}`].v = " ";
        }

        if (alpha === "A" || alpha === "B" || alpha === "E" || alpha === "J") {
          ws[`${alpha}${i}`].s = {
            font: {
              name: "Times New Roman",
              sz: 11,
            },
            alignment: {
              wrapText: true,
              vertical: "center",
              horizontal: "left",
            },
            border: {
              top: { style: "thin" },
              bottom: { style: "thin" },
              left: { style: "thin" },
              right: { style: "thin" },
            },
          };
        } else if (alpha === "H" || alpha === "F") {
          ws[`${alpha}${i}`].s = {
            font: {
              name: "Times New Roman",
              sz: 11,
            },
            alignment: {
              wrapText: true,
              vertical: "center",
              horizontal: "right",
            },
            border: {
              top: { style: "thin" },
              bottom: { style: "thin" },
              left: { style: "thin" },
              right: { style: "thin" },
            },
          };
        } else {
          ws[`${alpha}${i}`].s = {
            font: {
              name: "Times New Roman",
              sz: 11,
            },
            alignment: {
              wrapText: true,
              vertical: "center",
              horizontal: "center",
            },
            border: {
              top: { style: "thin" },
              bottom: { style: "thin" },
              left: { style: "thin" },
              right: { style: "thin" },
            },
          };
        }

        return alpha;
      });

      ws[`G${i}`].z = "#,###,###,###";
      delete ws[`G${i}`].w;
      XLSX.utils.format_cell(ws[`G${i}`]);

      ws[`H${i}`].z = "#,###,###,###";
      delete ws[`H${i}`].w;
      XLSX.utils.format_cell(ws[`H${i}`]);

      ws[`F${i}`].z = "#,###,###,###";
      delete ws[`F${i}`].w;
      XLSX.utils.format_cell(ws[`F${i}`]);
    }
    // Body

    colAlpha.map((alpha) => {
      if (ws[`${alpha}${absentIndex + 4}`].v === "empty") {
        ws[`${alpha}${absentIndex + 4}`].v = " ";
      }

      ws[`${alpha}${absentIndex + 4}`].s = {
        font: {
          name: "Times New Roman",
          sz: 11,
          bold: true,
        },
        alignment: {
          wrapText: true,
          vertical: "center",
          horizontal: alpha === "H" ? "right" : "center",
        },
        border: {
          top: { style: "thin" },
          bottom: { style: "thin" },
          left: { style: "thin" },
          right: { style: "thin" },
        },
      };

      return alpha;
    });

    ws[`H${absentIndex + 4}`].z = "#,###,###,###";
    delete ws[`H${absentIndex + 4}`].w;
    XLSX.utils.format_cell(ws[`H${absentIndex + 4}`]);

    ws["!cols"] = [
      { wch: 15 },
      { wch: 10 },
      { wch: 8 },

      { wch: 9 },
      { wch: 11 },
      { wch: 11 },
      { wch: 5 },
      { wch: 10 },

      { wch: 9 },
      { wch: 16.5 },
      { wch: 5 },
    ];

    ws["!rows"] = [{ hpt: 30 }];

    const merge = [
      { s: { r: 0, c: 0 }, e: { r: 0, c: 10 } },

      { s: { r: 1, c: 0 }, e: { r: 2, c: 0 } },
      { s: { r: 1, c: 1 }, e: { r: 2, c: 1 } },
      { s: { r: 1, c: 2 }, e: { r: 2, c: 2 } },

      { s: { r: 1, c: 3 }, e: { r: 1, c: 7 } },
      { s: { r: 1, c: 8 }, e: { r: 1, c: 10 } },

      { s: { r: absentIndex + 3, c: 0 }, e: { r: absentIndex + 3, c: 6 } },
    ];

    let absentIndex2 = 3;

    this.state.listStudent.map((student) => {
      if (student.absent.length === 0) {
        // absentIndex2 += 1;

        return student;
      }

      merge.push({
        s: { r: absentIndex2, c: 0 },
        e: { r: absentIndex2 + student.absent.length - 1, c: 0 },
      });
      merge.push({
        s: { r: absentIndex2, c: 1 },
        e: { r: absentIndex2 + student.absent.length - 1, c: 1 },
      });
      merge.push({
        s: { r: absentIndex2, c: 2 },
        e: { r: absentIndex2 + student.absent.length - 1, c: 2 },
      });
      merge.push({
        s: { r: absentIndex2, c: 6 },
        e: { r: absentIndex2 + student.absent.length - 1, c: 6 },
      });
      merge.push({
        s: { r: absentIndex2, c: 10 },
        e: { r: absentIndex2 + student.absent.length - 1, c: 10 },
      });

      merge.push({
        s: { r: absentIndex2, c: 7 },
        e: { r: absentIndex2 + student.absent.length - 1, c: 7 },
      });

      absentIndex2 += student.absent.length;

      return student;
    });

    ws["!merges"] = merge;

    XLSX.writeFile(wb, "Danh sách cắt suất.xlsx");
  };
  handleExport = () => {
    swal({
      title: `Xác nhận`,
      text: `Xuất phiếu danh sách cắt suất ${this.getDate_(
        this.state.startDate
      )} -  ${this.getDate_(this.state.endDate)}`,
      icon: "info",
      buttons: ["Đóng", "Xuất"],
      successMode: true,
    }).then((ok) => {
      if (ok) {
        this.xport();
      }
    });
  };
  render() {
    return (
      <>
        <Container fluid>
          <Breadcrumb>
            <Breadcrumb.Item active>Báo cáo</Breadcrumb.Item>
            <Breadcrumb.Item active>Chi tiết cắt suất ăn</Breadcrumb.Item>
          </Breadcrumb>
          <Card>
            <Card.Header>
              <Card.Title>
                <i className="fas fa-list me-1"></i>Danh sách suất ăn{" "}
                {this.state.startDate
                  ? this.getDate_(this.state.startDate)
                  : ""}{" "}
                -{" "}
                {this.state.endDate ? this.getDate_(this.state.endDate) : "--"}{" "}
                <Button
                  size="sm"
                  onClick={() => {
                    this.setState({ show: true });
                  }}
                >
                  <i className="fa-solid fa-calendar-days"> </i>{" "}
                </Button>
                <Button
                  variant="success"
                  size="sm"
                  style={{ marginLeft: "5px", float: "right" }}
                  onClick={() => this.handleExport()}
                >
                  Xuất danh sách cắt suất ăn{}
                  <i className="fa-solid fa-file-export"></i>
                </Button>
              </Card.Title>
            </Card.Header>
            <Card.Body>
              {this.state.checkloading
                ? this.loading()
                : this.renderCutMealListTable()}
            </Card.Body>
          </Card>
        </Container>
        <Modal show={this.state.show} size={"sm"}>
          <Modal.Header>
            <Modal.Title>Chọn thời gian</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <center>
              <DatePicker
                size={{ height: "600px" }}
                selected={this.state.start_}
                onChange={(update) => {
                  this.setState({ start_: update[0] });
                  this.setState({ end_: update[1] });
                }}
                startDate={this.state.start_}
                endDate={this.state.end_}
                calendarStartDay={1}
                selectsRange
                inline
              />
            </center>
            <br />
          </Modal.Body>
          <Modal.Footer>
            <Button
              size="sm"
              onClick={() => {
                this.setState({ show: false });
                this.getListMealstudent(
                  this.state.startDate,
                  this.state.endDate
                );
              }}
            >
              Hủy
            </Button>
            <Button
              size="sm"
              disabled={this.state.start_ === null || this.state.end_ === null}
              onClick={() => {
                this.setState({ show: false });
                this.setState({ startDate: this.state.start_ });
                this.setState({ endDate: this.state.end_ });
                this.getListMealstudent(this.state.start_, this.state.end_);
              }}
            >
              Lưu
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default ListCut;
