import axios from "axios";
import React, { Component } from "react";
import { Button, Form, Modal, Col, Row, Tabs, Tab } from "react-bootstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import ClassCreate from "../classV2_del/ClassCreate";
import CurrencyFormat from "react-currency-format";
import Check from "../other/Check";
import CheckboxTree from "react-checkbox-tree";
import { capitalizeEachWord } from "../../../helper/texts";

class StudentCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listUser: [],
      dataInput: {
        id_class: 0,
        student_gender: 3,
        student_name: "",
        student_birthday: "",
        student_identifier: "",
        student_note: "",
        not_eats: [],
        student_contact: null,
        student_population: null,
        start_at: "",
        student_ethnic: 1,
        price_return: "",
        student_address: {
          number: "",
          group: "",
          zone: "",
          street: "",
          ward: "",
          district: "",
          city: "",
        },
        student_father: {
          name: "",
          job: "",
          phone: "",
        },
        student_mother: {
          name: "",
          job: "",
          phone: "",
        },
      },
      btnClick: false,
      show: false,
      type: "",
      listclass: [],
      tabkey: 1,
      listEthnic: [],
      abledForm: props?.abledForm?.value,
      abledFormPause: !props?.abledForm?.value,
      listTimes: [],
      not_eats: [],
      err: {},
    };
  }
  async componentDidMount() {
    this.getEthnic();
    this.getlistuser();
    this.getlistclass();
    this.setState({ btnClick: false });
    this.getTimeOfDay();
  }
  getTimeOfDay = () => {
    axios
      .get("/getTimeOfDays")
      .then((res) => this.setState({ listTimes: res.data.times }));
  };

  getEthnic = () => {
    axios
      .get("/viewEthnic")
      .then((res) => this.setState({ listEthnic: res.data.data }));
  };

  getlistclass = () => {
    axios
      .get(`/classroom-shows?id_year=${this.props?.idYearNow}`)
      .then((res) => {
        this.setState({
          listclass: res.data?.data?.classrooms,
        });
        this.setState({ abledForm: false, abledFormPause: true });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleInput = (event) => {
    let field = event.target.name;
    let value = event.target.value;

    if (event.target.name === "is_meal") {
      if (event.target.checked) {
        value = 1;
      } else {
        value = 0;
      }
    }

    if (event.target.name === "student_status") {
      if (event.target.value == 1) {
        this.setState({ abledForm: false, abledFormPause: true });
      } else {
        this.setState({ abledForm: true, abledFormPause: false });
      }
    }

    if (event.target.name === "student_status") {
      if (event.target.value > 2 || event.target.value == 0) {
        toast("Trạng thái không đúng!", { type: "error" });
      }
    }

    if (field == "student_name") value = capitalizeEachWord(value);

    let dataInput = { ...this.state.dataInput };

    dataInput[field] = value;
    this.setState({ dataInput: dataInput });
    delete this.state.err[field];
    this.setState({ err: { ...this.state.err } });
  };

  handleInputaddress = (event) => {
    const { name, value } = event.target;
    this.setState((prevState) => {
      let studentAddress = prevState.dataInput.student_address;

      // Check if studentAddress is a string and parse it
      if (typeof studentAddress === "string") {
        try {
          studentAddress = JSON.parse(studentAddress);
        } catch (e) {
          console.error("Invalid JSON string:", studentAddress);
          studentAddress = {};
        }
      }

      // Ensure studentAddress is an object
      if (typeof studentAddress !== "object" || studentAddress === null) {
        studentAddress = {};
      }

      return {
        dataInput: {
          ...prevState.dataInput,
          student_address: {
            ...studentAddress,
            [name]: value,
          },
        },
      };
    });
  };

  handleInputparent = (event) => {
    const { name, value } = event.target;
    this.setState((prevState) => {
      let studentFather = prevState.dataInput.student_father;

      // Check if studentFather is a string and parse it
      if (typeof studentFather === "string") {
        try {
          studentFather = JSON.parse(studentFather);
        } catch (e) {
          console.error("Invalid JSON string:", studentFather);
          studentFather = {};
        }
      }

      // Ensure studentFather is an object
      if (typeof studentFather !== "object" || studentFather === null) {
        studentFather = {};
      }

      return {
        dataInput: {
          ...prevState.dataInput,
          student_father: {
            ...studentFather,
            [name]: value,
          },
        },
      };
    });
  };

  handleInputmother = (event) => {
    const { name, value } = event.target;
    this.setState((prevState) => {
      let studentMother = prevState.dataInput.student_mother;

      // Check if studentMother is a string and parse it
      if (typeof studentMother === "string") {
        try {
          studentMother = JSON.parse(studentMother);
        } catch (e) {
          console.error("Invalid JSON string:", studentMother);
          studentMother = {};
        }
      }

      // Ensure studentMother is an object
      if (typeof studentMother !== "object" || studentMother === null) {
        studentMother = {};
      }

      return {
        dataInput: {
          ...prevState.dataInput,
          student_mother: {
            ...studentMother,
            [name]: value,
          },
        },
      };
    });
  };

  handleChangeTime = (times) => {
    this.setState((prevState) => {
      let Times = prevState.dataInput.not_eats;

      // Check if studentMother is a string and parse it
      if (typeof Times === "string") {
        try {
          Times = JSON.parse(Times);
        } catch (e) {
          Times = {};
        }
      }

      // Ensure studentMother is an object
      if (typeof Times !== "object" || Times === null) {
        Times = {};
      }

      return {
        dataInput: {
          ...prevState.dataInput,
          not_eats: times,
        },
      };
    });

    // this.setState({
    //   dataInput: {
    //     ...this.state.dataInput,
    //     not_eats: times,
    //   },
    // });
  };

  handleSubmit = (event) => {
    event.preventDefault();

    let data = [];
    data = { ...this.state.dataInput };

    data.student_birthday =
      new Date(this.state.dataInput.student_birthday) / 1000;
    data.not_eats =
      this.state.dataInput.not_eats?.length > 1
        ? JSON.stringify([...this.state.dataInput.not_eats])
        : "";
    data.start_at = new Date(this.state.dataInput.start_at) / 1000;
    data.student_mother = JSON.stringify({
      ...this.state.dataInput.student_mother,
    });
    data.student_father = JSON.stringify({
      ...this.state.dataInput.student_father,
    });
    data.student_address = JSON.stringify({
      ...this.state.dataInput.student_address,
    });

    axios
      .post(`/student-create`, data, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {
        this.props.onSave();
        toast(res.data.messages, { type: "success" });
      })
      .catch((error) => {
        this.setState({ err: error.response.data.messages });
      });
  };

  dataClass() {
    if (this.state.listclass?.length > 0) {
      return this.state.listclass?.map((item, i) => {
        return (
          <option key={i} value={item.id}>
            {item.class_name}
          </option>
        );
      });
    } else {
      return this.props?.listclass?.map((item, i) => {
        return (
          <option key={i} value={item.id}>
            {item.class_name}
          </option>
        );
      });
    }
  }

  getlistuser = () => {
    axios
      .get("/showAllUser")
      .then((res) => {
        if (res.data.status) {
          this.setState({
            listUser: res.data.data,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  formclose = () => {
    this.setState({ btnClick: false });
    this.setState({ show: false });
  };

  onSave = () => {
    this.setState({ btnClick: false });
    this.setState({ show: false });
    this.getlistclass();
    this.props.onSaveClass();
  };

  renderClassAdd() {
    return (
      <ClassCreate
        listuser={this.state.listUser}
        PT={this.state.type}
        show={this.state.show}
        close={this.formclose}
        onSave={this.onSave}
        _class={[]}
      />
    );
  }

  getOptionTimes = () => {
    var arr_ = [];

    this.state.listTimes?.map((item, index) =>
      item?.time_name_now != null
        ? arr_.push({ value: item.id, label: item.time_name_now })
        : null
    );

    const nodes = [
      {
        value: "0",
        label: "Không ăn cả ngày ",
        children: arr_,
      },
    ];

    const convertArrayToObject = (array) => {
      const obj = {};
      array.forEach((value, index) => {
        obj[`item_${index}`] = value;
      });
      return obj;
    };

    return (
      <>
        <CheckboxTree
          nodes={nodes || []}
          showExpandAll
          checked={[...(this.state.dataInput?.not_eats || [])]}
          expanded={[0]}
          onCheck={(checked) => {
            this.setState({
              dataInput: {
                ...this.state.dataInput,
                not_eats: checked,
              },
            });
          }}
          icons={{
            check: (
              <i
                className="fa-solid fa-square-check"
                style={{ color: "#005eff" }}
              ></i>
            ),
            uncheck: (
              <i
                className="fa-regular fa-square"
                style={{ color: "#005eff" }}
              ></i>
            ),
            halfCheck: (
              <i
                className="fa-regular fa-square "
                style={{ color: "#99beff" }}
              ></i>
            ),
            expandClose: "",
            expandOpen: "",
            parentClose: "",
            parentOpen: "",
            leaf: "",
          }}
        />
      </>
    );
  };

  render() {
    return (
      <>
        <Modal
          {...this.props}
          size="lg"
          backdrop="static"
          keyboard={false}
          id="addStudentModal"
        >
          <Modal.Header closeButton>
            <Modal.Title className="text-center">Thêm học sinh</Modal.Title>
          </Modal.Header>
          <Form onSubmit={this.handleSubmit}>
            <Modal.Body>
              <Tabs
                // activeKey={this.state.tabkey}
                onSelect={(k) => {
                  this.setState({ tabkey: k });
                }}
                defaultActiveKey={1}
                className="mb-3"
              >
                <Tab key={1} title="Thông tin học sinh" eventKey={1}>
                  <Row>
                    <Col>
                      <Form.Floating className="mb-3">
                        <Form.Control
                          // {/* <CurrencyFormat */}
                          //   {/* customInput={Form.Control} format="###################################" */}
                          type="text"
                          defaultValue={this.state.student_identifier}
                          onChange={this.handleInput}
                          name="student_identifier"
                          id="student_identifier"
                          placeholder="Mã định danh Bộ GD&ĐT"
                          // readOnly
                          // required
                        />
                        <Form.Label>Mã định danh Bộ GD&ĐT</Form.Label>
                        <span className="text-form-err">
                          {this.state.err?.student_identifier}
                        </span>
                      </Form.Floating>
                    </Col>
                    <Col>
                      <Form.Floating className="mb-3">
                        <Form.Control
                          type="text"
                          defaultValue={this.state.student_population}
                          onChange={this.handleInput}
                          name="student_population"
                          id="student_population"
                          placeholder="Mã định danh dân cư"
                          // required
                        />
                        <Form.Label>Mã định danh dân cư</Form.Label>
                        <span className="text-form-err">
                          {this.state.err?.student_population}
                        </span>
                      </Form.Floating>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={8}>
                      {new Check().permission(["13"]) ? <br /> : null}
                      <Form.Floating>
                        <Form.Control
                          type="text"
                          value={this.state.dataInput.student_name}
                          onChange={this.handleInput}
                          name="student_name"
                          id="student_name"
                          placeholder="Tên học sinh"
                          required
                        />
                        <Form.Label>
                          Tên học sinh<i className="text-danger">*</i>
                        </Form.Label>
                        <span className="text-form-err">
                          {this.state.err?.student_name}
                        </span>
                      </Form.Floating>
                    </Col>
                    <Col md={4}>
                      {new Check().permission(["13"]) ? (
                        <Form.Group className="text-end">
                          <Link
                            className="fst-italic"
                            onClick={() => {
                              this.setState({
                                show: true,
                                type: "TM",
                              });
                            }}
                          >
                            +Thêm lớp
                          </Link>
                        </Form.Group>
                      ) : null}
                      <Form.Floating className="mb-3">
                        <Form.Select
                          defaultValue={this.state.id_class}
                          onChange={this.handleInput}
                          name="id_class"
                          required
                        >
                          <option value="">--Chọn--</option>
                          {this.dataClass()}
                        </Form.Select>
                        <Form.Label>
                          Lớp<i className="text-danger">*</i>
                        </Form.Label>
                        <span className="text-form-err">
                          {this.state.err?.id_class}
                        </span>
                      </Form.Floating>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={4}>
                      <Form.Floating className="mb-3">
                        <Form.Select
                          value={this.state.dataInput.student_gender}
                          onChange={this.handleInput}
                          name="student_gender"
                          required
                        >
                          <option value="">--Chọn--</option>
                          <option value="1">Nam</option>
                          <option value="2">Nữ</option>
                          <option value="3">Khác</option>
                        </Form.Select>
                        <Form.Label>
                          Giới tính
                          <i className="text-danger">*</i>
                        </Form.Label>
                        <span className="text-form-err">
                          {this.state.err?.student_gender}
                        </span>
                      </Form.Floating>
                    </Col>
                    <Col md={4}>
                      <Form.Floating className="mb-3">
                        <input
                          type="date"
                          className="form-control"
                          required
                          defaultValue={this.state.student_birthday}
                          name="student_birthday"
                          onChange={this.handleInput}
                          // onChange={(e) => {
                          //   onChange = { this.handleInput }
                          //   console.log('values>>', values)
                          // }}
                        />
                        <Form.Label>
                          Ngày sinh
                          <i className="text-danger">*</i>
                        </Form.Label>
                        <span className="text-form-err">
                          {this.state.err?.student_birthday}
                        </span>
                      </Form.Floating>
                    </Col>
                    <Col md={4}>
                      <Form.Floating className="mb-3">
                        <Form.Select
                          value={this.state.dataInput.student_ethnic}
                          onChange={this.handleInput}
                          name="student_ethnic"
                        >
                          <option value="">--Chọn--</option>
                          {this.state.listEthnic.length > 0 ? (
                            this.state.listEthnic.map((ethnic_) => {
                              return (
                                <option
                                  key={`ethnic_${ethnic_.id}`}
                                  value={ethnic_.id}
                                >
                                  {ethnic_.ethnic_name}
                                </option>
                              );
                            })
                          ) : (
                            <></>
                          )}
                        </Form.Select>

                        <Form.Label>Dân tộc</Form.Label>
                        <span className="text-form-err">
                          {this.state.err?.student_ethnic}
                        </span>
                      </Form.Floating>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={4}>
                      <Form.Floating className="mb-3">
                        <input
                          type="date"
                          className="form-control"
                          required
                          name="start_at"
                          onChange={this.handleInput}
                          // required={!this.state.abledForm}
                          disabled={this.state.abledForm}
                        />
                        <Form.Label>
                          Ngày nhập học
                          {this.state.abledForm ? (
                            ""
                          ) : (
                            <i className="text-danger">*</i>
                          )}
                        </Form.Label>
                        <span className="text-form-err">
                          {this.state.err?.start_at}
                        </span>
                      </Form.Floating>
                    </Col>
                    <Col md={4}>
                      <Form.Floating className="mb-3">
                        <Form.Control
                          type="text"
                          defaultValue={this.state.dataInput.price_return}
                          onChange={this.handleInput}
                          name="price_return"
                          placeholder="Tiền trả lại"
                          disabled={
                            this.state.dataInput?.not_eats.length > 0
                              ? false
                              : true
                          }
                          required={
                            this.state.dataInput?.not_eats.length > 0
                              ? true
                              : false
                          }
                        />
                        <Form.Label>
                          Tiền trả lại
                          {this.state.dataInput?.not_eats.length > 0 ? (
                            <i className="text-danger">*</i>
                          ) : (
                            <></>
                          )}
                        </Form.Label>
                        <span className="text-form-err">
                          {this.state.err?.price_return}
                        </span>
                      </Form.Floating>
                    </Col>
                    <Col md={4}>
                      <Form.Floating className="mb-3">
                        <Form.Control
                          type="text"
                          defaultValue={this.state.student_contact}
                          onChange={this.handleInput}
                          name="student_contact"
                          placeholder="SĐT liên hệ"
                        />
                        <Form.Label>SĐT liên hệ</Form.Label>
                        <span className="text-form-err">
                          {this.state.err?.student_contact}
                        </span>
                      </Form.Floating>
                    </Col>
                  </Row>
                  <Row></Row>

                  <Row>
                    <Col>{this.getOptionTimes()}</Col>

                    <Col md={8}>
                      <Form.Floating className="mb-3">
                        <Form.Control
                          type="text"
                          defaultValue={this.state.student_note}
                          onChange={this.handleInput}
                          name="student_note"
                          placeholder="Ghi chú"
                        />
                        <Form.Label>Ghi chú</Form.Label>
                        <span className="text-form-err">
                          {this.state.err?.student_note}
                        </span>
                      </Form.Floating>
                    </Col>
                  </Row>
                </Tab>
                <Tab key={2} title="Thông tin gia đình" eventKey={2}>
                  <Row>
                    <Col md={6}>
                      <p style={{ fontWeight: "bold" }}>Thông tin cha</p>
                      <Form.Floating className="mb-3">
                        <Form.Control
                          type="text"
                          onChange={this.handleInputparent}
                          name="name"
                          id="name"
                          placeholder="Họ và tên"
                        />
                        <Form.Label>Họ và tên</Form.Label>{" "}
                        <span className="text-form-err">
                          {this.state.err[`student_father.name`]}
                        </span>
                      </Form.Floating>

                      <Form.Floating className="mb-3">
                        <Form.Control
                          type="text"
                          onChange={this.handleInputparent}
                          name="job"
                          id="job"
                          placeholder="Nghề nghiệp"
                        />
                        <Form.Label>Nghề nghiệp</Form.Label>{" "}
                        <span className="text-form-err">
                          {this.state.err[`student_father.job`]}
                        </span>
                      </Form.Floating>

                      <Form.Floating className="mb-3">
                        <Form.Control
                          type="text"
                          onChange={this.handleInputparent}
                          name="phone"
                          id="phone"
                          placeholder="Số điện thoại"
                        />
                        <Form.Label>Số điện thoại</Form.Label>
                        <span className="text-form-err">
                          {this.state.err[`student_father.phone`]}
                        </span>
                      </Form.Floating>
                    </Col>
                    <Col md={6}>
                      <p style={{ fontWeight: "bold" }}>Thông tin mẹ</p>
                      <Form.Floating className="mb-3">
                        <Form.Control
                          type="text"
                          onChange={this.handleInputmother}
                          name="name"
                          id="name"
                          placeholder="Họ và tên"
                        />
                        <Form.Label>Họ và tên</Form.Label>
                        <span className="text-form-err">
                          {this.state.err[`student_mother?.name`]}
                        </span>
                      </Form.Floating>
                      <Form.Floating className="mb-3">
                        <Form.Control
                          type="text"
                          onChange={this.handleInputmother}
                          name="job"
                          id="job"
                          placeholder="Nghề nghiệp"
                        />

                        <Form.Label>Nghề nghiệp</Form.Label>
                        <span className="text-form-err">
                          {this.state.err["student_mother.job"]}
                        </span>
                      </Form.Floating>

                      <Form.Floating className="mb-3">
                        <Form.Control
                          type="text"
                          onChange={this.handleInputmother}
                          name="phone"
                          id="phone"
                          placeholder="Số điện thoại"
                        />
                        <Form.Label>Số điện thoại</Form.Label>
                        <span className="text-form-err">
                          {this.state.err[`student_mother.phone`]}
                        </span>
                      </Form.Floating>
                    </Col>
                  </Row>

                  <p style={{ fontWeight: "bold" }}>Địa chỉ gia đình</p>
                  <Row>
                    <Col md={3}>
                      <Form.Floating className="mb-3">
                        <Form.Control
                          type="text"
                          onChange={this.handleInputaddress}
                          name="number"
                          id="number"
                          placeholder="Số nhà"
                        />
                        <Form.Label>Số nhà</Form.Label>
                      </Form.Floating>
                    </Col>
                    <Col md={2}>
                      <Form.Floating className="mb-3">
                        <Form.Control
                          type="text"
                          onChange={this.handleInputaddress}
                          name="group"
                          id="group"
                          placeholder="Tổ"
                        />
                        <Form.Label>Tổ</Form.Label>
                      </Form.Floating>
                    </Col>
                    <Col md={3}>
                      <Form.Floating className="mb-3">
                        <Form.Control
                          type="text"
                          onChange={this.handleInputaddress}
                          name="zone"
                          id="zone"
                          placeholder="Khu vực"
                        />
                        <Form.Label>Khu vực</Form.Label>
                      </Form.Floating>
                    </Col>
                    <Col md={4}>
                      <Form.Floating className="mb-3">
                        <Form.Control
                          type="text"
                          onChange={this.handleInputaddress}
                          name="street"
                          id="street"
                          placeholder="Đường"
                        />
                        <Form.Label>Đường</Form.Label>
                      </Form.Floating>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={4}>
                      <Form.Floating className="mb-3">
                        <Form.Control
                          type="text"
                          onChange={this.handleInputaddress}
                          name="ward"
                          id="ward"
                          placeholder="Phường / Xã"
                        />
                        <Form.Label>Phường / Xã</Form.Label>
                      </Form.Floating>
                    </Col>
                    <Col md={4}>
                      <Form.Floating className="mb-3">
                        <Form.Control
                          type="text"
                          onChange={this.handleInputaddress}
                          name="distric"
                          id="distric"
                          placeholder="Quận / Huyện"
                        />
                        <Form.Label>Quận / Huyện</Form.Label>
                      </Form.Floating>
                    </Col>
                    <Col md={4}>
                      <Form.Floating className="mb-3">
                        <Form.Control
                          type="text"
                          onChange={this.handleInputaddress}
                          name="city"
                          id="city"
                          placeholder="Tỉnh / Thành phố"
                        />
                        <Form.Label>Tỉnh / Thành phố</Form.Label>
                      </Form.Floating>
                    </Col>
                  </Row>
                </Tab>
              </Tabs>
            </Modal.Body>
            <Modal.Footer>
              <Button
                size="sm"
                variant="secondary"
                id="btnCloseAddstudent"
                onClick={() => {
                  this.setState({
                    tabkey: 1,
                    abledForm: false,
                    abledFormPause: true,
                  });
                  this.props.onHide();
                }}
              >
                <i className="fa-solid fa-times"></i> Đóng
              </Button>

              <Button
                size="sm"
                variant="success"
                type="submit"
                disabled={this.state.btnClick}
              >
                <i className="fa-solid fa-check"></i> Lưu
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
        {this.renderClassAdd()}
      </>
    );
  }
}

export default StudentCreate;
