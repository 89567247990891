import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import swal from "sweetalert";
import moment from "moment";

import { Breadcrumb, Button, Card, Container } from "react-bootstrap";
import DataTables from "../../admin/datatable/DataTables";
import EditSetting from "./EditSetting";
import Check from "../other/Check";

const MainSetting = () => {
  const navigate = useNavigate();
  const [settings, setSettings] = useState([]);
  const [selectedSetting, setSelectedSetting] = useState(0);

  useEffect(() => {
    document.title = "Tiền ăn - Ngày học";
    getListSettings();
  }, []);

  const getListSettings = async () => {
    await axios
      .get(`/setting-show`)
      .then((res) => {
        if (res.data.errCode === 0) {
          setSettings(res.data.settings);

          const today = new Date().getTime() / 1000;

          setSelectedSetting(
            res.data.settings.find(
              (setting) =>
                setting.setting_date_start <= today &&
                today <= setting.setting_date_end
            )?.id || 0
          );
        } else {
          toast(res.data.message, {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteSetting = (settingItem) => {
    swal({
      title: "Bạn muốn xóa cài đặt?",
      text: `Thời gian "${moment(settingItem.setting_date_start * 1000).format(
        "DD/MM/YYYY"
      )} - ${moment(settingItem.setting_date_end * 1000).format(
        "DD/MM/YYYY"
      )}"`,
      icon: "error",
      buttons: ["Đóng", "Xóa"],
      dangerMode: true,
    }).then((ok) => {
      if (ok) {
        axios
          .delete(`/setting-destroy?`, {
            params: {
              id: settingItem.id,
            },
          })
          .then((res) => {
            if (res.data.errCode === 0) {
              toast(res.data.message, {
                type: "success",
                autoClose: 1000,
              });

              getListSettings();
            } else {
              toast(res.data.message, {
                type: "error",
                autoClose: 1000,
              });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };

  const columns = [
    {
      name: "Ngày bắt đầu",
      selector: (row) =>
        moment(row.setting_date_start * 1000).format("DD/MM/YYYY"),
      center: true,
      right: true,
    },
    {
      name: "Ngày kết thúc",
      selector: (row) =>
        moment(row.setting_date_end * 1000).format("DD/MM/YYYY"),
      center: true,
      right: true,
    },
    {
      name: "Phương thức",
      center: true,
      cell: (row) => (
        <>
          <Button
            size="sm"
            variant="warning me-2"
            type="button"
            onClick={() => {
              setSelectedSetting(row.id);
              window.scrollTo(0, 0);
            }}
          >
            {new Check().permission(["60"]) ? (
              <i className="fa-solid fa-pencil"></i>
            ) : (
              <i className="fa-solid fa-eye"></i>
            )}
          </Button>

          {new Check().permission(["98"]) ? (
            <Button
              size="sm"
              variant="danger"
              type="button"
              onClick={() => deleteSetting(row)}
            >
              <i className="fas fa-trash"></i>
            </Button>
          ) : (
            <></>
          )}
        </>
      ),
    },
  ];

  return (
    <Container fluid className="px-3">
      <Breadcrumb className="mb-2 mt-2">
        <Breadcrumb.Item active>Cài đặt</Breadcrumb.Item>
        <Breadcrumb.Item active>Tiền ăn - Ngày học</Breadcrumb.Item>
      </Breadcrumb>
      <Card className="mb-3">
        <Card.Header>
          <Card.Title>
            <i className="fa-solid fa-gear"></i> Tiền ăn - Ngày học
          </Card.Title>
        </Card.Header>

        {settings.length > 0 ? (
          <EditSetting settingId={selectedSetting} onUpdate={getListSettings} />
        ) : (
          navigate("/admin-v2/mainsetting/create-setting")
        )}
      </Card>

      <Card className="mb-2">
        <Card.Header className="d-flex justify-content-between align-items-center">
          <Card.Title>
            <i className="fa-solid fa-list"></i> Danh sách Tiền ăn - Ngày học
          </Card.Title>

          {new Check().permission(["60"]) ? (
            <Button
              size="sm"
              variant="primary"
              onClick={() => navigate("/admin-v2/mainsetting/create-setting")}
              className="px-3"
              title="Thêm"
            >
              <i className="fas fa-plus"></i> Thêm
            </Button>
          ) : (
            <></>
          )}
        </Card.Header>

        <Card.Body>
          <DataTables data={settings} columns={columns} />
        </Card.Body>
      </Card>
    </Container>
  );
};

export default MainSetting;
