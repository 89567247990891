import axios from "axios";
import React, { Component } from "react";

import {
  Breadcrumb,
  Button,
  Card,
  Container,
  Tab,
  Table,
  Tabs,
  Row,
  Col,
  Modal,
  Form,
} from "react-bootstrap";
import * as XLSX from "xlsx-js-style";
import GroupCreate from "./GroupCreate";
import swal from "sweetalert";
import { toast } from "react-toastify";
import Check from "../other/Check";
import ClassCreate from "../classV2_del/ClassCreate";
import ClassUpdate from "../classV2_del/ClassUpdate";
import DataTables from "../datatable/DataTables";
import { getDate } from "../utils/helpers";
import { Link } from "react-router-dom";
import LoadingSpinner from "../other/LoadingSpinner";

class GroupIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listclass: [],
      tabkey: 0,
      listgroup: [],
      showcreate: false,
      groupname: "",
      idgroup: "",
      isMain: "",
      recommended_from: 0,
      recommended_to: 0,
      id_groupAge: 0,
      enegry_ratio: 0,
      alertShow: false,
      selectedRows: [],
      checkload: true,
      listclasssearch: [],
      showDetail: false,
      listclassID: [],
      listEthnic: [],
      _class: [],
      show: false,
      type: "",
      listuser: [],
      schoolYears: [],
      id_year: "",

      groups: [],
      showModalUpdateClass: false,
      id_class: "",
      itemclass: {},
      studentFilter: "now",
      studentSearch: "",
    };
  }

  componentDidMount = async () => {
    document.title = "Nhóm học sinh";

    this.getEthnic();
    this.getlistuser();
    await this.getYearSchool();
  };

  getEthnic = async () => {
    await axios
      .get("/viewEthnic")
      .then((res) => this.setState({ listEthnic: res.data.data }));
  };

  formclose = async () => {
    this.setState({ show: false, err: {}, showModalUpdateClass: false });
    this.getlistclass(this.state.idgroup, this.state.id_year);
  };

  getYearSchool = async () => {
    await axios.get(`/school-year-shows`).then((res) => {
      if (res.data.data?.status === true) {
        const currentTime = Math.floor(Date.now() / 1000);
        const filteredData = res.data.data?.schoolYears?.filter(
          (item) =>
            item.start_date <= currentTime && item.end_date >= currentTime
        );
        const id_year = filteredData.length > 0 ? filteredData[0].id : "";

        this.setState(
          {
            schoolYears: res.data.data?.schoolYears || [],
            id_year: id_year,
          },
          () => {
            this.getlistgroup();
          }
        );
      }
    });
  };

  getlistuser = async () => {
    await axios
      .get("/showAllUser")
      .then((res) => {
        if (res.data.status) {
          this.setState({
            listuser: res.data.data,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  xport = (classroomName = "") => {
    const table = document.getElementById("StudentTable");
    const wb = XLSX.utils.book_new();
    var ws = XLSX.utils.table_to_sheet(table, { raw: true });

    const colAlpha = ["A", "B", "C", "D", "E", "F", "G", "H"];
    var wscols = [
      { wch: 5 },
      { wch: 25 },
      { wch: 20 },
      { wch: 20 },
      { wch: 10 },
      { wch: 10 },
      { wch: 10 },
      { wch: 11 },
    ];
    ws["!cols"] = wscols;

    const range = XLSX.utils.decode_range(ws["!ref"]);
    const borderStyle = {
      font: {
        name: "Times New Roman",
        sz: 11,
      },
      alignment: {
        wrapText: true,
      },
      border: {
        top: { style: "thin" },
        bottom: { style: "thin" },
        left: { style: "thin" },
        right: { style: "thin" },
      },
    };

    const startRow = 0;
    const endRow = range.e.r;

    for (let row = startRow; row <= endRow; row++) {
      for (let col = range.s.c; col <= range.e.c; col++) {
        const cellAddress = XLSX.utils.encode_cell({ r: row, c: col });
        const cell = ws[cellAddress];

        if (cell.s == undefined) cell.s = {};

        cell.s = {
          ...cell.s,
          ...borderStyle,
          alignment: {
            vertical: "center",
            horizontal:
              col === 0 || col === 4 || col === 5
                ? "center"
                : col === 3
                ? "right"
                : "left",
          },
        };

        if (cell.v === "empty") {
          cell.v = "";
        }
      }
    }

    colAlpha.map((alpha) => {
      ws[`${alpha}1`].s = {
        font: {
          name: "Times New Roman",
          sz: 11,
          bold: true,
        },
        alignment: {
          vertical: "center",
          horizontal: "center",
        },
        border: {
          top: { style: "thin" },
          bottom: { style: "thin" },
          left: { style: "thin" },
          right: { style: "thin" },
        },
      };

      return alpha;
    });

    XLSX.utils.book_append_sheet(
      wb,
      ws,
      classroomName !== "" ? classroomName : "Sheet 1"
    );

    XLSX.writeFile(
      wb,
      `DSHS${classroomName !== "" ? ` lớp ${classroomName}` : ""}.xlsx`
    );
  };

  changeEmpty = (element) => {
    return element == "" ||
      element == null ||
      element == "null" ||
      element == undefined ||
      element == "undefined"
      ? "empty"
      : element;
  };

  handleStudentSearch = (
    root_data,
    text,
    filter = this.state.studentFilter,
    id_class = this.state.id_class
  ) => {
    let root_ = root_data;
    const today = Math.floor(Date.now() / 1000);

    if (filter === "now") {
      root_ = root_.filter((student) => {
        return (
          student.classHistories.some((classHistory) => {
            const startAt = classHistory.start_at;
            const endAt = classHistory.end_at || Infinity;

            return (
              today >= startAt &&
              today <= endAt &&
              id_class === classHistory.id_class
            );
          }) && student.student_status === "1"
        );
      });
    }

    if (text !== "") {
      root_ = root_.filter((e) =>
        this.toSlug(e.student_name).includes(this.toSlug(text))
      );
    }

    return this.setState({ listclasssearch: root_ });
  };

  getDate = (data) => {
    const date = new Date(data * 1000);
    date.setHours(0, 0, 0, 0);
    return (
      ("0" + date.getDate()).slice(-2) +
      "/" +
      ("0" + (date.getMonth() + 1)).slice(-2) +
      "/" +
      date.getFullYear()
    );
    // return ('0' + date.getDate()).slice(-2) + '/' + ('0' + (date.getMonth() + 1)).slice(-2) + '/' + date.getFullYear();
  };
  getClassbyId = (id) => {
    axios.get(`/student-shows?id_class=${id}`).then((res) => {
      this.setState({
        listclassID: res.data.data?.students,
        id_class: id,
      });

      this.handleStudentSearch(res.data.data?.students, "", "now", id);
    });
  };

  toSlug = (str) => {
    str = str.toLowerCase(); // Chuyển hết sang chữ thường
    // xóa dấu
    str = str
      .normalize("NFD") // chuyển chuỗi sang unicode tổ hợp
      .replace(/[\u0300-\u036f]/g, ""); // xóa các ký tự dấu sau khi tách tổ hợp
    str = str.replace(/[đĐ]/g, "d"); // Thay ký tự đĐ
    str = str.replace(/([^0-9a-z-\s])/g, ""); // Xóa ký tự đặc biệt
    return str;
  };

  getlistgroup = async () => {
    this.setState({ checkload: true });
    await axios
      .get("/group-show")
      .then((res) => {
        if (res.data.errCode == 0) {
          if (res.data.groups.length > 0) {
            this.setState({ groups: res.data.groups });
            var arr = res.data.groups;

            this.setState({
              groupname: arr[this.state.tabkey]?.group_name,
              idgroup: arr[this.state.tabkey]?.id,
              isMain: arr[this.state.tabkey]?.is_main,
              recommended_from: arr[this.state.tabkey]?.recommended_from,
              recommended_to: arr[this.state.tabkey].recommended_to,
              enegry_ratio: arr[this.state.tabkey]?.enegry_ratio,
              id_groupAge: arr[this.state.tabkey]?.id_age_group,
              listgroup: arr,
            });

            this.getlistclass(arr[this.state.tabkey]?.id, this.state.id_year);
          } else {
            this.setState({ listgroup: [] });
          }
        }

        this.setState({ checkload: false });
      })
      .catch((error) => {
        toast(error.message, { type: "error", autoClose: 1000 });
        this.setState({ checkload: false });
      });
  };

  getlistclass = (id, id_year = null) => {
    axios
      .get(
        `/classroom-shows?id_group=${id}${
          id_year !== null ? `&id_year=${id_year}` : ""
        }`
      )
      .then((res) => {
        if (res.status === 200) {
          this.setState({
            listclass: res.data.data.classrooms,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  deletegroup = (event) => {
    let data = {
      id: this.state.idgroup,
    };

    swal({
      title: "Bạn muốn xóa nhóm học sinh",
      icon: "error",
      buttons: ["Đóng", "Xóa"],

      buttons: true,
      successMode: true,
    }).then((ok) => {
      if (ok) {
        axios({
          method: "delete",
          url: "group-destroy",
          data: data,
        })
          .then((res) => {
            this.setState({ tabkey: 0 });
            this.getlistgroup();

            toast(`${res.data.message}`, {
              type: res.data.errCode == 0 ? "success" : "error",
              autoClose: 1000,
            });
          })
          .catch((error) => {
            const res = error.response;
            toast(`${res.data?.messages.id || error.message}`, {
              type: "error",
              autoClose: 1000,
            });
          });
      }

      this.getlistgroup();
    });
  };

  deleteclass = (id, event) => {
    swal({
      title: "Bạn muốn xóa lớp học?",
      icon: "error",
      buttons: ["Đóng", "Xóa"],
      dangerMode: true,
    }).then((ok) => {
      if (ok) {
        axios
          .delete(`/classroom-destroy?id=${id}`)
          .then((res) => {
            this.getlistclass(this.state.idgroup, this.state.id_year);

            toast(`${res.data.messages}`, { type: "success" });
          })
          .catch((error) => {
            let message = "";

            if (error.response.status === 400) {
              const jsonObject = error.response.data.messages;
              for (const [key, value] of Object.entries(jsonObject)) {
                message += `${value} `;
              }
            } else {
              message = error.message;
            }

            toast(`${message}`, { type: "error" });
          });
      }
    });
  };
  creategroupClose = () => {
    this.setState({ showcreate: false, err: {} });
    this.getlistgroup();
  };

  renderAddGroupModal = () => {
    return (
      <GroupCreate
        groupname={this.state.groupname}
        idgroup={this.state.idgroup}
        PT={this.state.type}
        show={this.state.showcreate}
        close={this.creategroupClose}
        recommended_from={this.state.recommended_from}
        recommended_to={this.state.recommended_to}
        enegry_ratio={this.state.enegry_ratio}
        id_groupAge={this.state.id_groupAge}
      />
    );
  };

  dataTable() {
    const columns = [
      {
        name: "STT",
        selector: (row) => row.i,
        sortable: true,
        width: "80px",
      },
      {
        name: "Tên lớp",
        selector: (row) => row.class_name,
        sortable: true,
      },
      {
        name: "Năm học",
        selector: (row) => row.year_name,
        sortable: true,
      },
      {
        name: "Giáo viên",
        selector: (row) => row.teacher_name,
        sortable: true,
        style: {
          div: {
            width: "250px",
          },
        },
      },

      {
        name: "SL Hiện tại",
        selector: (row) => row.countStudent,
        sortable: true,
        center: true,
      },
      {
        name: "Cập nhật",
        selector: (row) => row.updated_at,
        sortable: true,
        right: true,
        cell: (row) => <>{row.updated_at ? getDate(row.updated_at) : ""}</>,
      },
      {
        name: "Phương thức",
        selector: (row) => row.setting,
        center: true,
        minWidth: "200px",
      },
    ];

    const data = [];

    const selectedRows = (items) => {
      this.setState({ selectedRows: items, alertShow: true });
    };

    if (this.state.listclass.length > 0) {
      this.state.listclass.map((item, i) => {
        data.push({
          i: i + 1,
          id: item.id,
          class_name: item.class_name,
          year_name: item.year_name,
          teacher_name: item.teacher_name,
          countStudent: item.countStudent,
          updated_at: item.updated_at,
          setting: (
            <>
              {new Check().permission(["106"]) ? (
                <Link to={`/admin-v2/schedule/${item.id}`}>
                  <Button variant="info" title="Thời khóa biểu" size="sm">
                    <i className="fa-solid fa-calendar-days"></i>
                  </Button>
                </Link>
              ) : (
                <></>
              )}
              &nbsp;
              {new Check().permission(["17"]) ? (
                <>
                  <Button
                    variant="primary"
                    title="Xem danh sách học sinh"
                    size="sm"
                    onClick={() => {
                      this.setState({
                        showDetail: true,
                        itemclass: item,
                        studentFilter: "now",
                      });

                      this.getClassbyId(item.id);
                    }}
                  >
                    <i className="fa-solid fa-circle-info" />
                  </Button>{" "}
                </>
              ) : null}
              {new Check().permission(["14", "12"], "or") ? (
                <Button
                  variant="warning"
                  title="Chi tiết lớp học"
                  size="sm"
                  onClick={() => {
                    this.setState({ id_class: item.id });
                    this.setState({ showModalUpdateClass: true });
                  }}
                >
                  <i
                    className={`fa-solid ${
                      new Check().permission(["14"]) ? "fa-pencil" : "fa-eye"
                    }`}
                  />
                </Button>
              ) : null}
              &nbsp;
              {new Check().permission(["15"]) ? (
                <Button
                  variant="danger"
                  title="Xóa"
                  size="sm"
                  onClick={() => {
                    this.deleteclass(item.id);
                  }}
                >
                  <i className="fas fa-trash"></i>
                </Button>
              ) : null}
            </>
          ),
        });

        return item;
      });
    }

    const totalStudent = data.reduce(
      (total, record) => total + record.countStudent,
      0
    );
    const totalRow = {
      countStudent: <strong>Tổng: {totalStudent}</strong>,
    };

    const temporaryData = [...data, totalRow];
    return (
      <>
        <DataTables
          data={temporaryData}
          columns={columns}
          selectedRows={selectedRows}
        />
      </>
    );
  }
  render() {
    const today = Math.floor(Date.now() / 1000);

    const data = (
      <>
        <Container fluid>
          <Breadcrumb className="mb-2 mt-2">
            <Breadcrumb.Item active>Học sinh</Breadcrumb.Item>
            <Breadcrumb.Item active>Nhóm học sinh</Breadcrumb.Item>
          </Breadcrumb>

          <Card>
            <Card.Header className="d-block d-xl-flex justify-content-between">
              <Card.Title>
                <i className="fas fa-list me-1"></i> Danh sách nhóm học sinh
              </Card.Title>

              <div className="d-block d-md-flex my-xl-0 align-items-center">
                {new Check().permission(["8"]) ? (
                  <Button
                    type="button"
                    variant="success"
                    style={{ marginLeft: "5px", float: "right" }}
                    size="sm"
                    onClick={() => {
                      this.setState({
                        showcreate: true,
                        type: "TM",
                        groupname: "",
                        recommended_from: 0,
                        recommended_to: 0,
                        id_groupAge: 0,
                        enegry_ratio: 0,
                      });
                    }}
                  >
                    <i className="fa-solid fa-plus"></i> Thêm nhóm
                  </Button>
                ) : null}

                {new Check().permission(["7"]) ? (
                  <Button
                    hidden={
                      this.state.listgroup.length === 0 ||
                      this.state.idgroup == 0
                        ? true
                        : false
                    }
                    type="button"
                    variant="warning"
                    size="sm"
                    style={{ marginLeft: "5px", float: "right" }}
                    onClick={() => {
                      this.setState({
                        type: "CN",
                        recommended_from: this.state.recommended_from,
                        recommended_to: this.state.recommended_to,
                        enegry_ratio: this.state.enegry_ratio,
                        id_groupAge: this.state.id_groupAge,
                        showcreate: true,
                      });
                    }}
                    title="Chi tiết nhóm"
                  >
                    {new Check().permission(["9"]) ? (
                      <>
                        <i className="fa-solid fa-pencil" /> Sửa nhóm
                      </>
                    ) : (
                      <>
                        <i className="fa-solid fa-eye" /> Xem nhóm
                      </>
                    )}
                  </Button>
                ) : null}

                {new Check().permission(["10"]) ? (
                  <>
                    <Button
                      hidden={
                        this.state.listgroup.length === 0 ||
                        Number(
                          this.state.isMain == 0 || this.state.idgroup == 0
                        )
                          ? true
                          : false
                      }
                      type="button"
                      variant="danger"
                      size="sm"
                      style={{ marginLeft: "5px", float: "right" }}
                      onClick={() => {
                        this.deletegroup();
                      }}
                    >
                      <i className="fa-solid fa-trash" /> Xóa nhóm
                    </Button>{" "}
                  </>
                ) : null}
              </div>
            </Card.Header>

            <Card.Body>
              <Row>
                <Col md={12} className="position-md-absolute mb-2 mb-md-0">
                  {
                    // this.state.checkload ? (
                    //   <LoadingSpinner />
                    // ) :

                    this.state.listgroup.length > 0 ? (
                      <>
                        {new Check().permission(["13"]) ? (
                          <Button
                            variant="success"
                            className="float-end ms-2 me-md-2"
                            size="sm"
                            hidden={
                              this.state.listgroup.length === 0 ||
                              Number(this.state.isMain) === 0
                                ? true
                                : false
                            }
                            onClick={() => {
                              this.setState({
                                _class: [],
                                show: true,
                                type: "TM",
                              });
                            }}
                          >
                            <i className="fa-solid fa-plus"></i> Thêm lớp
                          </Button>
                        ) : (
                          <></>
                        )}

                        <Form.Select
                          size="sm"
                          style={{
                            width: "160px",
                          }}
                          className="float-end"
                          value={this.state.id_year}
                          onChange={(idYear) => {
                            this.setState({
                              id_year: Number(idYear.target.value),
                            });
                            this.getlistclass(
                              this.state.idgroup,
                              Number(idYear.target.value)
                            );
                          }}
                          title="Năm học"
                        >
                          <option>--Tất cả--</option>
                          {this.state?.schoolYears.map((item) => {
                            return (
                              <option value={item.id}>{item.year_name}</option>
                            );
                          })}
                        </Form.Select>
                      </>
                    ) : (
                      <></>
                    )
                  }
                </Col>

                <Tabs
                  activeKey={this.state.tabkey}
                  defaultActiveKey={0}
                  onSelect={(k) => {
                    let group = this.state.listgroup[k];
                    this.setState({
                      tabkey: k,
                      groupname: group.group_name,
                      idgroup: group.id,
                      isMain: group.is_main,
                      recommended_from: group.recommended_from,
                      id_groupAge: group.id_age_group,
                      recommended_to: group.recommended_to,
                      enegry_ratio: group.enegry_ratio,
                    });

                    this.getlistclass(group.id, this.state.id_year);
                  }}
                  className="mb-2"
                >
                  {this.state.listgroup?.length > 0 ? (
                    this.state.listgroup.map((group, index) => {
                      if (group.is_adult == 0) {
                        return (
                          <Tab
                            key={index}
                            title={group.group_name}
                            eventKey={index}
                          >
                            {Number(this.state.isMain) === 0 ? (
                              ""
                            ) : this.state.listclass.length > 0 ? (
                              this.dataTable()
                            ) : (
                              <p className="text-center">Không có dữ liệu...</p>
                            )}
                          </Tab>
                        );
                      } else {
                        return null;
                      }
                    })
                  ) : (
                    <Tab key={0} title="Chưa có nhóm nào!" eventKey={0}>
                      <p className="text-center">Không có dữ liệu...</p>
                    </Tab>
                  )}
                </Tabs>
              </Row>
            </Card.Body>
          </Card>
        </Container>

        <ClassCreate
          _class={this.state._class}
          listuser={this.state.listuser}
          PT={this.state.type}
          show={this.state.show}
          close={this.formclose}
          listclass={this.state.listclass}
          user_now={this.state._class.id_user}
          id_group={this.state.idgroup}
        />

        {this.state.showModalUpdateClass ? (
          <ClassUpdate
            id={this.state.id_class}
            show={this.state.showModalUpdateClass}
            schoolYears={this.state.schoolYears}
            groups={this.state.groups}
            users={this.state.listuser}
            close={this.formclose}
          />
        ) : (
          <></>
        )}

        {this.state.showcreate ? this.renderAddGroupModal() : ""}

        <Modal
          size="xl"
          show={this.state.showDetail}
          onHide={() => this.setState({ showDetail: false })}
          keyboard={false}
        >
          <Modal.Header closeButton>
            <th> Chi tiết lớp {this.state.itemclass?.class_name}</th>
          </Modal.Header>

          <Modal.Body>
            <Row>
              <Col style={{ marginBottom: "15px" }} md={3}>
                <Form.Select
                  defaultValue={this.state.studentFilter}
                  onChange={(e) => {
                    const valueText = e.target.value;

                    this.setState({ studentFilter: valueText });

                    this.handleStudentSearch(
                      this.state.listclassID,
                      this.state.studentSearch,
                      valueText
                    );
                  }}
                >
                  <option value="now">Đang học</option>
                  <option value="all">Tất cả</option>
                </Form.Select>
              </Col>

              <Col style={{ marginBottom: "15px" }} md={6}>
                <Form.Control
                  type="text"
                  placeholder="Nhập tên học sinh..."
                  onChange={(e) => {
                    const valueText = e.target.value.trim();

                    this.setState({ studentSearch: valueText });

                    this.handleStudentSearch(
                      this.state.listclassID,
                      valueText,
                      this.state.studentFilter
                    );
                  }}
                />
              </Col>

              <Col md={3}>
                <Button
                  variant="success"
                  className="text-end"
                  size="sm"
                  style={{ marginLeft: "5px", float: "right" }}
                  onClick={() => this.xport(this.state.itemclass?.class_name)}
                >
                  <i className="fa-solid fa-file-export" /> Xuất DSHS
                </Button>
              </Col>
            </Row>

            {this.state.checkload === true ? (
              <LoadingSpinner />
            ) : this.state.listclasssearch?.length > 0 ? (
              <Table bordered hover>
                <thead>
                  <tr className="bg-light">
                    <th className="align-middle text-center">STT</th>
                    <th className="align-middle text-center">
                      Mã định danh Bộ GD&ĐT
                    </th>
                    <th className="align-middle text-center">Họ và tên</th>
                    <th className="align-middle text-center">Ngày sinh </th>
                    <th className="align-middle text-center">Giới tính</th>
                    <th className="align-middle text-center">Dân tộc</th>
                    <th className="align-middle text-center">Trạng thái</th>
                    <th className="align-middle text-center">SĐT liên hệ</th>
                  </tr>
                </thead>

                <tbody>
                  {this.state.listclasssearch.map((item, index) => {
                    const checkStatus = item.classHistories.some(
                      (classHistory) => {
                        const startAt = classHistory.start_at;
                        const endAt = classHistory.end_at || Infinity;
                        return (
                          today >= startAt &&
                          today <= endAt &&
                          this.state.id_class === classHistory.id_class
                        );
                      }
                    );

                    const checkFuture = item.classHistories.some(
                      (classHistory) => {
                        const startAt = classHistory.start_at;
                        return (
                          today < startAt &&
                          this.state.id_class === classHistory.id_class
                        );
                      }
                    );

                    return (
                      <tr key={item.id}>
                        <td className="text-center">{index + 1}</td>
                        <td>
                          {item.student_identifier == "null"
                            ? ""
                            : item.student_identifier}
                        </td>
                        <td>{item.student_name}</td>
                        <td className="text-end">
                          {this.getDate(item.student_birthday)}
                        </td>
                        <td>{item.gender_name}</td>
                        <td>
                          {
                            this.state.listEthnic.filter(
                              (e) => e.id == item.student_ethnic
                            )[0]?.ethnic_name
                          }
                        </td>
                        <td>
                          {item.student_status === "1" ? (
                            checkStatus ? (
                              <span className="text-success">Đang học</span>
                            ) : checkFuture ? (
                              <span className="text-primary">Sắp vào</span>
                            ) : (
                              <span className="text-warning">Chuyển lớp</span>
                            )
                          ) : (
                            <span className="text-danger">Tạm nghỉ</span>
                          )}
                        </td>
                        <td>
                          {item.student_contact == "null"
                            ? ""
                            : item.student_contact}
                        </td>
                      </tr>
                    );
                  })}

                  <tr>
                    <th colSpan={4} className="text-end">
                      Số lượng
                    </th>
                    <th colSpan={4}> {this.state.listclasssearch.length}</th>
                  </tr>
                </tbody>
              </Table>
            ) : (
              <p className="text-center"> Không có dữ liệu...</p>
            )}

            {this.state.listclasssearch.length > 0 ? (
              <Table bordered id="StudentTable" className="d-none">
                <thead>
                  <tr>
                    <th>STT</th>
                    <th>Mã định danh Bộ GD&ĐT</th>
                    <th>Họ và tên</th>
                    <th>Ngày sinh</th>
                    <th>Giới tính</th>
                    <th>Dân tộc</th>
                    <th>Trạng thái</th>
                    <th>SĐT liên hệ</th>
                  </tr>
                </thead>

                <tbody>
                  {this.state.listclasssearch.map((item, index) => {
                    return (
                      <tr key={item.id}>
                        <td className="text-center">{index + 1}</td>
                        <td>{this.changeEmpty(item.student_identifier)}</td>
                        <td>{this.changeEmpty(item.student_name)}</td>
                        <td>
                          {this.changeEmpty(
                            this.getDate(item.student_birthday)
                          )}
                        </td>
                        <td>{this.changeEmpty(item.gender_name)}</td>
                        <td>
                          {this.changeEmpty(
                            this.state.listEthnic.filter(
                              (e) => e.id == item.student_ethnic
                            )[0]?.ethnic_name
                          )}
                        </td>
                        <td>{this.changeEmpty()}</td>
                        <td>
                          {this.changeEmpty(
                            item.student_contact == "null"
                              ? ""
                              : item.student_contact
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            ) : (
              <></>
            )}
          </Modal.Body>

          <Modal.Footer>
            <Button
              size="sm"
              variant="secondary"
              onClick={() => this.setState({ showDetail: false })}
            >
              <i className="fa-solid fa-times"></i> Đóng
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );

    return data;
  }
}

export default GroupIndex;
