import React, { Component } from "react";
import axios from "axios";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";

class ChangeClassModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      choiceClass: "",
      choiceDate: 0,
      oldClassEndAt: "",
      classrooms: [],
      yearId: this.props.yearId,
      err: {},
    };
  }

  async componentDidMount() {
    await this.getClassrooms(this.state.yearId);
  }

  handleHide = () => {
    return this.props.onHide();
  };

  getClassrooms = async (yearId) => {
    await axios.get(`/classroom-shows?id_year=${yearId}`).then((res) => {
      this.setState({ classrooms: res.data.data?.classrooms });
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    let data = {
      id_student: this.props.idStudentChangeClass?.id,
      id_class: this.state.choiceClass,
      end_at: parseInt(
        new Date(this.state.oldClassEndAt).setHours(23, 59, 59) / 1000
      ),
      start_at: parseInt(
        new Date(this.state.choiceDate).setHours(0, 0, 0) / 1000
      ),
    };

    axios
      .post(`/student-change-class`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        toast(res.data.messages, { type: "success" });
        this.props.onSave();
      })
      .catch((error) => {
        this.setState({ err: error.response.data.messages });
      });
  };

  render() {
    const { onHide, ...other } = { ...this.props };
    return (
      <>
        <Modal
          show={other.show}
          onHide={onHide}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Chuyển lớp</Modal.Title>
          </Modal.Header>

          <Form onSubmit={this.handleSubmit}>
            <Modal.Body>
              <Row>
                <Col md={6}>
                  <Form.Floating className="mb-3">
                    <Form.Select
                      value={this.state.yearId}
                      onChange={(e) => {
                        const yearId = e.target.value;
                        this.setState({
                          yearId: yearId,
                          choiceClass: "",
                        });
                        this.getClassrooms(yearId);
                      }}
                      required
                    >
                      <option value="">--Chọn--</option>

                      {this.props.schoolYears?.map((itemYear, indexYear) => {
                        return (
                          <option key={`year_${indexYear}`} value={itemYear.id}>
                            {itemYear.year_name}
                          </option>
                        );
                      })}
                    </Form.Select>

                    <Form.Label>
                      Năm học<i className="text-danger">*</i>
                    </Form.Label>
                  </Form.Floating>
                </Col>

                <Col md={6}>
                  <Form.Floating className="mb-3">
                    <Form.Select
                      value={this.state.choiceClass}
                      onChange={(e) => {
                        this.setState({ choiceClass: e.target.value });
                        delete this.state.err["id_class"];
                      }}
                      required
                    >
                      <option value="">--Chọn--</option>
                      {this.state.classrooms?.map((item, index) => {
                        return (
                          <option key={`classroom_1_${index}`} value={item.id}>
                            {item.class_name}
                          </option>
                        );
                      })}
                    </Form.Select>

                    <Form.Label>
                      Lớp học
                      <i className="text-danger">*</i>
                    </Form.Label>

                    <span className="text-form-err">
                      {this.state.err?.id_class}
                    </span>
                  </Form.Floating>
                </Col>

                <Col md={6}>
                  <Form.Floating className="mb-3">
                    <input
                      type="date"
                      className="form-control"
                      required
                      onChange={(e) => {
                        this.setState({ oldClassEndAt: e.target.value });
                        delete this.state.err["end_at"];
                      }}
                    />
                    <Form.Label>
                      Ngày kết thúc lớp cũ
                      <i className="text-danger">*</i>
                    </Form.Label>
                    <span className="text-form-err">
                      {this.state.err?.end_at}
                    </span>
                  </Form.Floating>
                </Col>

                <Col md={6}>
                  <Form.Floating className="mb-3">
                    <input
                      type="date"
                      className="form-control"
                      required
                      onChange={(e) => {
                        this.setState({ choiceDate: e.target.value });
                        delete this.state.err["start_at"];
                      }}
                    />
                    <Form.Label>
                      Ngày vào lớp mới
                      <i className="text-danger">*</i>
                    </Form.Label>
                    <span className="text-form-err">
                      {this.state.err?.start_at}
                    </span>
                  </Form.Floating>
                </Col>
              </Row>
            </Modal.Body>

            <Modal.Footer>
              <Button
                size="sm"
                variant="secondary"
                id="btnCloseAddIngredent"
                onClick={onHide}
              >
                <i className="fa-solid fa-times"></i> Đóng
              </Button>

              <Button size="sm" variant="success" type="submit">
                <i className="fa-solid fa-check"></i> Lưu
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </>
    );
  }
}

export default ChangeClassModal;
