import { BrowserRouter, Routes, Route } from "react-router-dom";

import "sweetalert/dist/sweetalert.min";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import SchoolManagement from "../components/admin/school/SchoolManagement";

import MasterLayout from "../layouts/admin/MasterLayout";
import Home from "../components/frontend/Home";
import Login from "../components/frontend/auth/Login";
import CreateSchool from "../components/admin/school/CreateSchool";

import Dashboard from "../components/admin/Dashboard";
import Error404 from "../components/admin/errors/Error404";
import ErrorHome404 from "../components/admin/errors/ErrorHome404";

// Student
// import ClassIndex from "../components/admin/class/ClassIndex";
import StudentIndex from "../components/admin/student/StudentIndex";
import ListStudent from "../components/admin/student/ListStudent";
import GroupIndex from "../components/admin/group/GroupIndex";

// Food
import CategoryIndex from "../components/admin/category_food/CategoryIndex";
// import FoodIndex from "../components/admin/food/FoodIndex";
import BankFood from "../components/admin/food/BankFood";

// User
import UserIndex from "../components/admin/user/UserIndex";
import RoleIndex from "../components/admin/role/RoleIndex";

// Meal cut
import ListmealIndex from "../components/admin/meal/Listmeal/ListmealIndex";
import MealcutIndex from "../components/admin/meal/MealcutIndex";
import ListCut from "../components/admin/meal/ListCut";
import Report from "../components/admin/meal/ReportAttendance/Report";

// Store
import BuyIndex from "../components/admin/store/BuyIndex";
import IngredentIndex from "../components/admin/ingredent/IngredentIndex";
import BankIngredent from "../components/admin/ingredent/BankIngredent";
import Storeinventory from "../components/admin/store/Storeinventory";
import SummaryStoreMaterial from "../components/admin/store/SummaryStoreMaterial";

// Menu
import MenuIndex from "../components/admin/meal/Menu/MenuIndex";
import MarketList from "../components/admin/meal/Menu/MarketList";
import MarketKitchen from "../components/admin/meal/Menu/MarketKitchen";
import PortionCalculate from "../components/admin/food_portion/PortionCalculate";
import NutritionPortionReport from "../components/admin/food_portion/NutritionPortionReport";

// Supplier
import SupplierIndex2 from "../components/admin/Supplier/SupplierIndex2";
import StoreIndex from "../components/admin/store/StoreIndex";
import SupplierIndex from "../components/admin/Supplier/SupplierIndex";

// Form
import ThreeStepBook from "../components/admin/form/ThreeStepBook";
import SampleBook from "../components/admin/form/SampleBook";
import FormSetting from "../components/admin/form/FormSetting";

// Report
import AbsentReportIndex from "../components/admin/report/absentreport/AbsentReportIndex";
import ReportMealDetail from "../components/admin/report/absentreport/ReportMealDetail";
import SupplierShoppingReportIndex from "../components/admin/report/suppliershoppingreport/SupplierShoppingReportIndex";
import MarketListReportIndex from "../components/admin/report/marketlistreport/MarketListReportIndex";
import FinanceReport from "../components/admin/report/financereport/FinanceReport";
import FinanceBook from "../components/admin/report/financereport/FinanceBook";

import BankFile from "../components/admin/setting/BankFile";
import Check from "../components/admin/other/Check";

// Setting
import YearSchool from "../components/admin/yearschool/YearSchool";

import DetailSchool from "../components/admin/school/DetailSchool";
import UnitIndex from "../components/admin/unit/UnitIndex";
import Settingindex from "../components/admin/setting/Settingindex";
import ExportMaterial from "../components/admin/store/ExportMaterial";

// import CreateFood from "../components/testAPI/food/CreateFood";
import CreateSetting from "../components/testAPI/setting/CreateSetting";
import MenuWeek from "../components/testAPI/menu/MenuWeek";
import CalculateMealPorition from "../components/testAPI/menu/CalculateMealPorition";

// ********** Admin 2 ********** //
import MasterLayout2 from "../layouts/admin2/MasterLayout";
import Dashboard2 from "../components/admin2/Dashboard";

// Menu
import MenuIndex2 from "../components/admin2/menu/menu/MenuIndex";
import PortionCalculate2 from "../components/admin2/menu/portionCalculate/PortionCalculate";
import NutritionEnergy2 from "../components/admin2/menu/nutritionEnergy/NutritionEnergy";
import MarketList2 from "../components/admin2/menu/marketList/MarketList";
import QuantitativeKitchen2 from "../components/admin2/menu/quantitativeKitchen/QuantitativeKitchen";

// Store
import IngredientIndex2 from "../components/admin2/ingredient/IngredientIndex";
import BankIngredient2 from "../components/admin2/ingredient/BankIngredent";

// Food
import FoodIndex2 from "../components/admin2/food/FoodIndex";
import BankFood2 from "../components/admin2/food/BankFood";

// Report
import AbsentReportIndex2 from "../components/admin/report/absentreport/AbsentReportIndex";
import ReportMealDetail2 from "../components/admin/report/absentreport/ReportMealDetail";
import ListCut2 from "../components/admin2/meal/ListCut";

import IngredientPriceReport2 from "../components/admin2/report/ingredientPrice/IngredientPriceReport";
import FinanceBook2 from "../components/admin2/report/financereport/FinanceBook";
import FinanceBookV2 from "../components/admin2/report/financereport2/FinanceBook";
import FinanceReport2 from "../components/admin2/report/financereport/FinanceReport";
import SupplierShoppingReportIndex2 from "../components/admin2/report/suppliershoppingreport/SupplierShoppingReportIndex";

// Form
import ThreeStepBook2 from "../components/admin2/form/ThreeStepBook";
import SampleBook2 from "../components/admin2/form/SampleBook";
import FormSetting2 from "../components/admin2/form/FormSetting";

//Setting
import UnitIndex2 from "../components/admin2/unit/UnitIndex";
import YearSchool2 from "../components/admin2/yearschool/YearSchool";
import SettingIndex2 from "../components/admin2/setting/MainSetting";
import CreateSetting2 from "../components/admin2/setting/CreateSetting";
import DetailSchool2 from "../components/admin2/school/DetailSchool";

// Meal cut
import ListmealIndex2 from "../components/admin2/meal/Listmeal/ListmealIndex";
import MealcutIndex2 from "../components/admin2/meal/MealcutIndex";
import Report2 from "../components/admin2/meal/ReportAttendance/Report";
// Supplier
import Supplier2 from "../components/admin2/Supplier/SupplierIndex";
import SupplierHistory2 from "../components/admin2/Supplier/SupplierIndexHistory";
// Student
import StudentIndex2 from "../components/admin2/student/StudentIndex";
import StudentIndexV2 from "../components/admin2/studentV2/StudentIndex";
import GroupIndex2 from "../components/admin2/group/GroupIndex";
import GroupIndexV2 from "../components/admin2/groupV2/GroupIndex";
// Meal Cut V2
import ListmealIndexV2 from "../components/admin2/mealV2/Listmeal/ListmealIndex";
import MealcutIndexV2 from "../components/admin2/mealV2/MealcutIndex";
import ReportV2 from "../components/admin2/mealV2/ReportAttendance/Report";
import ListCutV2 from "../components/admin2/mealV2/ListCut";
import ReportMealDetailV2 from "../components/admin2/mealV2/ReportMealDetail";
// User
import UserIndex2 from "../components/admin2/user/UserIndex";
import RoleIndex2 from "../components/admin2/role/RoleIndex";

// Store
import BuyIndex2 from "../components/admin2/store/BuyIndex";
import Storeinventory2 from "../components/admin2/store/Storeinventory";
import SummaryStoreMaterial2 from "../components/admin2/store/SummaryStoreMaterial";
import ExportMaterial2 from "../components/admin2/store/ExportMaterial";
import StoreIndex2 from "../components/admin2/store/StoreIndex";
import Receipt from "../components/admin2/store/Receipt";

// Subjects
import SubjectsIndex from "../components/admin2/subjects/SubjectsIndex";
import SudyGroupIndex from "../components/admin2/subjects/StudyGroup/StudyGroupIndex";

// Parent Accounts
import ParentAccountsIndex from "../components/admin2/parentAccount/ParentAccountsIndex";

// Schedule
import ScheduleIndex from "../components/admin2/schedule/ScheduleIndex";
import TuitionIndex from "../components/admin2/tuition/TuitionIndex";
import BillIndex from "../components/admin2/bill/BillIndex";
import EvaluationIndex from "../components/admin2/evaluation/EvaluationIndex";
import ResultEvaluation from "../components/admin2/evaluation/ResultEvaluation";
import MedicationIndex from "../components/admin2/medication/MedicationIndex";
import PostIndex from "../components/admin2/post/PostIndex";
import PaymentIndex from "../components/admin2/payment/PaymentIndex";
import NotificationIndex from "../components/admin2/notification/NotificationIndex";

// ********** Admin 2 ********** //

const admin2Routes = [
  {
    id: 1,
    component: <Dashboard2 />,
    path: "dashboard",
    permission: true,
  },

  // Meal cut
  {
    id: 2,
    component: <ListmealIndex2 />,
    path: "meal",
    permission: new Check().permission(["72"]),
  },
  {
    id: 3,
    component: <MealcutIndex2 />,
    path: "meal-cut",
    permission: new Check().permission(["72"]),
  },
  {
    id: 4,
    component: <Report2 />,
    path: "reportAtt",
    permission: new Check().permission(["72"]),
  },
  {
    id: 2,
    component: <ListmealIndexV2 />,
    path: "meal2",
    permission: new Check().permission(["72"]),
  },
  {
    id: 3,
    component: <MealcutIndexV2 />,
    path: "meal-cut2",
    permission: new Check().permission(["72"]),
  },
  {
    id: 4,
    component: <ReportV2 />,
    path: "reportAtt2",
    permission: new Check().permission(["72"]),
  },

  {
    id: 5,
    component: <StudentIndex2 />,
    path: "student",
    permission: new Check().permission(["17"]),
  },
  {
    id: 5,
    component: <StudentIndexV2 />,
    path: "student2",
    permission: new Check().permission(["17"]),
  },
  {
    id: 6,
    component: <GroupIndex2 />,
    path: "student-group",
    permission: new Check().permission(["7"]),
  },
  {
    id: 6,
    component: <GroupIndexV2 />,
    path: "student-group2",
    permission: new Check().permission(["7"]),
  },
  {
    id: 8,
    component: <MenuIndex2 />,
    path: "menu",
    permission: new Check().permission(["52"]),
  },
  {
    id: 9,
    component: <PortionCalculate2 />,
    path: "portion-calculate",
    permission: new Check().permission(["120"]),
  },
  {
    id: 32,
    component: <NutritionEnergy2 />,
    path: "nutrition-report",
    permission: new Check().permission(["56"]),
  },
  {
    id: 33,
    component: <MarketList2 />,
    path: "market",
    permission: new Check().permission(["116"]),
  },
  {
    id: 34,
    component: <QuantitativeKitchen2 />,
    path: "marketkitchen",
    permission: new Check().permission(["57"]),
  },

  // Store
  {
    id: 10,
    component: <IngredientIndex2 />,
    path: "ingredient",
    permission: new Check().permission(["40"]),
  },
  {
    id: 11,
    component: <BankIngredient2 />,
    path: "bank-ingredient",
    permission: new Check().permission(["44"]),
  },

  // Food
  {
    id: 12,
    component: <FoodIndex2 />,
    path: "food",
    permission: new Check().permission(["35"]),
  },
  {
    id: 13,
    component: <BankFood2 />,
    path: "bank-food",
    permission: new Check().permission(["35"]),
  },

  // Setting
  {
    id: 14,
    component: <UnitIndex2 />,
    path: "unit",
    permission: new Check().permission(["67"]),
  },
  {
    id: 15,
    component: <SettingIndex2 />,
    path: "mainsetting",
    permission: new Check().permission(["59"]),
  },
  {
    id: 16,
    component: <CreateSetting2 />,
    path: "mainsetting/create-setting",
    permission: new Check().permission(["59"]),
  },
  {
    id: 17,
    component: <YearSchool2 />,
    path: "year-school",
    permission: new Check().permission(["100"]),
  },
  {
    id: 18,
    component: <Supplier2 />,
    path: "supplier",
    permission: new Check().permission(["23"]),
  },
  {
    id: 19,
    component: <SupplierHistory2 />,
    path: "hissupplier",
    permission: new Check().permission(["23"]),
  },
  {
    id: 20,
    component: <DetailSchool2 />,
    path: "school",
    permission: new Check().permission([159]),
  },

  // User
  {
    id: 21,
    component: <UserIndex2 />,
    path: "user",
    permission: new Check().permission(["2"]),
  },
  {
    id: 22,
    component: <RoleIndex2 />,
    path: "role",
    permission: new Check().permission(["2"]),
  },

  // Form
  {
    id: 23,
    component: <ThreeStepBook2 />,
    path: "3-step-book",
    permission: new Check().permission(["77"]),
  },
  {
    id: 24,
    component: <SampleBook2 />,
    path: "samplebook",
    permission: new Check().permission(["78"]),
  },
  {
    id: 24,
    component: <FormSetting2 />,
    path: "form-setting",
    permission: new Check().permission(["79"]),
  },

  // Report
  {
    id: 25,
    component: <AbsentReportIndex2 />,
    path: "absent-report",
    permission: new Check().permission(["83"]),
  },
  {
    id: 26,
    component: <IngredientPriceReport2 />,
    path: "market-report",
    permission: new Check().permission([116, 84], "or"),
  },
  {
    id: 27,
    component: <FinanceBook2 />,
    path: "finance-book",
    permission: new Check().permission(["89"]),
  },
  {
    id: 271,
    component: <FinanceBookV2 />,
    path: "finance-book2",
    permission: new Check().permission(["89"]),
  },
  {
    id: 28,
    component: <ReportMealDetail2 />,
    path: "report-detail",
    permission: new Check().permission(["92"]),
  },
  {
    id: 28,
    component: <ReportMealDetailV2 />,
    path: "report-detail2",
    permission: new Check().permission(["92"]),
  },
  {
    id: 29,
    component: <FinanceReport2 />,
    path: "finance-report",
    permission: new Check().permission(["90"]),
  },
  {
    id: 30,
    component: <SupplierShoppingReportIndex2 />,
    path: "supplier-report",
    permission: new Check().permission(["91"]),
  },
  {
    id: 31,
    component: <ListCut2 />,
    path: "listcut",
    permission: new Check().permission(["82"]),
  },
  {
    id: 31,
    component: <ListCutV2 />,
    path: "listcut2",
    permission: new Check().permission(["82"]),
  },

  // Store
  {
    id: 34,
    component: <BuyIndex2 />,
    path: "buy",
    permission: new Check().permission(["47"]),
  },
  {
    id: 35,
    component: <Storeinventory2 />,
    path: "storeinventory",
    permission: new Check().permission(["50"]),
  },
  {
    id: 36,
    component: <SummaryStoreMaterial2 />,
    path: "storematerial",
    permission: new Check().permission(["50"]),
  },
  {
    id: 37,
    component: <ExportMaterial2 />,
    path: "material",
    permission: new Check().permission(["50"]),
  },
  {
    id: 38,
    component: <StoreIndex2 />,
    path: "store",
    permission: new Check().permission(["46"]),
  },
  {
    id: 39,
    component: <Receipt />,
    path: "receipt",
    permission: new Check().permission(["46"]),
  },

  // Subject
  {
    id: 40,
    component: <SubjectsIndex />,
    path: "subject",
    permission: new Check().permission([139]),
  },
  {
    id: 41,
    component: <SudyGroupIndex />,
    path: "studyGroup",
    permission: new Check().permission([144]),
  },

  // Parent Accounts
  {
    id: 42,
    component: <ParentAccountsIndex />,
    path: "parentAccount",
    permission: new Check().permission(["17"]),
  },
  {
    id: 43,
    component: <ScheduleIndex />,
    path: "schedule/:classId",
    permission: new Check().permission(["7"]),
  },
  {
    id: 44,
    component: <TuitionIndex />,
    path: "tuition",
    permission: new Check().permission([134]),
  },
  {
    id: 44,
    component: <BillIndex />,
    path: "bill",
    permission: new Check().permission(["129"]),
  },
  {
    id: 45,
    component: <EvaluationIndex />,
    path: "evaluation",
    permission: new Check().permission(["111"]),
  },
  {
    id: 46,
    component: <ResultEvaluation />,
    path: "evaluation/:idevaluation",
    permission: new Check().permission(["111"]),
  },
  {
    id: 47,
    component: <MedicationIndex />,
    path: "medication",
    permission: new Check().permission([86]),
  },
  {
    id: 48,
    component: <PaymentIndex />,
    path: "payment-setting",
    permission: new Check().permission([160]),
  },
  {
    id: 49,
    component: <PostIndex />,
    path: "post",
    permission: new Check().permission([149, 154], "or"),
  },
  {
    id: 50,
    component: <NotificationIndex />,
    path: "notification",
    permission: new Check().permission([161]),
  },
];

export default function router() {
  return (
    <>
      <ToastContainer autoClose={1000} />

      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/katec/create-school" element={<CreateSchool />} />

          <Route path="admin" element={<MasterLayout />}>
            {new Check().permission(["52", "72"], "or") ? (
              <>
                <Route
                  path="school-management"
                  element={<SchoolManagement />}
                />
              </>
            ) : null}

            {new Check().permission(["52", "72"], "or") ? (
              <>
                <Route index element={<Dashboard />} />
                <Route path="dashboard" element={<Dashboard />} />
              </>
            ) : null}
            {/* Meal */}
            {new Check().permission(["52", "72"], "or") ? (
              <>
                {new Check().permission(["72"], "or") ? (
                  <>
                    <Route path="meal" element={<ListmealIndex />} />
                    <Route path="meal-cut" element={<MealcutIndex />} />
                    <Route path="reportAtt" element={<Report />} />
                    <Route
                      path="report-detail"
                      element={<ReportMealDetail />}
                    />
                  </>
                ) : (
                  ""
                )}

                {new Check().permission(["52"], "or") ? (
                  <>
                    <Route path="menu" element={<MenuIndex />} />
                    <Route path="market" element={<MarketList />} />
                    <Route path="marketkitchen" element={<MarketKitchen />} />
                  </>
                ) : (
                  ""
                )}
              </>
            ) : (
              ""
            )}

            {/* Portion  */}
            {new Check().permission(["57"]) ? (
              <>
                <Route
                  path="portion-calculate"
                  element={<PortionCalculate />}
                />
                <Route
                  path="nutrition-report"
                  element={<NutritionPortionReport />}
                />
              </>
            ) : (
              ""
            )}

            {/* Staff */}
            {new Check().permission(["2", "62"], "or") &&
            localStorage.getItem("role_rank") < 3 ? (
              <>
                {new Check().permission(["2"], "or") ? (
                  <Route path="user" element={<UserIndex />} />
                ) : (
                  ""
                )}

                {new Check().permission(["62"], "or") ? (
                  <Route path="role" element={<RoleIndex />} />
                ) : (
                  ""
                )}
              </>
            ) : (
              ""
            )}

            {/* Student */}
            {new Check().permission(["7", "12", "17"], "or") ? (
              <>
                {new Check().permission(["17"], "or") ? (
                  <>
                    <Route path="student" element={<StudentIndex />} />
                    <Route path="student-list" element={<ListStudent />} />
                  </>
                ) : (
                  ""
                )}

                {/* {
                    new Check().permission(['12'], 'or') 
                    ? <Route path="class" element={<ClassIndex />} />
                    : ""
                  } */}

                {new Check().permission(["7"], "or") ? (
                  <Route path="student-group" element={<GroupIndex />} />
                ) : (
                  ""
                )}
              </>
            ) : (
              ""
            )}

            {/* Store */}
            {new Check().permission(["40", "44", "46", "47", "50"], "or") ? (
              <>
                <Route path="material" element={<ExportMaterial />} />

                {new Check().permission(["47"]) ? (
                  <Route path="buy" element={<BuyIndex />} />
                ) : (
                  ""
                )}

                {new Check().permission(["46"]) ? (
                  <Route path="store" element={<StoreIndex />} />
                ) : (
                  ""
                )}

                {new Check().permission(["40"]) ? (
                  <Route path="ingredent" element={<IngredentIndex />} />
                ) : (
                  ""
                )}

                {new Check().permission(["44"]) ? (
                  <Route path="bank-ingredent" element={<BankIngredent />} />
                ) : (
                  ""
                )}

                {new Check().permission(["50"]) ? (
                  <Route path="storeinventory" element={<Storeinventory />} />
                ) : (
                  ""
                )}

                <Route
                  path="storematerial"
                  element={<SummaryStoreMaterial />}
                />
              </>
            ) : (
              ""
            )}

            {/* Supplier */}
            {new Check().permission(["23"], "or") ? (
              <>
                {new Check().permission(["23"], "or") ? (
                  <Route path="supplier" element={<SupplierIndex />} />
                ) : (
                  ""
                )}

                {new Check().permission(["23"], "or") ? (
                  <Route path="hissupplier" element={<SupplierIndex2 />} />
                ) : (
                  ""
                )}
              </>
            ) : (
              ""
            )}

            {/* Food */}
            {new Check().permission(["28", "33", "35"], "or") ? (
              <>
                {/* {
                    new Check().permission(['28'], 'or') 
                    ? <Route path="food" element={<FoodIndex />} />
                    : ""
                  } */}

                {new Check().permission(["35"], "or") ? (
                  <Route path="category-food" element={<CategoryIndex />} />
                ) : (
                  ""
                )}
                {new Check().permission(["35"], "or") ? (
                  <Route path="bank-food" element={<BankFood />} />
                ) : (
                  ""
                )}
              </>
            ) : (
              ""
            )}

            {/* Report */}
            {new Check().permission(
              ["82", "83", "84", "89", "90", "91"],
              "or"
            ) ? (
              <>
                {new Check().permission(["82"], "or") ? (
                  <Route path="listcut" element={<ListCut />} />
                ) : (
                  ""
                )}

                {new Check().permission(["83"], "or") ? (
                  <Route path="absent-report" element={<AbsentReportIndex />} />
                ) : (
                  ""
                )}

                {new Check().permission(["90"], "or") ? (
                  <Route path="finance-report" element={<FinanceReport />} />
                ) : (
                  ""
                )}

                {new Check().permission(["89"], "or") ? (
                  <Route path="finance-book" element={<FinanceBook />} />
                ) : (
                  ""
                )}

                {new Check().permission(["91"], "or") ? (
                  <Route
                    path="supplier-report"
                    element={<SupplierShoppingReportIndex />}
                  />
                ) : (
                  ""
                )}

                {new Check().permission(["84"], "or") ? (
                  <>
                    <Route
                      path="market-report"
                      element={<MarketListReportIndex />}
                    />
                  </>
                ) : (
                  ""
                )}
              </>
            ) : (
              ""
            )}

            {/* Form */}
            {new Check().permission(["77", "78", "79"], "or") ? (
              <>
                {new Check().permission(["77"], "or") ? (
                  <Route path="3-step-book" element={<ThreeStepBook />} />
                ) : (
                  ""
                )}

                {new Check().permission(["78"], "or") ? (
                  <Route path="samplebook" element={<SampleBook />} />
                ) : (
                  ""
                )}

                {new Check().permission(["79"], "or") ? (
                  <Route path="form-setting" element={<FormSetting />} />
                ) : (
                  ""
                )}
              </>
            ) : (
              ""
            )}

            {/* Setting */}

            {new Check().permission(["59", "67", "86"], "or") ? (
              <>
                <Route path="year-school" element={<YearSchool />} />
                {new Check().permission(["86"]) &&
                localStorage.getItem("role_rank") < 3 ? (
                  <Route path="school" element={<DetailSchool />} />
                ) : (
                  ""
                )}

                {new Check().permission(["67"], "or") ? (
                  <Route path="unit" element={<UnitIndex />} />
                ) : (
                  ""
                )}

                {new Check().permission(["59"]) &&
                localStorage.getItem("role_rank") < 3 ? (
                  <Route path="mainsetting" element={<Settingindex />} />
                ) : (
                  ""
                )}

                {Number(localStorage.getItem("vip")) === 1 ? (
                  <Route path="bank-file" element={<BankFile />} />
                ) : (
                  ""
                )}
              </>
            ) : (
              ""
            )}

            {/* Subject */}
            {/* {new Check().permission(["7", "12", "17"], "or") ? (
              <>
                {new Check().permission(["17"], "or") ? (
                  <>
                    <Route path="subject" element={<SubjectsIndex />} />
                  </>
                ) : (
                  ""
                )}

                {new Check().permission(["17"], "or") ? (
                  <>
                    <Route path="studyGroup" element={<SudyGroupIndex />} />
                  </>
                ) : (
                  ""
                )}
              </>
            ) : (
              ""
            )} */}

            {/* Parent Account */}
            {/* {new Check().permission(["7", "12", "17"], "or") ? (
              <>
                {new Check().permission(["17"], "or") ? (
                  <>
                    <Route path="subject" element={<ParentAccountsIndex />} />
                  </>
                ) : (
                  ""
                )}
              </>
            ) : (
              ""
            )} */}
            {/* <Route path="food/create" element={<CreateFood />} /> */}
            <Route path="setting/create" element={<CreateSetting />} />
            <Route path="menu/week" element={<MenuWeek />} />
            <Route
              path="menu/calculate-meal-portion"
              element={<CalculateMealPorition />}
            />

            <Route path="*" element={<Error404 />} />
          </Route>

          <Route path="admin-v2" element={<MasterLayout2 />}>
            <Route index element={<Dashboard2 />} />
            {admin2Routes.map((route) => {
              if (!route.permission) {
                return "";
              }

              return (
                <Route
                  key={route.id}
                  path={route.path}
                  element={route.component}
                />
              );
            })}
          </Route>
          <Route path="*" element={<ErrorHome404 />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}
