import React from "react";
import { Table } from "react-bootstrap";
import CurrencyFormat from "react-currency-format";
import { groupArrayByField } from "../../../utils/helpers";
import { dateFormatter } from "../../../utils/helpers";

const MarketBill = ({ quantitative, selectedClassGroup }) => {
  if (!quantitative) {
    return "";
  }

  // Get init data
  const currentCostPerDays = quantitative.costPerDays?.find(
    (costPerDay) => costPerDay.id_group === selectedClassGroup.id
  );

  const numberChildren = currentCostPerDays?.meal_quantity || 0;
  const feeMeal = Number(currentCostPerDays?.fee_meal || 0);
  const feeOther = Number(currentCostPerDays?.fee_other || 0);
  const feePerChild = feeMeal + feeOther;
  const startBalance = Number(currentCostPerDays?.start_balance || 0);

  // Change data structure of quantitative to ingredient array
  let ingredientArray = [];
  let extraIngredientArray = [];

  quantitative.times?.map((timeItem) => {
    timeItem.foods?.map((foodItem) => {
      foodItem.groups
        .find(
          (groupItem) =>
            groupItem.id === selectedClassGroup.id &&
            groupItem.more_meal === null
        )
        ?.ingredents?.map((ingredientItem) => {
          ingredientArray.push(ingredientItem);
          return ingredientItem;
        });

      foodItem.groups.map((groupItem) => {
        if (
          groupItem.id === selectedClassGroup.id &&
          groupItem.more_meal !== null
        ) {
          groupItem?.ingredents.map((ingredientItem) => {
            extraIngredientArray.push({
              ...ingredientItem,
              mealQuantity: groupItem.more_meal,
            });
            return ingredientItem;
          });
        }

        return groupItem;
      });

      return foodItem;
    });

    return timeItem;
  });

  const groupIngredientArray = groupArrayByField(ingredientArray, "id").sort(
    (a, b) => {
      let amountA = a.reduce((accumulator, currentValue) => {
        return Number(
          accumulator +
            (currentValue.ing_quantity * currentValue.volume_market) /
              currentValue.volume_cook
        );
      }, 0);

      let amountB = b.reduce((accumulator, currentValue) => {
        return Number(
          accumulator +
            (currentValue.ing_quantity * currentValue.volume_market) /
              currentValue.volume_cook
        );
      }, 0);

      return a[0].ingredent_type - b[0].ingredent_type || amountB - amountA;
    }
  );
  const groupExtraIngredientArray = groupArrayByField(
    extraIngredientArray,
    "id"
  ).sort((a, b) => {
    let amountA = a.reduce((accumulator, currentValue) => {
      return Number(
        accumulator +
          (currentValue.ing_quantity * currentValue.volume_market) /
            currentValue.volume_cook
      );
    }, 0);

    let amountB = b.reduce((accumulator, currentValue) => {
      return Number(
        accumulator +
          (currentValue.ing_quantity * currentValue.volume_market) /
            currentValue.volume_cook
      );
    }, 0);

    return a[0].ingredent_type - b[0].ingredent_type || amountB - amountA;
  });

  let totalMoney = 0;
  let totalExtraMoney = 0;

  let totalMarketMoney = 0;
  let totalStoreMoney = 0;

  groupIngredientArray.map((groupIngredientItem) => {
    let ingredientPrice = 0;
    let gramMarket = 0;
    let volumeMarket = 0;
    let marketQuantity = 0;

    groupIngredientItem.map((ingredientItem) => {
      gramMarket += Number(
        (ingredientItem.ing_quantity *
          numberChildren *
          ingredientItem.volume_market) /
          ingredientItem.volume_cook
      );
      volumeMarket = Number(ingredientItem.volume_market);
      return ingredientItem;
    });

    gramMarket = Number(gramMarket).toFixed(2);
    marketQuantity = Number(gramMarket / volumeMarket).toFixed(4);
    ingredientPrice = Math.round(
      marketQuantity * groupIngredientItem[0].ing_price_expect
    );

    totalMoney += ingredientPrice;

    return groupIngredientItem;
  });

  groupExtraIngredientArray.map((groupIngredientItem) => {
    let ingredientPrice = 0;

    groupIngredientItem.map((ingredientItem) => {
      const ingredientQuantity = ingredientItem.ing_quantity;

      ingredientPrice += Number(
        (ingredientQuantity *
          ingredientItem.mealQuantity *
          ingredientItem.ing_price_expect) /
          ingredientItem.volume_cook
      );
      return ingredientItem;
    });

    ingredientPrice = Math.round(ingredientPrice);
    totalExtraMoney += ingredientPrice;

    return groupIngredientItem;
  });

  // Calculate money
  let totalRevenue = Number(feePerChild * numberChildren);
  let totalFeeOhter = Number(feeOther * numberChildren);

  let costMoney = Number(
    feeOther * numberChildren + totalMoney + totalExtraMoney
  );

  let costMoneyPerChild =
    numberChildren !== 0
      ? Math.round(Number((costMoney - totalExtraMoney) / numberChildren))
      : 0;

  let endBalance = Number(startBalance + totalRevenue - costMoney);

  const totalDifference = totalRevenue - costMoney;

  let ingredientIndex = 0;

  return (
    <Table bordered responsive id={`market-bill-table`}>
      <thead>
        <tr>
          <td colSpan={3}></td>
          <td colSpan={2} className="text-end align-top">
            {`${quantitative.day_name}, ngày ${dateFormatter(
              new Date(quantitative.menu_date * 1000)
            )}`}
          </td>
        </tr>
        <tr>
          <th colSpan={5} className="text-center align-middle">
            HÓA ĐƠN ĐI CHỢ
          </th>
        </tr>
        <tr className="align-top">
          <td colSpan={3}>
            Độ tuổi: {currentCostPerDays?.group_name}
            <br />
            Tổng số trẻ: {numberChildren}
          </td>
          <td>
            {quantitative.times?.map((timeItem, timeIndex) => {
              const foods =
                timeItem.foods.filter((food) => {
                  return food.groups.some(
                    (group) => group.id === selectedClassGroup.id
                  );
                }) || [];

              return (
                <div key={timeItem.id}>
                  Ăn {timeItem.time_name}:
                  <br />
                  {foods.length > 0 ? (
                    foods.map((foodItem, foodIndex) => {
                      return (
                        <div key={`food_${timeIndex}_ ${foodIndex}`}>
                          - {foodItem.food_name}
                          <br />
                        </div>
                      );
                    })
                  ) : (
                    <></>
                  )}
                </div>
              );
            })}
          </td>
          <td></td>
        </tr>
        <tr>
          <td colSpan={5}>
            Số tiền trên mỗi trẻ: &nbsp;
            <CurrencyFormat
              suffix=" đ"
              value={costMoneyPerChild}
              displayType={"text"}
              thousandSeparator={true}
            />
          </td>
        </tr>
        <tr className="text-center align-middle">
          <th>STT</th>
          <th>Tên thực phẩm</th>
          <th>Khối lượng (g)</th>
          <th>Đơn giá (đ/100g)</th>
          <th>Thành tiền (đ)</th>
        </tr>
      </thead>
      <tbody>
        {/* Market */}
        <tr>
          <th>empty</th>
          <th>*CHỢ</th>
          <th>empty</th>
          <th>empty</th>
          <th>empty</th>
        </tr>

        {groupIngredientArray
          .filter(
            (groupIngredientItem) => groupIngredientItem[0].ingredent_type === 1
          )
          .map((groupIngredientItem) => {
            let gramMarket = 0;
            let gram100Price = 0;
            let ingredientPrice = 0;
            let volumeMarket = 0;
            let marketQuantity = 0;
            groupIngredientItem.map((ingredientItem) => {
              gramMarket += Number(
                (ingredientItem.ing_quantity *
                  numberChildren *
                  ingredientItem.volume_market) /
                  ingredientItem.volume_cook
              );
              volumeMarket = Number(ingredientItem.volume_market);

              return ingredientItem;
            });

            gramMarket = Number(gramMarket).toFixed(2);
            marketQuantity = Number(gramMarket / volumeMarket).toFixed(4);
            ingredientPrice = Math.round(
              marketQuantity * groupIngredientItem[0].ing_price_expect
            );
            gram100Price =
              Math.round(Number((ingredientPrice * 100) / gramMarket)) || 0;

            totalMarketMoney += ingredientPrice;

            ingredientIndex++;

            return (
              <tr
                key={groupIngredientItem[0].id}
                className="align-middle text-end"
              >
                <td className="text-center">{ingredientIndex}</td>
                <td className="text-start">
                  {groupIngredientItem[0].ingredent_name}
                </td>
                <td>
                  <CurrencyFormat
                    value={parseFloat(gramMarket)}
                    displayType={"text"}
                    thousandSeparator={true}
                    decimalScale={2}
                  />
                </td>
                <td>
                  <CurrencyFormat
                    value={parseFloat(gram100Price)}
                    displayType={"text"}
                    thousandSeparator={true}
                    decimalScale={2}
                  />
                </td>
                <td>
                  <CurrencyFormat
                    value={parseFloat(ingredientPrice)}
                    displayType={"text"}
                    thousandSeparator={true}
                    decimalScale={2}
                  />
                </td>
              </tr>
            );
          })}

        <tr>
          <th className="text-center">Cộng</th>
          <th>empty</th>
          <th>empty</th>
          <th>empty</th>
          <th className="text-end">
            <CurrencyFormat
              value={totalMarketMoney}
              displayType={"text"}
              thousandSeparator={true}
            />
          </th>
        </tr>

        {/* Store */}
        <tr>
          <th>empty</th>
          <th>*XUẤT KHO</th>
          <th>empty</th>
          <th>empty</th>
          <th>empty</th>
        </tr>

        {groupIngredientArray
          .filter(
            (groupIngredientItem) => groupIngredientItem[0].ingredent_type === 2
          )
          .map((groupIngredientItem) => {
            let gramMarket = 0;
            let gram100Price = 0;
            let ingredientPrice = 0;
            let volumeMarket = 0;
            let marketQuantity = 0;

            groupIngredientItem.map((ingredientItem) => {
              gramMarket += Number(
                (ingredientItem.ing_quantity *
                  numberChildren *
                  ingredientItem.volume_market) /
                  ingredientItem.volume_cook
              );
              volumeMarket = Number(ingredientItem.volume_market);

              return ingredientItem;
            });

            gramMarket = Number(gramMarket).toFixed(2);
            marketQuantity = Number(gramMarket / volumeMarket).toFixed(4);
            ingredientPrice = Math.round(
              marketQuantity * groupIngredientItem[0].ing_price_expect
            );
            gram100Price =
              Math.round(Number((ingredientPrice * 100) / gramMarket)) || 0;
            totalStoreMoney += ingredientPrice;

            ingredientIndex++;

            return (
              <tr
                key={groupIngredientItem[0].id}
                className="align-middle text-end"
              >
                <td className="text-center">{ingredientIndex}</td>
                <td className="text-start">
                  {groupIngredientItem[0].ingredent_name}
                </td>
                <td>
                  {" "}
                  <CurrencyFormat
                    value={parseFloat(gramMarket)}
                    displayType={"text"}
                    thousandSeparator={true}
                    decimalScale={2}
                  />{" "}
                </td>
                <td>
                  {" "}
                  <CurrencyFormat
                    value={parseFloat(gram100Price)}
                    displayType={"text"}
                    thousandSeparator={true}
                    decimalScale={2}
                  />{" "}
                </td>
                <td>
                  {" "}
                  <CurrencyFormat
                    value={parseFloat(ingredientPrice)}
                    displayType={"text"}
                    thousandSeparator={true}
                    decimalScale={2}
                  />{" "}
                </td>
              </tr>
            );
          })}

        <tr>
          <th className="text-center">Cộng</th>
          <th>empty</th>
          <th>empty</th>
          <th>empty</th>
          <th className="text-end">
            <CurrencyFormat
              value={totalStoreMoney}
              displayType={"text"}
              thousandSeparator={true}
            />
          </th>
        </tr>

        {/* Summary */}
        <tr>
          <th>Tổng tiền thu</th>
          <th>empty</th>
          <th>empty</th>

          <th className="text-end">
            <CurrencyFormat
              value={totalRevenue}
              displayType={"text"}
              thousandSeparator={true}
              suffix=" đ"
            />
          </th>
          <th>empty</th>
        </tr>

        <tr>
          <th>Tổng chi phí khác</th>
          <th>empty</th>
          <th>empty</th>

          <th className="text-end">
            <CurrencyFormat
              value={totalFeeOhter}
              displayType={"text"}
              thousandSeparator={true}
              suffix=" đ"
            />
          </th>
          <th>empty</th>
        </tr>

        <tr>
          <th>Tổng tiền thực phẩm</th>
          <th>empty</th>
          <th>empty</th>

          <th className="text-end">
            <CurrencyFormat
              value={totalMoney}
              displayType={"text"}
              thousandSeparator={true}
              suffix=" đ"
            />
          </th>
          <th>empty</th>
        </tr>

        <tr>
          <th>Tổng tiền món dự trù</th>
          <th>empty</th>
          <th>empty</th>

          <th className="text-end">
            <CurrencyFormat
              value={totalExtraMoney}
              displayType={"text"}
              thousandSeparator={true}
              suffix=" đ"
            />
          </th>
          <th>empty</th>
        </tr>

        <tr>
          <th>Tổng chi trong ngày</th>
          <th>empty</th>
          <th>empty</th>

          <th className="text-end">
            <CurrencyFormat
              value={costMoney}
              displayType={"text"}
              thousandSeparator={true}
              suffix=" đ"
            />
          </th>
          <th>empty</th>
        </tr>

        <tr>
          <th>Chênh lệch trong ngày</th>
          <th>empty</th>
          <th>empty</th>

          <th className="text-end">
            <CurrencyFormat
              value={totalDifference}
              displayType={"text"}
              thousandSeparator={true}
              suffix=" đ"
            />
          </th>
          <th>empty</th>
        </tr>

        <tr>
          <th>Số dư đầu kì</th>
          <th>empty</th>
          <th>empty</th>

          <th className="text-end">
            <CurrencyFormat
              value={startBalance}
              displayType={"text"}
              thousandSeparator={true}
              suffix=" đ"
            />
          </th>
          <th>empty</th>
        </tr>
        <tr>
          <th>Số dư cuối kì</th>
          <th>empty</th>
          <th>empty</th>

          <th className="text-end">
            <CurrencyFormat
              value={endBalance}
              displayType={"text"}
              thousandSeparator={true}
              suffix=" đ"
            />
          </th>
          <th>empty</th>
        </tr>
      </tbody>
    </Table>
  );
};

export default MarketBill;
