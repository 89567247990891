import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import * as XLSX from "xlsx-js-style";

import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Spinner,
} from "react-bootstrap";
import swal from "sweetalert";
import Select from "react-select";
import QuantitativeTable from "./portionCalculateComponent/QuantitativeTable";
import NutritionTable from "./portionCalculateComponent/NutritionTable";
import SummaryForm from "./portionCalculateComponent/SummaryForm";
import FoodQuantitativeTable from "./portionCalculateComponent/FoodQuantitativeTable";
import MarketBill from "./excelReport/MarketBill";
import NutritionResult from "./excelReport/NutritionResult";
import NutritionSetupResult from "./excelReport/NutritionSetupResult";

import {
  exportMarketBill,
  exportMarketBillAll,
  exportNutritionResult,
  exportNutritionSetupResult,
} from "./excelReport/exportFunctions";
import {
  groupArrayByField,
  dateFormatter,
  priceFormatter,
} from "../../utils/helpers";
import Check from "../../other/Check";
import MarketBillAllExport from "./excelReport/MarketBillAllExport";

const PortionCalculate = () => {
  const [menu, setMenu] = useState({});

  const [listQuantitatives, setListQuantitatives] = useState([]);
  const [quantitative, setQuantitative] = useState({
    menu_date: new Date().getTime() / 1000,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [isSendingForm, setIsSendingForm] = useState(false);

  const [isChangeMarket, setIsChangeMarket] = useState(false);
  const [isChangeFood, setIsChangeFood] = useState(false);

  const [classGroups, setClassGroups] = useState([]);
  const [selectedClassGroup, setSelectedClassGroups] = useState({});

  const [suppliers, setSuplliers] = useState([]);
  const [ingredients, setIngredients] = useState([]);

  const [listDays, setListDays] = useState([]);
  const [selectedDay, setSelectedDay] = useState({});
  const [dataDays, setDataDays] = useState({});

  const [menuGroups, setMenuGroups] = useState([]);

  useEffect(() => {
    document.title = "Tính khẩu phần ăn";

    let initialWeek = datesOfWeek(new Date());

    let todayIs = new Date().getDay();
    if (todayIs === 0) {
      todayIs = 7;
    }

    getListQuantitatives(initialWeek[0], initialWeek[6], todayIs);
    getListClassGroups();
    getListSuppliers();
    getListIngredients();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  //#region  API
  const getListQuantitatives = async (startDate, endDate, dayId) => {
    setIsLoading(true);

    setMenu({});
    setListQuantitatives([]);
    setListDays([]);
    setQuantitative({ menu_date: startDate / 1000 });

    await axios
      .get(`menu-show`, {
        params: {
          start_at: startDate.getTime() / 1000,
          term_at: endDate.getTime() / 1000,
        },
      })
      .then((res) => {
        setIsLoading(false);

        if (res.data.errCode === 0) {
          setMenu(res.data.menu);

          const resDays = res.data.days;

          if (resDays.length > 0) {
            setListDays(
              resDays.map((dayItem) => {
                return {
                  id_day: dayItem.id_day,
                  day_name: dayItem.day_name,
                  date: new Date(dayItem.menu_date * 1000),
                };
              })
            );

            const day = resDays.filter((item) => item.id_day === dayId);
            const idDayselected =
              day.length > 0 ? day[0].menu_date : resDays[0]["menu_date"];

            setMenuGroups(res.data.groups || []);
            getListSettings(resDays, dayId, idDayselected);
            setDataDays(resDays);
          }
        } else {
          toast(res.data.message, {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getListSettings = async (
    listQuantitatives,
    dayId,
    setting_date = parseInt(quantitative.menu_date)
  ) => {
    setIsLoading(true);

    await axios
      .get(`/setting-show?setting_date=${setting_date}`)
      .then((res) => {
        if (res.data.errCode === 0) {
          try {
            const dataGroups = res.data.setting.groups;

            const newListQuantitatives = listQuantitatives.map(
              (quantitativeItem) => {
                return {
                  ...quantitativeItem,
                  costPerDays: quantitativeItem.costPerDays.map(
                    (costPerDay) => {
                      const daySetting = dataGroups.find(
                        (group) => group.id === costPerDay.id_group
                      );

                      return {
                        ...costPerDay,
                        start_balance: Math.round(
                          Number(costPerDay.start_balance)
                        ),
                        fee_other: daySetting
                          ? Number(daySetting.fee_other)
                          : 0,
                        fee_meal: daySetting ? Number(daySetting.fee_meal) : 0,
                        price_meal: daySetting
                          ? Number(daySetting.price_meal)
                          : 0,
                      };
                    }
                  ),
                  times: quantitativeItem.times?.map((timeItem) => {
                    return {
                      ...timeItem,
                      foods: timeItem.foods?.map((foodItem) => {
                        return {
                          ...foodItem,
                          groups: foodItem.groups?.map((groupItem) => {
                            return {
                              ...groupItem,
                              // Handle change market quantitative
                              ingredents:
                                // groupItem.more_meal === null?
                                groupItem.ingredents?.map((ingredientItem) => {
                                  return {
                                    ...ingredientItem,
                                    tmp_ing_quantity:
                                      ingredientItem.ing_quantity,
                                    tmp_id_supplier: ingredientItem.id_supplier,
                                    tmp_supplier_name:
                                      ingredientItem.supplier_name,
                                    tmp_ing_price_expect:
                                      ingredientItem.ing_price_expect,
                                  };
                                }),
                              // : [], //Là TH Nấu riêng nên không TKPA => []
                            };
                          }),
                        };
                      }),
                    };
                  }),
                };
              }
            );

            setListQuantitatives(newListQuantitatives);

            setQuantitative(
              newListQuantitatives.find(
                (listQuantitativesItem) =>
                  listQuantitativesItem.id_day === dayId
              )
                ? newListQuantitatives.find(
                    (listQuantitativesItem) =>
                      listQuantitativesItem.id_day === dayId
                  )
                : newListQuantitatives[0]
            );

            setSelectedDay(
              newListQuantitatives.find(
                (listQuantitativesItem) =>
                  listQuantitativesItem.id_day === dayId
              )
                ? newListQuantitatives.find(
                    (listQuantitativesItem) =>
                      listQuantitativesItem.id_day === dayId
                  )?.id_day
                : newListQuantitatives[0].id_day
            );
          } catch (e) {
            toast(e.message, { type: "error" });
          }
        } else {
          toast(res.data.message, {
            type: "error",
            autoClose: 1000,
          });
        }

        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getListClassGroups = async () => {
    await axios
      .get(`/group-show`)
      .then((res) => {
        if (res.data.errCode === 0) {
          setClassGroups(res.data.groups);
          setSelectedClassGroups(res.data.groups[0]);
        } else {
          toast(res.data.message, {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getListSuppliers = async () => {
    await axios
      .post("/viewSupplier")
      .then((res) => {
        if (res.data.status) {
          setSuplliers(
            res.data.data.map((supplierItem) => {
              return {
                id: supplierItem.id,
                supplier_name: supplierItem.supplier_name,
              };
            })
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getListIngredients = async () => {
    await axios
      .get(`/ingredent-show`)
      .then((res) => {
        if (res.data.errCode === 0) {
          setIngredients(res.data.ingredents);
        } else {
          toast(res.data.message, {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const saveQuantitative = async () => {
    setIsSendingForm(true);

    var bodyFormData = new FormData();

    bodyFormData.append("id", quantitative.id);
    bodyFormData.append("id_menu", menu.id_menu);
    bodyFormData.append("id_day", quantitative.id_day);
    bodyFormData.append("menu_date", quantitative.menu_date);
    bodyFormData.append(
      "costPerDays",
      JSON.stringify(quantitative.costPerDays)
    );
    bodyFormData.append(
      "times",
      JSON.stringify(
        quantitative.times.map((timeItem) => {
          return {
            ...timeItem,
            foods: timeItem.foods.map((foodItem) => {
              return {
                ...foodItem,
                groups: foodItem.groups.map((groupItem) => {
                  return {
                    ...groupItem,
                    ingredents: groupItem.ingredents.map((ingredientItem) => {
                      return {
                        ...ingredientItem,
                        ing_quantity: isChangeMarket
                          ? // ? priceFormatter.format(Number(ingredientItem.tmp_ing_quantity)).replace(",", "") || 0
                            // : priceFormatter.format(Number(ingredientItem.ing_quantity)).replace(",", "") || 0,
                            Number(ingredientItem.tmp_ing_quantity) || 0
                          : Number(ingredientItem.ing_quantity) || 0,
                      };
                    }),
                  };
                }),
              };
            }),
          };
        })
      )
    );

    await axios({
      method: "put",
      url: "meals-daily-save",
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        setIsSendingForm(false);

        if (res.data.errCode === 0) {
          toast(res.data.message, {
            type: "success",
            autoClose: 1000,
          });
          let initialWeek = datesOfWeek(
            new Date(quantitative.menu_date * 1000)
          );

          getListQuantitatives(initialWeek[0], initialWeek[6], selectedDay);
          setIsChangeMarket(false);
          setIsChangeFood(false);
        } else {
          toast(res.data.message, {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleUnSavedMealDaily = async (id_menu_day) => {
    swal({
      title: `Bạn chắc muốn hủy lưu số liệu tính khẩu phần ăn?`,
      text: `Ngày ${dateFormatter(new Date(quantitative.menu_date * 1000))}`,
      icon: "warning",
      buttons: ["Đóng", "Hủy"],
      successMode: true,
    }).then(async (ok) => {
      if (ok) {
        await axios
          .delete(`/meals-daily-unsaved`, {
            params: {
              id_menu_day: id_menu_day,
            },
          })
          .then((res) => {
            if (res.data.errCode === 0) {
              toast(res.data.message, {
                type: "success",
                autoClose: 1000,
              });

              let initialWeek = datesOfWeek(
                new Date(quantitative.menu_date * 1000)
              );

              getListQuantitatives(initialWeek[0], initialWeek[6], selectedDay);
              setIsChangeMarket(false);
              setIsChangeFood(false);
            } else {
              toast(res.data.message, {
                type: "error",
                autoClose: 1000,
              });
            }
          });
      }
    });
  };
  // #endregion

  //#region Logic
  const exportExcel = () => {
    const wb = XLSX.utils.book_new();

    let ingredientArray = [];

    quantitative.times?.map((timeItem) => {
      timeItem.foods?.map((foodItem) => {
        foodItem.groups
          .find(
            (groupItem) =>
              groupItem.id === selectedClassGroup.id &&
              groupItem.more_meal === null
          )
          ?.ingredents?.map((ingredientItem) => {
            ingredientArray.push(ingredientItem);
            return ingredientItem;
          });
        return foodItem;
      });
      return timeItem;
    });

    const groupIngredientArray = groupArrayByField(ingredientArray, "id");

    // const ws1 = exportMarketBill(groupIngredientArray);
    const ws1 = exportMarketBill(
      groupIngredientArray,
      quantitative,
      selectedClassGroup.id
    );

    const ws2 = exportNutritionResult(groupIngredientArray, quantitative);
    const ws3 = exportNutritionSetupResult(groupIngredientArray, quantitative);

    XLSX.utils.book_append_sheet(wb, ws1, "Hóa đơn đi chợ");
    XLSX.utils.book_append_sheet(wb, ws2, "Kết quả dưỡng chất");
    XLSX.utils.book_append_sheet(wb, ws3, "Kết quả thiết lập dưỡng chất");
    // XLSX.utils.book_append_sheet(wb, ws4, "Hóa đơn đi chợ All");

    XLSX.writeFile(
      wb,
      `Tính khẩu phần ăn (${dateFormatter(
        new Date(quantitative.menu_date * 1000)
      )}).xlsx`
    );
  };
  const exportExcelAll = () => {
    const wb = XLSX.utils.book_new();

    let ingredientArray = [];

    quantitative.times?.map((timeItem) => {
      timeItem.foods?.map((foodItem) => {
        foodItem.groups
          .find(
            (groupItem) =>
              groupItem.id === selectedClassGroup.id &&
              groupItem.more_meal === null
          )
          ?.ingredents?.map((ingredientItem) => {
            ingredientArray.push(ingredientItem);
            return ingredientItem;
          });
        return foodItem;
      });
      return timeItem;
    });

    const groupIngredientArray = groupArrayByField(ingredientArray, "id");

    const ws4 = exportMarketBillAll(
      groupIngredientArray,
      quantitative,
      classGroups
    );
    XLSX.utils.book_append_sheet(wb, ws4, "Hóa đơn đi chợ tổng hợp");

    XLSX.writeFile(
      wb,
      `Hóa đơn đi chợ (${dateFormatter(
        new Date(quantitative.menu_date * 1000)
      )}).xlsx`
    );
  };
  const handleXportPortionAll = () => {
    swal({
      title: `Bạn muốn xuất hóa đơn đi chợ?`,
      text: `Ngày ${dateFormatter(new Date(quantitative.menu_date * 1000))}`,
      icon: "warning",
      buttons: ["Đóng", "Xuất"],
      successMode: true,
    }).then((ok) => {
      if (ok) {
        exportExcelAll();
      }
    });
  };
  const handleXportPortion = () => {
    swal({
      title: `Bạn muốn xuất file khẩu phần ăn?`,
      text: `Ngày ${dateFormatter(new Date(quantitative.menu_date * 1000))}`,
      icon: "warning",
      buttons: ["Đóng", "Xuất"],
      successMode: true,
    }).then((ok) => {
      if (ok) {
        exportExcel();
      }
    });
  };

  const handleCancelMarketChange = () => {
    swal({
      title: `Bạn muốn hủy thay đổi đi chợ?`,
      text: `Lưu ý: hành động không thể khôi phục!`,
      icon: "warning",
      buttons: ["Đóng", "Hủy"],
      successMode: true,
    }).then((ok) => {
      if (ok) {
        setIsChangeMarket(false);
        setQuantitative({
          ...quantitative,
          times: quantitative.times.map((timeItem) => {
            return {
              ...timeItem,
              foods: timeItem.foods.map((foodItem) => {
                return {
                  ...foodItem,
                  groups: foodItem.groups.map((groupItem) => {
                    return {
                      ...groupItem,
                      // Handle change market quantitative
                      ingredents: groupItem.ingredents.map((ingredientItem) => {
                        return {
                          ...ingredientItem,
                          tmp_ing_quantity: ingredientItem.ing_quantity || 0,
                          id_supplier: ingredientItem.tmp_id_supplier,
                          supplier_name: ingredientItem.tmp_supplier_name,
                          ing_price_expect: ingredientItem.tmp_ing_price_expect,
                        };
                      }),
                    };
                  }),
                };
              }),
            };
          }),
        });
      }
    });
  };

  const handleCancelFoodChange = () => {
    swal({
      title: `Bạn muốn hủy thay đổi bếp?`,
      text: `Lưu ý: hành động không thể khôi phục!`,
      icon: "warning",
      buttons: ["Đóng", "Hủy"],
      successMode: true,
    }).then((ok) => {
      if (ok) {
        setIsChangeFood(false);
        setQuantitative({
          ...quantitative,
          times: quantitative.times.map((timeItem) => {
            return {
              ...timeItem,
              foods: timeItem.foods.map((foodItem) => {
                return {
                  ...foodItem,
                  groups: foodItem.groups.map((groupItem) => {
                    return {
                      ...groupItem,
                      ingredents: groupItem.ingredents.map((ingredientItem) => {
                        return {
                          ...ingredientItem,
                          ing_quantity: ingredientItem.tmp_ing_quantity || 0,
                        };
                      }),
                    };
                  }),
                };
              }),
            };
          }),
        });
      }
    });
  };

  const datesOfWeek = (current) => {
    var week = [];
    // Starting Monday not Sunday
    current.setDate(current.getDate() - current.getDay() + 1);
    current.setHours(0, 0, 0, 0);
    for (var i = 0; i < 7; i++) {
      week.push(new Date(current));
      current.setDate(current.getDate() + 1);
      current.setHours(0, 0, 0, 0);
    }
    return week;
  };
  // #endregion

  // Render
  const RenderOption = () => {
    return (
      <section className="mb-2">
        <Card.Title>Chọn ngày tính khẩu phần ăn</Card.Title>

        <Row className="my-2">
          {listDays.map((dayItem) => {
            return (
              <Col key={dayItem.id_day} md="auto" xs={6} className="d-flex">
                <Form.Check
                  type="radio"
                  value={dayItem.id_day}
                  checked={selectedDay === dayItem.id_day}
                  onChange={(e) => {
                    if (e.target.checked) {
                      const findListQuantitatives = listQuantitatives.find(
                        (quantitativeItem) =>
                          quantitativeItem.id_day === Number(e.target.value)
                      );

                      setSelectedDay(Number(e.target.value));

                      setQuantitative(
                        listQuantitatives.find(
                          (quantitativeItem) =>
                            quantitativeItem.id_day === Number(e.target.value)
                        )
                      );

                      getListSettings(
                        dataDays,
                        Number(e.target.value),
                        findListQuantitatives.menu_date
                      );
                    }
                  }}
                  name="day"
                  className="me-2"
                  disabled={isChangeMarket || isChangeFood}
                />
                {dayItem.day_name}
              </Col>
            );
          })}
        </Row>

        <div className="d-block d-md-flex justify-content-between">
          <Select
            options={classGroups}
            placeholder={<div>Tất cả</div>}
            getOptionLabel={(option) => option.group_name}
            getOptionValue={(option) => option.id}
            value={selectedClassGroup}
            onChange={(choice) => {
              setSelectedClassGroups(choice);
            }}
            className="mb-2 mb-md-0 d-flex in z-index-1021"
            isDisabled={isChangeFood || isChangeMarket}
          />

          <div className="d-flex align-items-center">
            {isChangeFood ? (
              ""
            ) : isChangeMarket ? (
              <>
                <div className="me-3 text-muted">
                  <i>
                    <strong>
                      <span className="text-danger">**</span>Lưu ý:
                    </strong>{" "}
                    Hãy chọn "Lưu số liệu" trước khi chuyển trang hoặc hủy thay
                    đổi.
                  </i>
                </div>

                <Button
                  size="sm"
                  variant="danger"
                  onClick={() => {
                    handleCancelMarketChange();
                  }}
                  disabled={isLoading}
                >
                  <i className="fa-solid fa-times" /> Hủy
                </Button>
              </>
            ) : new Check().permission(["30"]) ? (
              <Button
                size="sm"
                variant="success"
                onClick={() => {
                  const currentCostPerDays = quantitative.costPerDays?.find(
                    (costPerDay) =>
                      costPerDay.id_group === selectedClassGroup.id
                  );
                  const numberChildren = currentCostPerDays?.meal_quantity || 0;

                  if (numberChildren === 0) {
                    toast("Vui lòng nhập số lượng trẻ", {
                      type: "error",
                      autoClose: 2000,
                    });

                    return;
                  }

                  setIsChangeMarket(true);
                }}
                className="me-2"
                disabled={isLoading}
              >
                Thay đổi đi chợ
              </Button>
            ) : (
              <></>
            )}

            {/* Change food quantitative */}
            {isChangeMarket ? (
              ""
            ) : isChangeFood ? (
              <>
                <div className="me-3 text-muted">
                  <i>
                    <strong>
                      <span className="text-danger">**</span>Lưu ý:
                    </strong>{" "}
                    Hãy chọn "Lưu số liệu" trước khi chuyển trang hoặc hủy thay
                    đổi.
                  </i>
                </div>

                <Button
                  size="sm"
                  variant="danger"
                  onClick={() => {
                    handleCancelFoodChange();
                  }}
                  disabled={isLoading}
                >
                  <i className="fa-solid fa-times" /> Hủy
                </Button>
              </>
            ) : new Check().permission(["30"]) ? (
              <Button
                size="sm"
                variant="success"
                onClick={() => setIsChangeFood(true)}
                disabled={isLoading}
              >
                Thay đổi bếp
              </Button>
            ) : (
              <></>
            )}
          </div>
        </div>
      </section>
    );
  };

  return (
    <Container fluid>
      <Breadcrumb>
        <Breadcrumb.Item active>Thực đơn</Breadcrumb.Item>
        <Breadcrumb.Item active>Tính khẩu phần ăn</Breadcrumb.Item>
      </Breadcrumb>
      <Card>
        <Card.Header>
          <div className="d-block d-md-flex justify-content-between">
            <div className="d-block d-md-flex align-items-center">
              <Card.Title className="me-2">
                Tính khẩu phần ăn{" "}
                {dateFormatter(new Date(quantitative.menu_date * 1000))}
              </Card.Title>

              {/* Select Week */}
              <input
                type="week"
                name="week"
                style={{
                  border: "none",
                  width: 39,
                  backgroundColor: "rgb(16,108,252)",
                  borderRadius: 5,
                }}
                className="me-2 mb-3 mb-md-0"
                onChange={(e) => {
                  const today = e.target.value
                    ? new Date(e.target.valueAsNumber)
                    : new Date();
                  const week = datesOfWeek(today);

                  getListQuantitatives(week[0], week[6], selectedDay);
                }}
                hidden={isLoading || isChangeMarket || isChangeFood}
              />
            </div>

            <div className="d-flex">
              <Button
                size="sm"
                variant="success"
                onClick={() => handleXportPortionAll()}
                className="me-2"
                disabled={
                  isLoading ||
                  isSendingForm ||
                  Object.keys(quantitative).length <= 1 ||
                  isChangeMarket ||
                  isChangeFood
                }
              >
                <i className="fa-solid fa-file-export" /> Xuất HĐĐC
              </Button>
              <Button
                size="sm"
                variant="success"
                onClick={() => handleXportPortion()}
                className="me-2"
                disabled={
                  isLoading ||
                  isSendingForm ||
                  Object.keys(quantitative).length <= 1 ||
                  isChangeMarket ||
                  isChangeFood
                }
              >
                <i className="fa-solid fa-file-export" /> Xuất file
              </Button>

              {quantitative.is_meal_saved === 1 &&
              new Check().permission(["122"]) ? (
                <Button
                  size="sm"
                  variant="danger"
                  onClick={() => handleUnSavedMealDaily(quantitative.id)}
                  className="me-2"
                  disabled={
                    isLoading ||
                    isSendingForm ||
                    Object.keys(quantitative).length <= 1
                  }
                >
                  <i className="fa-solid fa-times" /> Hủy lưu số liệu
                </Button>
              ) : (
                ""
              )}

              {new Check().permission(["121"]) ? (
                <Button
                  size="sm"
                  variant="success"
                  onClick={() => saveQuantitative()}
                  disabled={
                    isLoading ||
                    isSendingForm ||
                    Object.keys(quantitative).length <= 1
                  }
                >
                  <i className="fa-solid fa-check" /> Lưu số liệu
                </Button>
              ) : (
                <></>
              )}
            </div>
          </div>
        </Card.Header>

        <Card.Body style={{ fontSize: ".9em" }} className="pt-1">
          {isLoading ? (
            <div className="d-flex justify-content-center my-5">
              <Spinner
                animation="border"
                role="status"
                variant="primary"
                style={{ width: "3rem", height: "3rem" }}
              >
                <span className="visually-hidden">Đang tải...</span>
              </Spinner>
            </div>
          ) : Object.keys(quantitative).length > 0 ? (
            <Row>
              <Col xs={12} md={8} className="px-0">
                {RenderOption()}

                <QuantitativeTable
                  quantitative={quantitative}
                  setQuantitative={setQuantitative}
                  selectedClassGroup={selectedClassGroup}
                  isChangeMarket={isChangeMarket}
                  suppliers={suppliers}
                />

                {isChangeFood ? (
                  <FoodQuantitativeTable
                    quantitative={quantitative}
                    setQuantitative={setQuantitative}
                    selectedClassGroup={selectedClassGroup}
                    ingredients={ingredients}
                  />
                ) : (
                  <></>
                )}

                <div className="d-none">
                  <MarketBillAllExport quantitative={quantitative} />

                  <MarketBill
                    quantitative={quantitative}
                    selectedClassGroup={selectedClassGroup}
                  />

                  <NutritionResult
                    quantitative={quantitative}
                    selectedClassGroup={selectedClassGroup}
                  />
                  <NutritionSetupResult
                    quantitative={quantitative}
                    selectedClassGroup={selectedClassGroup}
                  />
                </div>
              </Col>

              <Col xs={12} md={4} className="pe-md-0">
                <div className="sticky-md-top" style={{ top: "0" }}>
                  <NutritionTable
                    quantitative={quantitative}
                    selectedClassGroup={selectedClassGroup}
                    isChangeMarket={isChangeMarket}
                  />

                  <SummaryForm
                    quantitative={quantitative}
                    setQuantitative={setQuantitative}
                    selectedClassGroup={selectedClassGroup}
                    isChangeMarket={isChangeMarket}
                    isChangeFood={isChangeFood}
                  />
                </div>
              </Col>
            </Row>
          ) : (
            <></>
          )}
        </Card.Body>
      </Card>
    </Container>
  );
};

export default PortionCalculate;
